.file-input-el {
    cursor: pointer;
    background-color: var(--blue-100);
    border: 1px solid var(--blue-100);
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 16px;

    &-sm {
        padding: 8px;
    }

    &-md {
        padding: 12px;
    }

    &-lg {
        padding: 21px 12px;
    }

    &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
    }

    &-box {
        display: flex;
        border-radius: 8px;
        background: #f5f6f7;
        flex-direction: column;
        padding: 12px;
    }

    &-text {
        color: #767676;
        font-size: var(--body-text-size-md);
    }

    &-button {
        padding: 2px 18px;
        font-weight: bold;
        border-radius: 20px;
        background: var(--blue-100);
        border: 2px solid var(--yellow-500);
        color: var(--blue-900);
        font-size: var(--body-text-size-sm);
    }

    &-button:hover {
        box-shadow: 1px 1px 8px rgba(53, 74, 99, 0.3);
    }

    &-name {
        margin-top: 10px;
    }

    .file-input-el-box {
        & > .header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 10px;
            margin: 0 5px;

            & > .header-start {
                display: flex;
                align-items: center;
                gap: 10px;

                & > .icon {
                    color: var(--grey-600);
                    width: 20px;
                    height: 20px;
                }

                & > .title-container {
                    display: flex;
                    flex-direction: column;

                    & > .file-input-el-title {
                        color: var(--grey-600);
                        font-size: var(--body-text-size-sm);
                    }

                    & > .file-input-el-subtitle {
                        color: var(--grey-600);
                        font-size: var(--body-text-size-sm);
                    }
                }
            }

            & > .remove-upload-text {
                color: var(--blue-900);
                font-weight: bold;
                font-size: var(--body-text-size-md);
            }
        }

        .file-input-el-content {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 25px 0;

            .file-input-browse-file-text {
                color: var(--blue-900);
                font-weight: bold;
                font-size: var(--body-text-size-md);
            }

            .file-input-el-content-container {
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 25px;
                min-height: 100px;

                & > .icon {
                    width: 84px;
                    height: 84px;
                }
            }
        }
    }
}

.is-disabled {
    border: unset !important;
}

.upload-mini {
    height: 2.5rem;
    width: 95%;
    border: 1px solid var(--grey-050);
    background-color: var(--blue-10);
    border-radius: 0.5rem;
    display: inline-flex;
    align-items: center;
    padding: 0.25rem 0.5rem;
    gap: 0.5rem;
    &__text {
        width: calc(100% - 4.5rem);
        color: var(--grey-600);
        font-size: var(--body-text-size-sm);
    }
    &__icon {
        height: 2.25rem;
        width: 2.25rem;
    }
}

.product-image {
    height: 5rem !important;
    width: 5rem !important;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--blue-10);
    border-radius: 0.5rem;
    position: relative;
    .MuiImageListItem-img {
        height: inherit;
        object-fit: contain;
    }
    .delete {
        position: absolute;
        top: 0;
        right: 0;
        height: 1.5rem;
        width: 1.5rem;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(#f5f6f7, 0.8);
    }
    .star {
        position: absolute;
        bottom: 0;
        right: -0.2rem;
        height: 2rem;
        width: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        background: rgba(#f5f6f7, 0.8);
        &:hover {
            background: var(--blue-100);
        }
        &--is-featured {
            color: var(--yellow-300);
            background-color: rgba(255, 255, 255, 0.5);
            &:hover {
                background-color: var(--blue-100);
            }
        }
    }

    .delete:hover path {
        cursor: pointer;
        fill: var(--grey-800);
    }
}
