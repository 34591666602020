.new-manufacturer-equipment-detail-page {
    .detail-page-container {
        display: flex;
        column-gap: 1rem;

        & > .absolute {
            position: absolute;
            z-index: 1;
        }

        .detail-page {
            width: 100%;

            & .tab-container {
                .upload-button {
                    background-color: white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 4;
                    width: 2.8rem;
                    height: 2.8rem;
                    padding: 0;
                    margin-right: 0.5rem;
                }

                & .toggle-button {
                    width: 2.8rem;
                    height: 2.8rem;
                    &:hover {
                        border: 1px solid #fdc00e;
                        background-color: white;
                    }
                }
                & .divider {
                    width: 2px;
                    background-color: var(--grey-100);
                    margin: 0 0.5rem;
                    height: 2.8rem;
                }
            }

            & .documents-search-input {
                width: 35%;
                margin-bottom: 0.556rem;

                @media only screen and (max-width: 900px) {
                    width: 100%;
                }
            }

            .equipment-type {
                background-color: #a2cdb3;
                color: var(--blue-200);
                padding: 0.2rem 0.5rem;
                border-radius: 18px;
                margin-top: 1rem;
                font-weight: 300;
            }

            .part-number {
                color: var(--grey-600);
                font-size: var(--body-text-size-sm);
                font-weight: 400;
                margin-left: 0.5rem;

                b {
                    color: var(--grey-800);
                }
            }

            .content-container {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                @media only screen and (min-width: 1200px) {
                    & > :first-child {
                        width: 70%;
                    }

                    & > :last-child {
                        width: 30%;
                    }
                }

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    & > * {
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1200px) {
                    & > :first-child {
                        width: 70%;
                    }

                    & > :last-child {
                        width: 30%;
                    }
                }
            }

            & .yellow-wrapper {
                display: block;
                border-radius: 8px;
                padding: 10px;
                align-self: stretch;
                background-color: white;
                margin-bottom: 16px;
                justify-content: center;
                align-items: center;
                min-height: 930px;
                position: relative;

                div[data-rmiz-wrap='visible'] {
                    width: 100%;
                }

                & .product-image {
                    max-width: 100%;
                    width: calc(100vw);
                }
            }

            & .yellow-wrapper.drawing-parts {
                min-height: 575px;

                & .product-image {
                    height: auto;
                    max-width: 100%;
                    max-height: 575px;
                    width: auto;
                }
            }
        }
    }
}

.delete-equipment-modal {
    .delete-modal-content {
        display: flex;
        flex-direction: column;
        row-gap: 1.25rem;

        span {
            font-size: var(--body-text-size-md);
        }
    }
}
