$containerWidth: 150px;
$containerHeight: 40px;
$space: 5px;

.activity-due-date-container {
    position: relative;
    width: $containerWidth;
    height: $containerHeight;
    z-index: 3;

    .selected-one {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: calc($containerHeight / 2);
        z-index: 1;

        svg {
            transform: scale(0.8);
            margin-right: 5px;
        }

        &.empty-due-date {
            background-color: #f5f6f7;
            border: 2px dashed #a2adbb;
            color: #a2adbb;

            svg {
                opacity: 0.5;
            }
        }

        &.focused-dropdown {
            border: 1px solid #fdc00e !important;
        }
    }

    &:hover .enabled-dropdown {
        background-color: white;
        border: 1px solid #68737f;
        color: #68737f;

        svg {
            opacity: 1;
        }
    }

    .datepicker-wrapper {
        position: relative;
        z-index: 2;

        .react-datepicker-popper {
            z-index: 2 !important;
        }

        input[type='text'] {
            opacity: 0;
            width: $containerWidth;
            height: $containerHeight;
        }
    }
}
