.copy-button {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    padding: 0.2rem;
    border-radius: 50%;

    &:hover {
        background-color: var(--blue-050);
        color: var(--blue-900);
    }
}
