.addMoreButtonContainer {
    position: relative;

    .textContainer {
        position: relative;
        cursor: pointer;

        svg {
            margin-inline: 5px;
        }
    }

    .addMoreDropdown {
        position: absolute;
        left: 0;
        background: #ffffff;
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        overflow: hidden;
        z-index: 2;

        & > .option {
            position: relative;
            width: 100%;
            white-space: nowrap;
            padding: 8px 16px;

            &:hover {
                background-color: #f5f6f7;
                cursor: pointer;
            }
        }
    }

    .up {
        top: -100px;
    }

    .down {
        top: 40px;
    }
}
