.edit-assembly-modal {
    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }

        & .MuiOutlinedInput-root {
            margin-bottom: 10px;
        }
    }

    .modal-line-checkbox {
        width: 100%;
        display: flex;
        gap: 10px;

        & > .modal-line-text {
            color: var(--grey-800);
        }
    }

    p {
        margin: 10px;
    }

    & .important-note {
        font-size: var(--body-text-size-sm);
        color: var(--red-100);
    }

    & .important-inline-note {
        color: var(--red-100) !important;
    }
}
