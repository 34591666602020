$addNewProductSize: 48px;

.activity-equipment-container {
    position: relative;
    width: 100%;

    .block-dropdown-input-container .input-wrapper {
        .label {
            font-size: var(--body-text-size-sm);
        }
    }

    .add-new-equipment {
        position: relative;
        width: $addNewProductSize;
        height: $addNewProductSize;
        border-radius: calc($addNewProductSize / 3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dashed #a2adbb;
        background-color: #f5f6f7;
        cursor: pointer;

        svg {
            color: #68737f;
            opacity: 0.5;
        }

        &:hover {
            border: 2px dashed #68737f;

            svg {
                opacity: 1;
            }
        }
    }

    .equipment-list-container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 10px;

        .equipment-list {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            flex-wrap: wrap;
            flex: 5;

            .equipment-list-item {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-block: 5px;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid #e7e7e7;
                height: 50px;
                transition: all 0.5s ease-in-out;

                .list-item-content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    flex: 6;
                    padding-left: 15px;

                    b {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        font-size: var(--body-text-size-md);
                    }

                    .model-part-number {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        font-size: var(--body-text-size-sm);
                        color: #767676;
                    }
                }

                .close-section {
                    margin: 5px 0;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    border-left: 2px solid #f5f6f7;
                    max-width: 3rem;

                    span {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            background-color: #fbe9e7;
                        }

                        svg {
                            color: red;
                            transform: scale(0.8);
                        }
                    }
                }

                &.full-width {
                    width: 100%;
                }
            }
        }

        .equipment-list-right-section {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }

    .dropdown-input-content {
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5) !important;
        border-radius: 8px !important;
        .item-content {
            border: none !important;
            padding-left: 1.1rem !important;
            .checkbox-container {
                display: flex;
                align-items: start !important;
                margin-top: 4px;
            }
        }
    }
}
