.dropdown-button {
    display: flex;
    align-items: center;
    width: 2.8rem;
    height: 2.8rem;
    z-index: 2;
    position: relative;

    & .dropdown-inner-button {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;
        width: 2.8rem;
        height: 2.8rem;
        padding: 0;
    }

    &-transparent-container {
        position: absolute;
        width: 15.5rem;
        right: 0.5rem;
        margin-top: 3.5rem;
        background-color: transparent;
        z-index: 2;

        & .dropdown-button-content {
            border-radius: 0.667rem 0.667rem 0 0;
            position: absolute;
            background-color: white;
            min-width: 100%;
            box-shadow: 0 0.444rem 0.889rem 0 rgba(0, 0, 0, 0.2);
            z-index: 1;

            & .anchor {
                color: black;
                padding: 0.667rem;
                text-decoration: none;
                display: block;
                border-bottom: 0.056rem solid var(--yellow-500);
            }

            & .anchor:first-child:hover {
                border-radius: 0.667rem 0.667rem 0 0;
            }

            & .anchor:last-child {
                border-bottom: none;
            }

            & .anchor:hover {
                background-color: #f1f1f1;
            }

            & .disabled {
                pointer-events: none !important;
                color: var(--light-500);
            }
        }
    }
}
