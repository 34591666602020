.network-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3.75rem 0;

    .network-content {
        display: flex;
        width: 72.5rem;
        column-gap: 2.5rem;
    }
}
