.document-list-page {
    .document-list-title {
        color: var(--blue-900);
        font-weight: bold;
    }

    .document-header-right {
        display: flex;
        gap: 1rem;

        .create-document-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
        }
    }

    .no-data {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 0.5rem;

        &__title {
            font-size: 1rem;
            line-height: 1.375rem;
            color: var(--grey-800);
        }

        &__button {
            display: flex;
            gap: 0.75rem;
            align-items: center;
        }
    }

    .create-document-modal {
        height: auto !important;
    }
}
