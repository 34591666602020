.customer-equipment-details-tab-container {
    width: 100%;

    & > .section {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        padding: 1rem 0.5rem;
        border-bottom: 1px solid var(--grey-050);
        
        & > .section-icon {
            margin-right: 0.467rem;
            width: 1rem;
            height: 1rem;
        }
        
        & > .section-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;

            & > .title {
                color: var(--blue-900);
                font-size: var(--body-text-size-sm);
            }

            & > .content {
                width: 100%;
                padding: 0 0.417rem;
                font-size: var(--body-text-size-lg);
                .content-line{
                    display: flex;
                    margin: 7px 0 12px 0;
                    &-item{
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        .title {
                            color: var(--blue-060);
                            font-size: var(--body-text-size-sm);
                        }
                        .value {
                            color: var(--grey-800);
                            font-size: var(--body-text-size-lg);
                        }
                        .active{
                            color: var(--green-100);
                            font-weight: 700;
                        }
                        .expired{
                            color: var(--red-600);
                            font-weight: 700;
                        }
                       
                    }
                }

                & > .image-scroll {
                    display: block;
                    overflow: auto;

                    & > .image-container {
                        display: inline-flex;
                        justify-content: flex-start;
                        align-items: center;
                        padding-top: 0.667rem;
                        min-height: 6.156rem !important;
                        max-height: 6.156rem !important;
    
                        & > * {
                            margin-right: 1.333rem;
    
                            &:last-child {
                                margin-right: 0;
                            }
                        }
    
                        & > .image-wrapper {
                            position: relative;
                            width: 5.25rem !important;
                            height: 5.25rem !important;
                            border-radius: 0.444rem;
                            background-color: var(--grey-050);

                            & .image-item {
                                width: 5.25rem !important;
                                height: 5.25rem !important;
                                object-fit: cover;
                                object-position: center;
                                border-radius: 0.444rem;
                            }

                            & > .icon {
                                cursor: pointer;
                            }
                            
                            & > .image-add-icon {
                                position: absolute;
                                color: var(--light-500);
                                width: 2.5rem;
                                height: 2.5rem;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                            }
    
                            & > .image-remove-icon {
                                position: absolute;
                                color: var(--grey-600);
                                background-color: var(--blue-100);
                                width: 1rem;
                                height: 1rem;
                                top: -0.6rem;
                                right: -0.6rem;
                                border-radius: 50%;
                                padding: 0.2rem;
    
                                &:hover {
                                    color: var(--blue-900);
                                }
                            }
    
                            & > .image-star-border-icon {
                                position: absolute;
                                color: var(--blue-900);
                                background-color: rgba(255, 255, 255, 0.5);
                                width: 1.5rem;
                                height: 1.5rem;
                                bottom: 0.15rem;
                                right: 0.15rem;
                                padding: 0.2rem;
                                border-radius: 50%;
    
                                &:hover {
                                    background-color: var(--blue-100);
                                }
                            }
    
                            & > .image-star-icon {
                                position: absolute;
                                color: var(--yellow-300);
                                background-color: rgba(255, 255, 255, 0.5);
                                width: 1.5rem;
                                height: 1.5rem;
                                bottom: 0.15rem;
                                right: 0.15rem;
                                padding: 0.2rem;
                                border-radius: 50%;

                                &:hover {
                                    background-color: var(--blue-100);
                                }
                            }
    
                            &:hover {
                                background-color: var(--blue-100);
        
                                & > .image-add-icon {
                                    color: var(--blue-500);
                                }
                            }
                        }

                        & .file-input-area {
                            position: absolute;
                            width: 5.25rem !important;
                            height: 5.25rem !important;
                            cursor: pointer;
                        }
                    }

                    &::-webkit-scrollbar-track {
                        -webkit-box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
                        box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
                        background-color: #f5f5f5;
                        border-radius: 0.444rem;
                    }
                
                    &::-webkit-scrollbar {
                        height: 0.42rem !important;
                        background-color: #f5f5f5;
                    }
                
                    &::-webkit-scrollbar-thumb {
                        background-color: #aaa;
                        border-radius: 0.42rem;
                    }
                }
            }
        }

        &.section-images > .section-container > .content {
            padding: 0rem !important;
        }

        &:last-child {
            border-bottom: none;
        }
    }
}
