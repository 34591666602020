$cartContainerPadding: 16px;

.customer-shopping-cart-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $cartContainerPadding;
    background-color: white;
    height: calc(100% - 72px);
    border: 2px solid #f0f0f0;
    box-shadow: 0px 0px 12px 0px #354a6326;
    border-radius: 20px;
    width: 85%;

    .cart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $cartContainerPadding;
        border-bottom: 4px solid var(--blue-10);
        margin: -$cartContainerPadding;
        margin-bottom: 0;

        .cart-title {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 0.5rem;
            font-size: var(--body-text-size-lg);

            svg {
                color: var(--yellow-300);
            }
        }

        .cart-close {
            margin-right: 1rem;

            svg {
                width: 1rem;
                height: 1rem;
                color: var(--grey-600);
            }
        }
    }

    .cart-body {
        height: -webkit-fill-available;
        overflow-y: auto;

        .selected-customer-name {
            font-size: var(--body-text-size-sm);
            color: black;
            background-color: #a2cdb3;
            border-radius: 18px;
            padding: 5px;
        }

        .cart-wrapper {
            .cart-parent-name {
                font-size: 0.6rem;
                font-weight: bold;
                color: var(--blue-900);
            }

            .cart-content-name {
                font-size: 0.889rem;
                font-weight: bold;
                color: var(--blue-900);
            }

            .cart-content-description {
                font-size: var(--body-text-size-sm);
                color: var(--grey-600);
            }
        }
    }

    .cart-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 -16px;
        padding: 0 16px;
        border-top: 4px solid #fdc00e;

        .total-price {
            display: flex;
            justify-content: space-between;
            padding: 1.25rem 0 0.75rem 0;

            &-title {
                color: var(--blue-900);
            }

            &-value {
                color: var(--blue-900);
                font-weight: 700;
            }
        }

        .under-side {
            display: flex;
            justify-content: space-between;
            align-items: self-end;

            .cart-add-more {
                .additional-input {
                    display: flex;
                    column-gap: 3%;

                    .line {
                        width: 40% !important;
                    }
                }
            }
        }
    }

    .reorder-notes {
        font-size: var(--body-text-size-sm);
        color: red;
    }
}
