.customer-cart-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.889rem 0.5rem;
    -webkit-animation: fadein 0.5s linear;
    animation: fadein 0.5s linear;
    border-bottom: 2px solid #f5f6f7;

    .customer-cart-header {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .name {
            color: var(--blue-800);
            font-size: var(--body-text-size-md);
            font-weight: 400;
            color: #354a63;
        }

        .cart-price {
            color: var(--grey-800);
            font-size: var(--body-text-size-md);
        }
    }

    .cart-label {
        display: flex;

        span {
            color: #767676;
            font-size: var(--body-text-size-sm);
        }

        .label-line {
            display: flex;
            gap: 0.2rem;
        }
    }

    .action {
        display: flex;
        align-items: center;
        margin-top: 0.75rem;
        column-gap: 0.75rem;

        .counter {
            display: flex;
            justify-content: center;
            border: 1px solid #f0f0f0;
            background-color: transparent;
            border-radius: 4px;
            padding: 0.2px 2px;
            width: 4.444rem;

            .count-changer {
                display: flex;
                align-items: center;
                cursor: pointer;
            }

            input {
                background-color: transparent;
                border-width: 0;
                width: 50%;
                text-align: center;
            }

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            input[type='number'] {
                -moz-appearance: textfield;
            }
        }

        .remove-wrapper {
            display: flex;
            cursor: pointer;

            path {
                fill: var(--grey-600);
            }
        }
    }

    @-webkit-keyframes fadein {
        0%,
        100% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadein {
        0%,
        100% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }
}
