.new-notepad {
    display: flex;
    flex-direction: column;
    border-radius: 1rem;
    background: linear-gradient(231.58deg, #fdc00e 0%, #fedf87 98.77%);
    height: 29rem;

    &-header {
        display: flex;
        flex-direction: column;
        padding: 1.5rem;

        .title {
            font-size: var(--body-text-size-md);
            font-weight: 700;
        }

        .note {
            font-size: var(--body-text-size-sm);
        }
    }

    &-body {
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 1rem;
        padding: 1.5rem;
        background-color: white;
        height: 100%;

        textarea {
            border: none;
            outline: none;
            background-color: transparent;
            resize: none;
            height: 100%;
            color: var(--grey-800);
            font-size: var(--body-text-size-sm);
        }

        .body-header {
            display: flex;
            position: absolute;
            top: 0.5rem;
            left: 1rem;
            z-index: 1;

            & > .date {
                font-size: var(--body-text-size-sm);
                color: var(--grey-600);
                margin-right: 0.5rem;
            }

            & > .save-container {
                display: flex;

                & > .saving-info {
                    font-size: var(--body-text-size-sm);
                    color: var(--grey-600);
                    margin-right: 0.5rem;
                }

                & > .loading-span {
                    width: var(--body-text-size-sm);
                    height: var(--body-text-size-sm);
                    border-radius: 50%;
                    -webkit-border-radius: 50%;
                    -moz-border-radius: 50%;
                    -ms-border-radius: 50%;
                    -o-border-radius: 50%;
                    border: 0.278rem solid var(--yellow-500);
                    border-bottom-color: transparent;
                    position: relative;
                    animation: spinner 1s linear infinite paused;
                    -webkit-animation: spinner 1s linear infinite;
                }

                @keyframes spinner {
                    to {
                        transform: rotate(1turn);
                        -webkit-transform: rotate(1turn);
                        -moz-transform: rotate(1turn);
                        -ms-transform: rotate(1turn);
                        -o-transform: rotate(1turn);
                    }
                }
            }
        }

        .content {
            display: block;
            margin-bottom: 2.222rem;
            outline: none;
            overflow: auto;
            padding-right: 0.5rem;
            color: var(--grey-800);
            font-size: var(--body-text-size-sm);

            &[contenteditable='true']:empty:before {
                content: attr(placeholder);
                pointer-events: none;
                color: var(--grey-400);
                display: block;
                /* For Firefox */
            }
        }
    }
}

.footer > .note {
    font-size: var(--body-text-size-sm);
    color: var(--grey-600);
    font-style: italic;
}
