$addNewProductSize: 48px;

.activity-product-container {
    position: relative;
    width: 100%;

    .add-new-product {
        position: relative;
        width: $addNewProductSize;
        height: $addNewProductSize;
        border-radius: calc($addNewProductSize / 3);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 2px dashed #a2adbb;
        background-color: #f5f6f7;
        cursor: pointer;

        svg {
            color: #68737f;
            opacity: 0.5;
        }

        &:hover {
            border: 2px dashed #68737f;

            svg {
                opacity: 1;
            }
        }
    }

    .dropdownWithChecklistContainer > .dropdownWithChecklistSearchContainer {
        height: 2.8rem !important;

        .chevronDown > svg {
            position: absolute;
            top: 50%;
            right: 30px;
        }
    }

    .product-list-container {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;

        .product-list {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            flex: 5;

            .product-list-item {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                margin-block: 5px;
                border-radius: 8px;
                overflow: hidden;
                border: 1px solid #e7e7e7;
                height: 50px;
                transition: all 0.5s ease-in-out;

                &:not(.editing-item) .quantity {
                    background-color: #ffe5cd;

                    &:hover {
                        background-color: #fff2e6;
                        cursor: pointer;
                    }
                }

                &.editing-item .quantity {
                    box-shadow: 0 0 2px 2px #fdc00e inset;
                    border-radius: 8px 0 0 8px;

                    input[type='number'] {
                        border: none;
                        outline: none;
                        position: relative;
                        max-width: 30px;

                        &::-webkit-outer-spin-button,
                        &::-webkit-inner-spin-button {
                            -webkit-appearance: none !important;
                        }
                    }
                }

                .quantity {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    font-weight: bold;
                    font-size: var(--body-text-size-md);
                    max-width: 3rem;
                }

                .list-item-content {
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: flex-start;
                    flex: 6;
                    padding-left: 15px;

                    b {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        font-size: var(--body-text-size-md);
                    }

                    .model-part-number {
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box !important;
                        -webkit-line-clamp: 1;
                        -webkit-box-orient: vertical;
                        font-size: var(--body-text-size-sm);
                        color: #767676;
                    }
                }

                .editing-icon-section {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    flex: 1;

                    span {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            background-color: #a2adbb;
                        }

                        svg {
                            color: #767676;
                            transform: scale(0.8);
                        }
                    }
                }

                .close-section {
                    margin: 5px 0;
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex: 1;
                    border-left: 2px solid #f5f6f7;
                    max-width: 3rem;

                    span {
                        width: 30px;
                        height: 30px;
                        border-radius: 50%;
                        cursor: pointer;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        transition: all 0.2s ease-in-out;

                        &:hover {
                            background-color: #fbe9e7;
                        }

                        svg {
                            color: red;
                            transform: scale(0.8);
                        }
                    }
                }

                &.full-width {
                    width: 100%;
                }
            }
        }

        .product-list-right-section {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-end;
        }
    }
}
