.react-datepicker__navigation--next,
.react-datepicker__navigation--previous {
    width: 32px !important;
}

.datepicker-wrapper {
    width: 100% !important;

    .absolute {
        position: absolute;
        right: 5px;
        z-index: 10;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }
}

.react-datepicker-wrapper {
    .react-datepicker__input-container input:disabled {
        background: var(--grey-100) !important;
    }
}
.react-datepicker-popper {
    z-index: 2;
}
