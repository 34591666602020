$buttonMarginLeft: 10px;

.searchEngineContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    svg {
        color: #767676;
    }

    .seachEngineContent {
        display: flex;
        flex-direction: row;
        align-items: center;

        .input-wrapper {
            transform: scaleY(0.9);

            input:hover {
                border: 1px solid #fdc00e !important;
            }
        }

        button:disabled {
            cursor: not-allowed !important;
        }

        .searchButton {
            margin-left: $buttonMarginLeft;
        }

        .goBackButton {
            margin-left: $buttonMarginLeft;
            background-color: white !important;
        }
    }

    .searchEngineNote {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-top: 5px;
        color: #767676;
        font-size: 12.1px;

        svg {
            margin-right: 5px;
        }
    }

    .noResult {
        margin-top: 10px;
        text-align: center;
        color: #767676;
        font-size: 16px;
    }
}
