.order {
    margin-top: 10px;

    &-table {
        .monittor-table-container {
            padding-bottom: 20px !important;
        }
    }

    .buttons {
        display: flex;
        height: 100%;
        align-items: center;
        column-gap: 10%;
        justify-content: flex-end;
        margin-right: 0.5rem;

        .action-button {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            svg {
                color: var(--grey-600);
            }

            &:hover {
                svg {
                    color: var(--blue-900);
                }
            }
        }
    }

    .table-wrapper {
        padding: 4px;
        border-radius: 8px;
        align-self: stretch;

        div[data-rmiz-wrap='visible'] {
            width: 100%;
        }
    }

    .additional-input {
        display: flex;
        flex-wrap: wrap;
        margin-top: 1.667rem;
        align-items: center;
        row-gap: 0.5rem;

        .input-wrapper {
            margin-right: 0.5rem;
        }

        .block-dropdown-input {
            margin-right: 1rem;
            background-color: #ffff;
            border-radius: 0.444rem;
            width: fit-content;

            .input-wrapper {
                margin-right: 0;
            }
        }

        .input-button-wrapper {
            display: flex;
            gap: 4px;
            width: calc(20% - 0.5rem);

            button {
                height: 2.7rem;
                padding: 0.5rem;
                width: 100%;
            }
        }

        @media (min-width: 768px) {
            input {
                margin-bottom: 0;
            }
        }

        @media (max-width: 768px) {
            align-items: flex-end;
            flex-direction: column;
        }
    }

    .additional-wrapper {
        margin-top: 1.667rem;
    }

    .additional-title {
        padding: 0.444rem;
        font-weight: bold;
    }

    .total-wrapper {
        display: flex;
        margin-top: 30px;
        align-items: center;
        justify-content: space-between;

        .total-price {
            border-radius: 8px;
            font-size: var(--body-text-size-md);
            color: var(--blue-900);
            display: flex;
            justify-content: space-between;
            padding: 0.87rem;
            width: fit-content;
            column-gap: 1.8rem;

            &-title {
                font-weight: 400;
            }

            &-value {
                font-weight: 700;
            }

            div {
                padding: 5px 20px;
            }
        }

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;

            .additional-cta {
                margin-bottom: 1.667rem;
            }
        }
    }

    .modal-item-name {
        color: var(--blue-900);
        font-size: 0.85rem;
        font-weight: 700;
    }

    .modal-item-description {
        color: var(--grey-600);
        font-size: 0.75rem;
    }

    .table-cell-description {
        font-size: 0.7rem;
        font-weight: 400;
        color: var(--grey-600);
    }
}

.counter {
    display: flex;
    justify-content: center;
    background-color: var(--blue-100);
    border-radius: 4px;
    padding: 8px;
    width: 80px;
    margin-right: 8px;

    .count-changer {
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    input {
        width: 50%;
        text-align: center;
        background-color: transparent;
        border-width: 0;
        color: var(--grey-600);
        font-size: var(--body-text-size-md);
        outline: none;
        font-weight: 400;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.price {
    position: relative;
    display: flex;
    background-color: var(--blue-100);
    border-radius: 4px;
    padding: 8px;
    width: 120px;
    margin: 0 8px;
    color: var(--grey-600);
    font-size: var(--body-text-size-md);
    font-weight: 700;

    .price-changer {
        right: 1.5rem;
        top: 0.5rem;
        position: absolute;
        display: flex;
        align-items: center;
        cursor: pointer;
    }

    input {
        background-color: transparent;
        border-width: 0;
        width: 100%;
        text-align: center;
        color: var(--grey-600);
        font-size: var(--body-text-size-md);
        outline: none;
        padding-right: 2rem;
        text-align: start;
        font-weight: 700;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    input[type='number'] {
        -moz-appearance: textfield;
    }
}

.modal-container {
    display: flex;
    row-gap: 0.5rem;
    flex-direction: column;
}

.attachments-preview {
    width: 100%;
    &__line {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        border-bottom: 1px solid var(--grey-100);
        padding-bottom: 0.4rem;
        &__link {
            width: 2rem;
            height: 100%;
            & a {
                display: flex;
                align-items: center;
                justify-content: center;
            }
            & svg {
                padding-top: 0.2rem;
                height: 1.25rem;
                width: 1.25rem;
            }
        }
        &__file-name {
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
            & .right {
                display: flex;
                align-items: center;
            }
        }
        &__delete {
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
