$image-width-height: 6.25rem;
$top-section-height: 50px;
$top-section-space: 50px;

.product-partial-update {
    background: white;
    height: calc(100% - 100px);
    width: 22.5rem;
    border-radius: 1.322rem;
    position: sticky;
    top: 0;

    .header {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding-inline: 1.5rem;

        .top-section {
            position: absolute;
            height: $top-section-height;
            width: 100%;
            left: 0;

            .icon-section {
                width: calc(100% - 20px);
                float: right;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                border-bottom: 1px solid #f5f6f7;
                margin-inline: 10px;

                &.with-image {
                    width: calc(100% - ($image-width-height + $top-section-space));
                    margin-right: 10px;
                    margin-left: 0;
                }

                svg {
                    padding-inline: 0.657rem;
                    padding-block: 1rem;
                    width: 0.657rem;
                    height: 0.657rem;
                }

                span {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }
            }
        }

        .image-section {
            position: relative;
            width: $image-width-height;
            height: $image-width-height;
            margin-top: calc($top-section-height / 2);
            background-repeat: no-repeat;
            background-size: cover;
            background-position: 0;
            border: 3px solid #e8e8e8;
            border-radius: 15px;

            svg {
                position: absolute;
                top: 10px;
                right: 10px;
            }
        }

        .description-section {
            position: relative;
            width: calc(100% - $image-width-height);
            top: $top-section-height;
            height: auto;
            padding: 10px;

            .product-name {
                font-size: var(--body-text-size-lg);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                padding-left: 15px;
                line-height: 22px;
                margin-bottom: 10px;
            }
        }

        .no-image {
            width: 100%;

            .product-name {
                padding-left: 0px;
            }
        }
    }
    .part-number-section {
        position: relative;
        padding: 1.5rem;

        .brand {
            padding: 5px;
            padding-inline: 10px;
            background-color: #ffcb9b;
            border-radius: 18px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-size: var(--body-text-size-sm);
            font-weight: 400;
            margin-top: 10px;
            width: fit-content;
            line-height: 18px;
        }

        .part-number {
            margin-top: 10px;
            font-size: var(--body-text-size-sm);
            margin-left: 10px;

            .definition {
                color: var(--grey-600);
                margin-right: 10px;
            }
        }
    }
    .body {
        &__section {
            display: flex;
            flex-direction: column;
            padding: 1rem 0;
            border-top: 4px solid var(--blue-10);
        }

        .label-with-line {
            color: #68737f;
            width: 94%;
            margin-left: 3%;
        }

        .row-item {
            padding: 0.5rem 1rem;
        }

        .unit-price {
            border: 1px solid #ffcb9b;
            border-radius: 0.5rem;
            margin-right: 10px;

            .slash-price {
                color: var(--grey-600);
                font-size: var(--body-text-size-md);
            }
        }
    }
}
