div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 400px !important;
}

.MuiTableRow-root {
    .expand-button-row {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: var(--blue-050) !important;
        height: 2.5rem;
        width: 2.5rem;
        margin-left: -16px;

        path {
            fill: black;
        }
    }

    .add-to-cart-button-cell {
        .add-button-container {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .green-tick {
                width: 100%;
                display: flex;
                justify-content: center;
                svg path {
                    fill: green !important;
                }
            }

            path {
                fill: var(--blue-900);
            }

            & .button:hover {
                background-color: var(--yellow-100);
            }
            & .button:active {
                transform: scale(0.95);
                box-shadow: none;
                border: 4px solid var(--yellow-25);
                & .button:active + & {
                    border-radius: 0;
                }
            }
        }
    }
}

.subRow {
    color: black !important;
}

.subRowCell {
    color: var(--white) !important;
}

.subRow {
    cursor: pointer;
}

.subRowHead {
    visibility: collapse !important;
}

.name-info-container {
    display: flex;
    flex-direction: column;

    .name {
        font-size: var(--body-text-size-md);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 25rem;
    }

    .sub-name {
        color: var(--blue-050);
        font-weight: 400;
        font-size: var(--body-text-size-xs);
    }
}

.date-info-container {
    font-size: var(--body-text-size-md);
}

.parts-table-sub-row {
    height: 2.5rem;

    .child-tree-border {
        display: flex;
        justify-content: center;
        height: 2.5rem;

        .vertical-line {
            width: 1px;
            background-color: var(--blue-050);
            height: 100%;
        }

        .last-child {
            height: 50%;
        }

        .horizontal-container {
            height: 100%;
            display: flex;
            align-items: center;

            .horizontal-line {
                height: 1px;
                background-color: var(--blue-050);
                width: 1.2rem;
            }
        }
    }
}
