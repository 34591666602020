.activity-type-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-block: 10px;

    .types {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;

        .activity-type {
            border-radius: 10px;
            height: 1.8rem;
            border-radius: 18px;
            cursor: pointer;
            font-size: var(--body-text-size-lg);
            display: flex;
            flex-direction: row;
            align-items: center;
            font-weight: 400;
            margin-inline: 10px;
            padding-inline: 10px;
            transition: all 0.3s ease-in-out;

            svg {
                opacity: 0.3;
            }

            &.service-type {
                border: 1px solid #beb290;
                background: #e5e0d2;
                color: #97834e;
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }

            &.replenishment-type {
                background: #dad4e5;
                opacity: 0.5;
                border: 1px solid #b4a9cb;
                color: #695397;

                &:hover {
                    opacity: 1;
                }
            }

            &.ticket-type {
                background: #d5e3e4;
                color: #579293;
                border: 1px solid #96bbbb;
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }

            &.active {
                opacity: 1;
                box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.25);
                font-weight: 700;

                svg {
                    opacity: 1;
                }
            }
        }
    }

    .billable {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        padding-left: 15px;
    }
}
