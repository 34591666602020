div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.flex {
    display: flex;
    align-items: center;
    column-gap: 0.5rem;
    .status {
        width: 0.625rem;
        height: 0.625rem;
        border-radius: 50%;
        margin-top: 1px;

        &-not_invited {
            border: 1px solid var(--grey-600);
        }

        &-pending {
            background-color: var(--orange-100);
            border: 1px solid var(--orange-100);
        }

        &-accepted {
            background-color: var(--green-100);
            border: 1px solid var(--green-100);
        }
    }
    .row {
        display: flex;
        flex-direction: column;
        .contact-info {
            font-size: var(--body-text-size-md);
            color: var(--grey-400);
            font-weight: 300;
        }
    }
}
