.row {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 0.2rem 0;
    &-item {
        display: flex;
        flex-direction: column;
        width: 100%;
        &__label {
            color: #354a63;
            font-size: var(--body-text-size-md);
            font-weight: 700;
            line-height: 1.125rem;
        }
        &__value {
            color: #212121;
            font-size: var(--body-text-size-md);
            line-height: 20px;
            padding-bottom: 10px;
            white-space: pre-line;
        }
    }
}
