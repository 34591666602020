.zoom-button {
    display: flex;
    z-index: 1;
    column-gap: 0.4rem;
    margin-left: auto;

    & div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .zoom-button-icon {
        color: var(--blue-900);
    }

    .zoom-button-reset,
    .zoom-button-plus,
    .zoom-button-minus,
    .zoom-button-close,
    .zoom-button-download {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        & > svg {
            width: 15px;
            height: 15px;
        }
    }

    .zoom-label {
        color: var(--blue-900);
    }

    .zoom-button-reset:hover,
    .zoom-button-download:hover,
    .zoom-button-close:hover {
        background-color: var(--grey-300);

        & > .zoom-button-icon {
            color: var(--blue-900);
        }
    }

    .zoom-button-close > .close-icon {
        transform: rotate(45deg);
    }

    .zoom-button-close {
        width: 2.174rem;
        height: 2.174rem;
        display: flex;
        background-color: var(--blue-100);
        border-radius: 0.444rem;
        flex-direction: row;
        justify-content: space-around;
    }

    .zoom-button-close::before {
        content: '';
        position: absolute;
        left: -7px;
        z-index: 5000;
        width: 1.5px;
        height: 25px;
        background-color: var(--grey-600);
    }

    .zoom-button-left {
        width: 6.522rem;
        height: 2.174rem;
        display: flex;
        background-color: var(--blue-100);
        border-radius: 1rem;
        flex-direction: row;
        justify-content: space-around;
        padding: 0 0.489rem;
    }

    .zoom-button-reset,
    .zoom-button-download {
        width: 2.174rem;
        height: 2.174rem;
        display: flex;
        background-color: var(--blue-100);
        border: none;
        border-radius: 50%;
        flex-direction: row;
        justify-content: space-around;

        svg {
            width: 20px;
            height: 20px;
        }

        &:disabled {
            background-color: #ccc !important;
        }
    }
}

.fixed {
    position: fixed;
    height: 2.174rem;
    top: 90%;
    right: 56px;
}
