div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.document-table {
    svg {
        color: gray !important;
    }
}
