.activity-status-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-block: 10px;

    .activity-status {
        border-radius: 10px;
        height: 1.8rem;
        border-radius: 18px;
        cursor: pointer;
        font-size: var(--body-text-size-lg);
        display: flex;
        flex-direction: row;
        align-items: center;
        font-weight: 400;
        margin-inline: 10px;
        padding-inline: 10px;
        transition: all 0.3s ease-in-out;

        &.open-status {
            background-color: #e0eeff;
            border: 1px solid #a8c2e1;
            color: #354a63;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }

        &.in-progress {
            background-color: #eefbf3;
            border: 1px solid #bce0cb;
            color: #459b68;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }

        &.on-hold {
            background-color: #fff5eb;
            color: #f58926;
            border: 1px solid #fdecdc;
            opacity: 0.5;

            &:hover {
                opacity: 1;
            }
        }

        &.closed {
            background-color: #f5f6f7;
            color: #767676;
            border: 1px solid #c4c4c4;
            opacity: 0.5;

            svg {
                opacity: 0.5;
            }

            &:hover {
                opacity: 1;

                svg {
                    opacity: 1;
                }
            }
        }

        &.active {
            opacity: 1;
            font-weight: 700;
            box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.25) !important;

            svg {
                opacity: 1;
            }
        }
    }
}
