.master-page {
    .create-activity-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
    }

    //.historicalLogSwitch {
    //    .Mui-checked .MuiSwitch-thumb,
    //    .Mui-checked + .css-1yjjitx-MuiSwitch-track {
    //        background-color: gold !important;
    //    }
    //}

    .filter-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        //border: 2px solid var(--yellow-100);
        background-color: transparent;
        border: none !important;
        &--active {
            @extend .filter-button;
            border: 2px solid var(--yellow-100) !important;
        }
    }

    .activity-right-buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 0.5rem;

        .block-dropdown-input {
            width: 30%;
        }
    }

    .filter-menu {
        display: flex;
        justify-content: flex-end !important;
        flex-direction: row;
        align-items: center;
        width: 100%;

        .active-dropdown {
            .MuiOutlinedInput-root {
                background-color: #fffaed;
            }
        }

        .block-dropdown-input {
            width: 15% !important;
        }

        .input {
            background-color: white;
        }
        .MuiSwitch-root {
            height: 2.2rem;
            width: 4rem;
            .MuiSwitch-switchBase {
                padding: 0.4125rem;
                top: 0.45rem;
                left: 0.7rem;
                background-color: var(--grey-600);
                svg path {
                    fill: white;
                }
                &:hover {
                    background: var(--light-500);
                    + .MuiSwitch-track {
                        background: white !important;
                    }
                }
                &.Mui-checked {
                    background-color: var(--yellow-100);
                    &:hover {
                        background: var(--yellow-80);
                    }
                    svg path {
                        fill: black;
                    }
                }
            }
            .MuiSwitch-track {
                background-color: white;
                border: 1px solid var(--grey-100);
            }
        }

        .clear-filter {
            font-size: var(--body-text-size-md);
            color: var(--blue-900);
            font-weight: 700;
            cursor: pointer;
        }

        .MuiFormControl-root {
            width: 12rem;
        }
    }

    .activityContent {
        display: flex;
        justify-content: space-between;

        .activity-table {
            width: 100%;
        }

        .onBox {
            width: 68%;
        }

        .partial-update-box {
            height: calc(100vh - 280px);
            display: flex;
            width: 30%;
            min-width: 450px;
            position: sticky;
            position: -webkit-sticky;
            top: 0;
            right: 1rem;
        }
    }

    .monittor-table-container {
        margin-top: 0 !important;
    }
}
