.new-manufacturer-equipment-detail-page {
    .dot {
        background-color: var(--yellow-800);
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }

    & .tab-container {
        .upload-button {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 5;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            margin-right: 0.5rem;
        }

        .right-actions {
            display: inline-flex;
            gap: 0.5rem;

            .status-badge {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0.2rem 0.5rem;
                border-radius: 18px;
                width: fit-content;
                border: solid 0.5px;
                width: 75px;
                height: 30px;
                font-size: var(--body-text-size-lg);
            }
            .badge-offer {
                border-color: #4280cb;
                color: white;
                background: #4280cb;
            }
            .badge-active {
                color: white;
                border-color: #459b68;
                background: #459b68;
            }
            .badge-expired {
                color: white !important;
                border-color: #d84315 !important;
                background: #d84315 !important;
                width: 85px !important;
            }

            svg {
                padding: 0.62rem;
                cursor: pointer;
            }
        }

        .right-actions-documents {
            display: inline-flex;
            gap: 0.5rem;
            align-items: stretch;

            .create-document-button {
                display: inline-flex;
                gap: 0.75rem;
                align-items: center;
                height: 2.8rem;
            }
        }
    }

    & .documents-search-input {
        width: 35%;
        margin-bottom: 0.556rem;

        @media only screen and (max-width: 900px) {
            width: 100%;
        }
    }

    & .content-box {
        .product-name {
            color: var(--grey-800);
            font-size: var(--body-text-size-3xl);
            font-weight: 400;
        }

        .subtitle {
            display: flex;
            align-items: center;

            .contract-number {
                font-weight: normal;
            }

            .brand-container {
                background-color: #a2cdb3;
                border-radius: 1.125rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                position: relative;
                padding: 0.25rem 0.25rem;

                &--empty {
                    border: 1px dashed var(--yellow-100);
                    border-radius: 1rem;
                    padding: 0.25rem 0.5rem;
                }

                .icon {
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 1.875rem;
                    border-radius: 0 1.125rem 1.125rem 0;

                    &:hover {
                        background-color: var(--orange-100);

                        svg {
                            height: 0.875rem;
                            width: 0.875rem;

                            path {
                                fill: white;
                            }
                        }
                    }
                }
            }
        }

        .equipment-type {
            background-color: #a2cdb3;
            color: var(--blue-200);
            padding: 0.2rem 0.5rem;
            border-radius: 18px;
            margin-top: 1rem;
            font-weight: 300;
        }

        .part-number {
            color: var(--grey-600);
            font-size: var(--body-text-size-sm);
            font-weight: 400;
            margin-left: 0.5rem;

            b {
                color: var(--grey-800);
            }

            .add-button {
                color: #5f9074;
                font-size: var(--body-text-size-sm);
                text-decoration: underline;
            }
        }

        & > .content-body {
            & > .content-container {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                @media only screen and (min-width: 1200px) {
                    & > :first-child {
                        width: 70%;
                    }

                    & > :last-child {
                        width: 30%;
                    }
                }

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    & > * {
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1200px) {
                    & > :first-child {
                        width: 70%;
                    }

                    & > :last-child {
                        width: 30%;
                    }
                }
            }

            & .yellow-wrapper {
                display: block;
                border-radius: 8px;
                padding: 10px;
                align-self: stretch;
                background-color: white;
                margin-bottom: 16px;
                justify-content: center;
                align-items: center;
                min-height: 930px;
                position: relative;

                div[data-rmiz-wrap='visible'] {
                    width: 100%;
                }

                & .product-image {
                    max-width: 100%;
                    width: calc(100vw);
                }
            }

            & .yellow-wrapper.drawing-parts {
                min-height: 575px;

                & .product-image {
                    height: auto;
                    max-width: 100%;
                    max-height: 575px;
                    width: auto;
                }
            }
        }
    }
}
