.settings-page {
    & .content-box {
        & > .content-body {
            & > .content-container {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                @media only screen and (min-width: 1400px) {
                    & > :first-child {
                        width: 40%;
                    }

                    // & > :last-child {
                    //     width: 60%;
                    // }
                }

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    & > * {
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1400px) {
                    & > :first-child {
                        width: 50%;
                    }

                    // & > :last-child {
                    //     width: 55%;
                    // }
                }

                .hover-card {
                    width: 100%;

                    .content {
                        display: flex;
                        column-gap: 5%;

                        & .hubspot-integration-tab-container {
                            width: 30%;

                            .log-name-container {
                                width: 100%;
                                display: flex;
                                flex-direction: column;
                                row-gap: 0.5rem;
                                margin-top: 1rem;

                                .title {
                                    text-align: center;
                                    background-color: var(--grey-050);
                                    padding: 5px 15px;
                                    border-radius: 13px;
                                    cursor: pointer;

                                    &:hover {
                                        background-color: var(--grey-300);
                                    }
                                }

                                .selected {
                                    background-color: var(--grey-300);
                                }
                            }

                            & > .section {
                                display: flex;
                                justify-content: flex-start;
                                align-items: flex-start;
                                padding: 1rem 0.5rem;
                                border-bottom: 1px solid var(--grey-050);

                                & > .section-icon {
                                    color: var(--blue-900);
                                    margin-right: 0.667rem;
                                    width: 1.25rem;
                                    height: 1.25rem;
                                }

                                & > .section-container {
                                    display: flex;
                                    justify-content: flex-start;
                                    align-items: flex-start;
                                    flex-direction: column;
                                    width: 100%;

                                    & > .title {
                                        color: var(--blue-900);
                                        font-size: var(--body-text-size-sm);
                                    }

                                    & > .content {
                                        width: 100%;
                                        padding: 0 0.417rem;
                                        color: var(--grey-800);

                                        & > .content-row {
                                            display: flex;
                                            flex-direction: column;

                                            & .title {
                                                padding-right: 10px;
                                                color: var(--blue-900);
                                            }

                                            & .content {
                                                color: var(--grey-800);
                                            }
                                        }
                                    }
                                }

                                &:last-child {
                                    border-bottom: none;
                                }
                            }
                        }

                        & .hubspot-integration-log-container {
                            width: 65%;

                            .body {
                                display: flex;

                                &-item {
                                    width: 50%;
                                    display: flex;
                                    flex-direction: column;
                                    row-gap: 0.5rem;

                                    .title {
                                        text-align: center;
                                        font-weight: 700;
                                    }

                                    .title-logs {
                                        color: var(--green-100);
                                        background-color: var(--green-050);
                                        border-radius: 13px 0 0 13px;
                                    }

                                    .title-errors {
                                        color: var(--red-100);
                                        background-color: var(--red-050);
                                        border-radius: 0 13px 13px 0;
                                    }

                                    .value {
                                        text-align: center;
                                        font-weight: 700;
                                        background-color: var(--grey-050);
                                    }

                                    .value-logs {
                                        border-radius: 13px 0 0 13px;
                                    }

                                    .value-errors {
                                        border-radius: 0 13px 13px 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
