.login-page {
    position: absolute;
    background-image: url('../../img/login-bg.png');
    background-size: cover;
    height: 100%;
    width: 100%;

    .form-wrapper {
        margin-top: 15%;
        margin-left: 15%;

        .login-form {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 20.444rem;
            border: 2px solid var(--grey-600);
            background: rgba(255, 255, 255, 0.15);
            /* Glassmorph 32blur */
            backdrop-filter: blur(32px);
            /* Note: backdrop-filter has minimal browser support */

            h1 {
                margin: 0.833rem 0;
            }

            .brand-title {
                font-weight: 700;
                color: var(--white);
                font-size: 1.222rem;
            }

            .subtitle {
                text-align: center;
                color: var(--yellow-200);
            }

            .description {
                width: 100%;
                text-align: left;
                color: var(--white);
                margin: 1rem 0;
                font-size: 0.667rem;
            }

            .input-wrapper {
                margin: 0.667rem 0 0 0;
                width: calc(100% - 0.882rem);

                .input {
                    width: 100%;
                }
            }

            .button {
                margin-top: 1.833rem;
                width: calc(100% - 0.882rem);

                &[disabled] {
                    opacity: 1;
                    color: var(--blue-050);
                    background: var(--yellow-500);
                }
            }

            .info-text {
                width: 100%;
                text-align: right;

                a {
                    font-weight: bold;
                    color: var(--white);
                    font-size: 0.667rem;
                    padding-right: 0.7rem;
                    text-decoration: none;
                }
            }

            .foot-note {
                color: var(--white);
                font-size: 0.667rem;
                margin-top: 1.111rem;

                a {
                    font-weight: bold;
                    color: var(--white);
                    padding-left: 0.444rem;
                    text-decoration: none;
                }
            }

            .re-captcha {
                margin: 0.8rem 0;
                transform: scale(1.02);
                margin-left: -0.4rem;
                -webkit-transform: scale(1.03);
                transform-origin: 0 0;
                -webkit-transform-origin: 0 0;
            }

            @media screen and (min-width: 1441px) {
                .re-captcha {
                    transform: scale(1.2);
                    margin-left: -3rem;
                    -webkit-transform: scale(1.19);
                    transform-origin: 0 0;
                    -webkit-transform-origin: 0 0;
                }
            }
        }
    }

    .message {
        font-weight: bold;

        &.success {
            color: var(--green-500);
        }

        &.error {
            color: var(--red-500);
        }
    }

    @media screen and (max-width: 768px) {
        .form-wrapper {
            margin-top: 33%;
            width: 100%;
            margin-left: 0;

            .login-form {
                max-width: unset;
            }
        }
    }

    .loading-span {
        width: 1.111rem;
        height: 1.111rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border: 0.278rem solid var(--yellow-500);
        border-bottom-color: transparent;
        position: relative;
        animation: spinner 1s linear infinite paused;
        -webkit-animation: spinner 1s linear infinite;
    }

    @keyframes spinner {
        to {
            transform: rotate(1turn);
            -webkit-transform: rotate(1turn);
            -moz-transform: rotate(1turn);
            -ms-transform: rotate(1turn);
            -o-transform: rotate(1turn);
        }
    }
}