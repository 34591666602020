.update-documet-modal {
    .line {
        position: relative;
        margin-bottom: 10px;

        input[type='text'] {
            background-color: #f0f0f0;
        }
    }

    .note-section {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-top: 15px;
        border-top: 4px solid #f0f0f0;
        margin-top: 15px;

        .info-icon {
            position: relative;
            width: 5%;
            svg {
                color: var(--grey-600);
            }
        }

        .info-text {
            position: relative;
            width: 95%;
            color: var(--grey-600);
            font-size: var(--body-text-size-md);
        }
    }
}
