div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.collapse-detail {
    .order-list-expand-item {
        border: 2px solid var(--red-500);
        border-top: 4px solid var(--blue-050);
        border-radius: 8px;

        & > .item-container {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;

            & > .section {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                & > .order-number,
                .section-title {
                    color: var(--blue-900);
                    font-weight: bold;
                }

                & > .total-price,
                .total-price {
                    color: var(--grey-800);
                    font-weight: bold;
                }
            }

            & > .row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                & > .column {
                    & > .title {
                        color: var(--grey-600);
                    }

                    & > .content {
                        color: var(--grey-800);
                    }

                    & > .unit-price {
                        color: var(--grey-600);
                    }
                }

                .section-title {
                    color: var(--blue-900);
                    font-weight: bold;
                }
            }
        }

        & > .section-spacing {
            padding-bottom: 0.833rem;
        }
    }

    .order-list-expand-item {
        //padding: 0.833rem;
        & > .order-list-expand-item-wrapper {
            padding: 24px 32px;

            .notes {
                display: flex;
                flex-direction: column;

                .title {
                    font-weight: 700;
                    font-size: var(--body-text-size-lg);
                }
            }
        }
    }
}

.expand-row {
    td {
        padding-left: 0 !important;
    }
}

.status-dropdown {
    .focus {
        border: none !important;
    }
}

.action-menu {
    display: flex;

    .action-button {
        height: 100%;
        padding: 0.6rem 0.5rem;
        border-radius: 0px;
        background: none;

        svg {
            width: 20px;
            height: 20px;
        }

        &:hover {
            background-color: var(--blue-050);
        }
    }

    .yellow-button {
        height: 100%;
        padding: 0.5rem;
        border-radius: 0px;
        background-color: var(--yellow-800);

        &:hover {
            background-color: var(--yellow-300);
        }
    }
}

.customer-order-table {
    .MuiTableRow-hover {
        th,
        td {
            height: 3.5rem !important;
        }
    }

    .table-line {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        .badge {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 1.75rem;
            height: 1.75rem;
            border-radius: 32px;
            font-size: var(--body-text-size-sm);
        }
    }
}
