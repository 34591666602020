.equipment-cart-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0.8rem;
    height: 16.85rem;
    border: 1px solid var(--blue-050);
    border-radius: 8px;
    width: 11.9rem;

    .equipment-info {
        display: flex;
        flex-direction: column;

        .name {
            font-size: var(--body-text-size-2xl);
            color: var(--blue-900);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .serial-number {
            font-size: var(--body-text-size-lg);
            color: var(--grey-600);
        }
    }

    .warranty-info {
        display: flex;
        flex-direction: column;

        .warranty {
            padding: 0 16px;
            border-radius: 18px;
            width: fit-content;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
        }

        .active {
            background-color: var(--green-100);
            color: #fff;
        }

        .expired {
            background-color: var(--red-100);
            color: #fff;
        }

        .no-info {
            background-color: var(--blue-100);
            color: var(--grey-600);
        }

        .exp {
            font-size: var(--body-text-size-lg);
            color: var(--grey-600);
        }

        .equipment-info-container {
            width: initial;
            display: flex;
            flex-direction: column;
            .name {
                font-size: var(--body-text-size-lg);
                color: var(--grey-800);
            }

            .info {
                font-size: var(--body-text-size-lg);
                color: var(--grey-600);
            }
        }
    }

    .equipment-detail {
        display: flex;
        flex-direction: column;
        align-content: flex-start;
        gap: 0.125rem;

        & > * {
            margin: 0;
            line-height: 1.375rem;
            font-weight: 400;
            font-size: 1rem;
        }

        &__manufacturer {
            color: var(--grey-800);
        }

        &__secondary {
            color: var(--grey-600);
        }
    }
}
