.searchPartsContainer {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    transition: all 0.3s ease-in-out;

    .searchEngineAndDropdownContainer {
        display: flex;
        flex-direction: row;
        margin-top: 10px;
        gap: 1rem;

        .customer-select-container {
            display: flex;
            flex-direction: column;
            row-gap: 0.5rem;
        }

        .block-dropdown-input {
            margin-right: 10px;
            width: 400px;
        }

        .purchase-without-customer {
            margin-right: 5px;
            display: flex;
            flex-direction: row;
            align-items: center;
            font-size: var(--body-text-size-sm);
            color: var(--grey-600);

            .checkbox-container {
                margin-right: 5px;
                transform: scale(0.8);
            }
        }

        .MuiAutocomplete-root {
            width: 25rem;
        }
    }

    .resultCount {
        position: absolute;
        left: 0;
        top: 0;
        color: #767676;
        font-size: 12.1px;
    }
}

.emptySearchParts {
    align-items: center !important;
    padding-top: 100px;
}

//.block-dropdown-input-container .input-wrapper .input {
//    background-color: white;
//}
