.activity-contract-container {
    position: relative;

    .MuiAutocomplete-root {
        width: 100%;
        label {
            font-size: var(--body-text-size-sm) !important;
        }
    }

    .selected-contract-cart {
        display: flex;
        position: relative;
        width: 98%;
        height: auto;
        padding: 5px;
        border: 1px solid #f3f3f3;
        border-radius: 5px;
        margin-top: 15px;
        align-items: center;
        justify-content: space-between;

        .contract-title {
            color: #212121;
            font-size: var(--body-text-size-md);
        }

        .contract-number {
            font-size: var(--body-text-size-sm);
            color: #767676;
        }

        .unlink-button-wrapper {
            display: flex;
            width: 3rem;
            min-height: 3rem;
            justify-content: center;
            align-items: center;
            border-left: 2px solid #f5f6f7;

            .unlink-button {
                border-radius: 50%;
                width: 30px;
                height: 30px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                &:hover {
                    background-color: #fbe9e7;
                }

                svg {
                    color: red;
                    transform: scale(0.8);
                }
            }
        }
    }
}
