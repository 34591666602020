.product-info-cart-container {
    display: flex;
    padding: 2rem;
    justify-content: space-around;
    background-color: white;
    border-radius: 16px;
    flex-direction: row;
    height: 8.25rem;
    width: 100%;

    .status-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0.2rem 0.5rem;
        border-radius: 18px;
        width: fit-content;
        border: solid 0.5px;

        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 0px;

        width: 75px;
        height: 30px;

        /* Alerts/Green/100 */

        border-radius: 18px;

        /* Inside auto layout */

        flex: none;
        order: 0;
        flex-grow: 0;
    }
    .badge-offer {
        border-color: #4280cb;
        color: white;
        background: #4280cb;
    }
    .badge-active {
        color: white;
        border-color: #459b68;
        background: #459b68;
    }
    .badge-expired {
        color: white;
        border-color: #d84315;
        background: #d84315;
        width: 85px;
    }

    .divider {
        width: 1px;
        background-color: var(--grey-100);
        margin: 0.5rem 0;
    }

    .group {
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
    }

    .left-badge-offer {
        border-color: #4280cb;
        border-style: solid;
        border-width: 2px;
        border-left-width: 13px;
    }

    .left-badge-active {
        border-color: #459b68;
        border-style: solid;
        border-width: 2px;
        border-left-width: 13px;
    }

    .left-badge-expired {
        border-color: #d84315;
        border-style: solid;
        border-width: 2px;
        border-left-width: 13px;
        .content {
            font-size: var(--body-text-size-sm);
        }
    }

    .left {
        justify-content: center;
        padding: 10px;
        width: 25.3%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mid {
        justify-content: center;
        width: 25.3%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1.25rem;
        .item {
            display: flex;
            flex-direction: column;
        }
    }

    .right {
        justify-content: center;
        width: 25.3%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        font-size: var(--body-text-size-sm);
        color: var(--blue-900);
        font-weight: 700;
    }

    .content {
        display: flex;
        flex-direction: column;
        color: var(--grey-800);
        text-align: center;

        .amount {
            font-size: 3rem;
            line-height: normal;
        }

        .unit {
            line-height: normal;
            font-size: var(--body-text-size-md);
        }
    }

    .dot {
        background-color: var(--yellow-800);
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }

    .add-button {
        font-size: var(--body-text-size-sm);
        color: #5f9074;
        text-decoration: underline;
        cursor: pointer;
    }
}
