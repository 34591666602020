.mapping-control {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: relative;
    overflow-x: auto !important;

    .mapping-container {
        position: relative;

        .mapping-cursor {
            cursor: pointer;
        }

        .add-mapping-cursor {
            cursor: url('../../../public/mapping-cursor.svg') 12 12, pointer;
        }

        & > img {
            position: absolute;
            z-index: 0;
            max-width: 1000px;
            max-height: 1000px;
        }

        & > .context-menu {
            display: none;
            position: absolute;
            width: 7.5rem;
            background: white;
            box-shadow: 0 0 5px grey;
            border-radius: 1rem;
            z-index: 3;
        }
    }
}
