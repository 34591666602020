.technician-view {
    height: calc(100% - 12px);
    border: 6px solid var(--yellow-300);
    border-radius: 0;

    &.technician-view-as {
        border: 6px solid var(--green-300);

        .technician-info-bar {
            background-color: var(--green-300);
        }
    }

    &.technician-purchase-as {
        border: 6px solid var(--yellow-300);

        .technician-info-bar {
            width: 100%;
            background-color: var(--yellow-300);
        }
    }

    .technician-info-wrapper {
        display: flex;
        justify-content: space-between;
        margin-top: -1px;
        margin-right: -1px;

        .technician-info-header {
            margin: 10px 0 0 40px;
            display: flex;
            align-items: center;
            gap: 10px;

            &-title-container {
                display: flex;
                flex-direction: column;
            }

            .technician-view-subTitle {
                font-weight: 700;
                font-size: var(--body-text-size-md);
                color: var(--blue-900);
                margin-top: 0.5rem;
                cursor: pointer;

                & :hover {
                    color: var(--yellow-300);
                }
            }

            .technician-view-title {
                font-weight: 700;
                font-size: 24.33px;
                line-height: 27px;
            }

            .technician-view-description {
                font-size: 1.15rem;
                color: var(--grey-600);
                display: flex;
            }
        }

        .technician-info-bar {
            display: flex;
            gap: 20px;
            align-items: center;
            padding: 0 20px 6px;
            height: 44px;
            justify-content: space-between;

            .left {
                display: flex;
                gap: 0.5rem;
            }

            .right {
                display: flex;
                gap: 20px;
            }

            & .close-button {
                display: flex;
                align-items: center;
            }
        }

        .technician-save-wrapper {
            cursor: pointer;
            width: 40px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #ffff;
            color: var(--blue-900);
            border-radius: 6px;
            padding: 0;

            svg {
                width: 18px;
                height: 18px;
            }
        }

        .technician-cart-wrapper {
            width: 40px;
            height: 40px;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            background-color: var(--blue-900);
            color: var(--blue-100);
            cursor: pointer;
            padding: 0;

            .counter-dot {
                position: absolute;
                display: flex;
                justify-content: center;
                align-items: center;
                top: -7px;
                right: -7px;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                background-color: var(--red-500);
            }
        }
    }

    .technician-view-content {
        overflow-y: auto;
        height: calc(100vh - 160px);
    }
}

.quick-container {
    display: flex;
    flex-direction: column;
    border: 4px solid var(--yellow-300);
    height: -webkit-fill-available;

    & > .header {
        min-height: 3.5rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 2.5rem;
        background-color: var(--yellow-300);

        .header-item {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            .close-button {
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 1rem;
                    height: 1rem;
                }
            }

            .title {
                color: var(--grey-900);
                font-size: var(--body-text-size-md);
            }

            .quick-cart-button-container {
                position: relative;

                .quick-cart-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 2rem;
                    height: 2rem;
                    padding: 0;
                }

                .order-counter {
                    position: absolute;
                    top: -6px;
                    right: -6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--red-100);
                    border-radius: 50%;
                    height: 1.25rem;
                    width: 1.25rem;
                    font-size: var(--body-text-size-sm);
                    color: var(--blue-100);
                    font-weight: 700;
                }
            }
        }
    }

    .content {
        overflow-y: auto;
        display: flex;
        height: -webkit-fill-available;

        .content-children {
            width: -webkit-fill-available;
        }
    }
}
