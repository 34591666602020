$typeContainerPadding: 1rem;

.partial-update {
    background-color: white;
    padding: $typeContainerPadding;
    height: max-content;
    width: 90%;
    overflow: visible;
    border-radius: 1.25rem;

    .cart {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        height: 100%;

        .checklist-line {
            margin-top: 35px;
        }

        .checklistContainer {
            border-radius: 0;
        }

        &-header {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .activity-title-input {
                width: 100%;

                input:disabled {
                    opacity: 1 !important;
                }

                &.filled-input {
                    & > input {
                        &:not(:focus) {
                            font-size: var(--body-text-size-lg);
                        }

                        &:focus {
                            font-size: var(--body-text-size-lg);
                        }
                    }
                }
            }
        }

        .divider {
            position: relative;
            width: calc(100% + 2rem);
            border-bottom: 4px solid #f5f6f7;
            margin-block: 5px;
            margin-left: -1rem;
        }

        .delete-todo:hover {
            background-color: #a2adbb !important;
        }

        .icon-section {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-top: 3px;
            padding-bottom: 0.5rem;

            &-left-side {
                display: flex;
                flex-direction: row;
                align-items: center;
                position: relative;
            }

            &-icons {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                display: flex;
                flex-direction: row;
                align-items: center;
                z-index: 4;

                span {
                    margin-inline: 3px;
                    position: relative;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    transition: background-color 0.2s ease-in-out;

                    &:hover {
                        background-color: #a2adbb;
                    }

                    &:nth-child(2) svg {
                        transform: scale(1.2);
                    }

                    svg {
                        color: gray;
                    }
                }
            }
        }

        .date-section {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .react-datepicker-popper {
                z-index: 10000 !important;
            }

            .activity-frequency-container {
                color: #a2adbb !important;

                svg {
                    color: #a2adbb !important;
                }
            }
        }

        .customer-selection {
            position: relative;
            width: 100%;

            input[type='text']:disabled {
                background-color: white !important;
            }

            .MuiOutlinedInput-root {
                background-color: white !important;
            }
        }

        .activity-assignee-container {
            .assignee-name {
                font-size: var(--body-text-size-md);
            }

            .assignee-options {
                width: 207px;
                overflow-x: hidden;
                background-color: white;

                .assignee-option {
                    padding: 5px;
                }
            }

            .selected-one {
                transition: none;

                &.focused-dropdown.enabled-dropdown {
                    background-color: white !important;
                    border: 2px solid #fdc00e !important;
                }
            }
        }

        .vertical-seperator {
            width: 0;
            height: 30px;
            border-right: 2px solid #f5f6f7;
            position: relative;
        }

        &-body {
            height: 80%;
            max-height: 21rem;
            overflow-y: auto;
            overflow-x: visible;
            margin-bottom: 5px;
            padding-right: 0.5rem;

            .divider {
                width: 100%;
            }
        }

        &-footer {
            border-top: 4px solid var(--yellow-800);
            padding-top: 10px;
            margin-inline: -$typeContainerPadding;
            padding-inline: $typeContainerPadding;

            & .modal-line {
                display: flex;
                column-gap: 0.75rem;

                button {
                    width: -webkit-fill-available;
                }

                button.tertiary {
                    border: 1px solid var(--yellow-800);
                }
            }
        }
    }
}
