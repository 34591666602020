.inProgress {
    color: #354a63;
    background: #eefbf3;
    border: 1px solid #d0e6d9;
}

.onHold {
    color: #354a63;
    background: #fff5eb;
    border: 1px solid #ffcb9b;
}

.open {
    color: #354a63;
    background: #e0eeff;
    border: 1px solid #bdd2ec;
}

.closed {
    color: #354a63;
    background: #f5f6f7;
    border: 1px solid #e7e7e7;
}

.task-cart-container {
    padding: 0.25rem;
    border-radius: 8px;
    border: none;
    width: 16.68rem;

    .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: white;
        border-radius: 8px;
        padding: 1rem;
        padding-bottom: 0;
        color: var(--grey-800);
        height: 7rem;

        .description {
            font-size: var(--body-text-size-lg);
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }

        .footer {
            display: flex;
            justify-content: space-between;
            font-size: var(--body-text-size-sm);
            color: var(--grey-600);
        }
    }

    .footer {
        display: flex;
        justify-content: space-between;
        padding: 0.5rem 0;

        .status {
            border-radius: 18px;
            background-color: white;
            padding: 0 0.5rem;
        }

        .date-info {
            display: flex;
            align-items: center;
            color: var(--grey-800);
            font-size: var(--body-text-size-md);
            column-gap: 0.5rem;
            padding: 0 0.5rem;
        }

        .red {
            span {
                color: var(--red-600);
            }
        }
    }
}
