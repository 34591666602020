.dropdownWithChecklistContainer {
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 10px;

    & > .dropdownWithChecklistSearchContainer {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        height: auto;
        background-color: white;
        border: 0.056rem solid var(--grey-100);
        border-radius: 0.444rem;

        input[type='text'] {
            position: relative;
            width: 80%;
            height: 40px;
            outline: none;
            border: none;
            background-color: white;
            margin-inline: 10px;
            font-size: var(--body-text-size-sm);
        }

        .chevronDown {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 20%;
            height: 40px;
        }

        .placeholder {
            position: absolute;
            left: 10px;
            top: -5px;
            z-index: 2;
            font-size: 0.8rem;
            color: var(--grey-600);
            pointer-events: none;
        }
    }

    .saveContainer {
        position: absolute;
        top: 0;
        right: 0;
        width: 9%;
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .saveCircle {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            width: 35px;
            height: 35px;
            border-radius: 50%;
            background-color: white;
            box-shadow: 0 0 5px var(--grey-100);

            &:hover {
                background-color: #a2adbb;
            }
        }
        .disabled {
            border: 1px solid transparent;
            pointer-events: none;
            background: none;
            path {
                fill: var(--grey-100);
            }
        }
    }

    .searchList {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 50px;
        max-height: 200px;
        background-color: white;
        box-shadow: 0 0 5px #80354a63;
        overflow-y: auto;
        border-radius: 5px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);

        .searchListItem {
            position: relative;
            width: 95%;
            display: flex;
            flex-direction: row;
            align-items: start;
            justify-content: flex-start;
            min-height: 40px;
            margin-inline: 5px;
            padding: 0.5rem;

            .checkbox-container {
                margin-top: 5px !important;
            }

            &.with-sub-tex {
                margin-block: 10px;
            }

            &:hover {
                background-color: #f1f1f1;
                cursor: pointer;
            }

            .product-option-label {
                margin-inline: 10px;
                display: flex;
                flex-direction: column;
                flex: 1;
                overflow-x: hidden;
                white-space: nowrap;
                position: relative;

                .product-option-sub-text {
                    font-size: var(--body-text-size-xs);
                }
            }

            .input-wrapper {
                width: 20%;
                min-height: 45px;

                .input-sm {
                    min-height: 45px;
                    padding-inline: 5px;
                    font-size: 0.8rem !important;
                    padding-top: 1rem !important;

                    &:not(:placeholder-shown) ~ .label {
                        top: 0.1rem !important;
                    }

                    + .label {
                        left: 0.444rem;
                        top: 0.333rem !important;
                    }
                }
            }
        }
    }
}

.focusedDropdown {
    border: 0.056rem solid var(--yellow-100) !important;
    width: 90% !important;
}
