.notification-popup-wrapper {
    position: relative;

    .notification-info-message {
        color: var(--grey-600);
        display: flex;
        gap: 1rem;
        align-items: center;
        justify-content: center;
    }

    .openBell {
        background-color: #0e1f33;

        .icon {
            color: white;
        }

        .counter {
            background-color: #ffab91 !important;
        }
    }

    .notification-icon-wrapper {
        position: relative;
        width: 50px;
        height: 50px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: 8px;

        &:hover {
            background-color: #f5f6f7;

            .icon {
                color: var(--blue-900);
            }
        }

        .counter {
            position: absolute;
            left: 45%;
            top: 22%;
            background-color: var(--red-500);
            border-radius: 50%;
            height: 1px;
            width: 1px;
            transform: scale(0.5);
        }
    }

    .notification-panel {
        margin-top: 10px;
        position: absolute;
        background-color: white;
        border-radius: 6px;
        min-height: 100px;
        min-width: 360px;
        box-shadow: 0 0 12px rgba(53, 74, 99, 0.5);
        right: 0;
        z-index: 2;

        &-body {
            max-height: 500px;
            overflow-y: auto;

            .notification-item {
                width: 370px;
                font-size: 16px;
                padding: 10px;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: stretch;

                .notificationItemLeftSide {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    height: auto;
                    position: relative;
                    width: 10%;

                    .redDot {
                        position: relative;
                        width: 20px;
                        height: 20px;
                        background-color: #d84315;
                        border-radius: 50%;
                        margin-top: 5px;
                    }
                }

                .notificationItemMiddleSide {
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    position: relative;
                    height: auto;
                    width: 75%;

                    .notification-title {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        color: #212121;
                        font-weight: 700;
                        font-size: 16px;
                        width: 100%;

                        .date-info {
                            font-size: 12px;
                            color: #767676;
                        }
                    }

                    .readTitle {
                        color: #767676;
                    }

                    .notification-description {
                        text-align: left;
                        font-size: 14px;
                        font-weight: 400;
                        color: #767676;
                        cursor: default;
                    }
                }

                .notificationItemRightSide {
                    display: flex;
                    align-self: center;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    height: auto;
                    position: relative;
                    width: 15%;

                    .notification-button-wrapper {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                    }

                    .notification-button {
                        cursor: pointer;
                        height: 44px;
                        width: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        &:hover {
                            background-color: #a2adbb;
                        }
                    }
                }

                &:hover {
                    .notificationItemLeftSide,
                    .notificationItemMiddleSide {
                        background-color: var(--grey-050);
                    }
                }
            }
        }
    }
}
