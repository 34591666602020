.searchable-dropdown-container {
    width: 100%;

    .MuiAutocomplete-root {
        width: 100%;
    }
    .MuiFormLabel-root {
        &.MuiInputLabel-root {
            font-size: var(--body-text-size-xs);
        }
    }

    .css-1q60rmi-MuiAutocomplete-endAdornment {
        top: 18% !important;
    }

    .MuiAutocomplete-root .MuiFormControl-root {
        .Mui-disabled {
            background: var(--blue-100);

            input.Mui-disabled {
                background: var(--blue-100) !important;
            }
        }

        .MuiInputLabel-root {
            .MuiInputLabel-asterisk,
            .MuiFormLabel-asterisk {
                color: red;
            }
        }
    }
    .clear-button {
        display: flex;
        align-items: center;
    }
}
