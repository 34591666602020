.checklistItemContainer {
    position: relative;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    .checkbox-container {
        margin-left: 10px;
    }

    &:not(:last-child) {
        margin-bottom: 10px;
    }

    .listItemTextContainer {
        position: relative;

        font-size: var(--body-text-size-md);
        margin-left: 10px;
        padding-inline: 10px;
        border-radius: 5px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        &:hover {
            border: 1px solid #edeff2;
        }
    }

    .listItemInputContainer {
        position: relative;
        width: calc(100% - 120px);
        font-size: var(--body-text-size-md);
        margin-inline: 10px;
    }

    .delete-todo {
        &:hover {
            background-color: #fbe9e7 !important;
        }

        svg {
            color: var(--grey-600);
        }
    }

    .listItemButtonContainer {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-around;

        & > span {
            position: relative;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            &:hover {
                background-color: #a2adbb;
            }
        }

        svg {
            cursor: pointer;
        }
    }

    input[type='text'] {
        position: relative;
        width: 100%;
        border: 1px solid #fdc00e;
        outline: none;
        padding: 5px;
        border-radius: 10px;
    }
}
