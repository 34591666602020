.activity-description-note {
    border: 1px solid white;
    outline: none !important;
    width: calc(100% - 20px);
    border-radius: 10px;
    padding: 5px;
    resize: none;
    font-size: var(--body-text-size-md);
    min-height: 30px;
    padding-left: 1rem;

    &.enabled-textarea:hover {
        border: 1px solid #edeff2;
    }

    &.enabled-textarea:focus {
        border: 1px solid #fdd768;
        resize: vertical;
    }

    &.filled-input {
        padding-top: 0.4rem !important;
    }

    &.enabled-textarea.empty-input {
        &:not(:hover) {
            background-color: #f5f6f7;
            border: 2px dashed #a2adbb;
        }
    }
}
