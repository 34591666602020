.contract-list-modal {
    .shareWithCustomerSwitch {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding-right: 20px;
    }

    & .dropdown-line {
        display: flex;
        .customer-dropdown {
            width: 100%;
        }
        .MuiAutocomplete-root {
            width: 100%;
            .MuiOutlinedInput-input {
                background-color: white;
            }
        }
    }

    & .modal-line-title {
        color: var(--blue-900);
        font-size: var(--body-text-size-lg);
        font-weight: 700;
    }

    & .modal-line-subTitle {
        color: var(--grey-600);
        font-size: var(--body-text-size-md);
    }

    & .modal-line-iconTitle {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        color: var(--blue-900);
        font-size: var(--body-text-size-sm);
        margin-top: 1rem;
    }

    .border-line {
        background-color: var(--blue-100);
        border-radius: 4px;
        height: 4px;
        margin: 0.5rem 0;
    }

    .css-sghohy-MuiButtonBase-root-MuiButton-root {
        width: 10px !important;
        padding: 0 0 !important;
        min-width: 0 !important;
    }

    .uploaderButton {
        width: 30px !important;
        height: 30px !important;
        background-color: white;
        border: none !important;
        box-shadow: none !important;
        border-radius: 15px !important;

        &:hover {
            background-color: #9aa4b1 !important;
        }

        label {
            width: 10px !important;
            padding: 0 0 !important;
            min-width: 0 !important;
        }
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;
        margin: 0.3125rem 0;

        button {
            width: 100%;
        }

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
        }

        .textarea {
            background-color: white;
        }

        .datepicker-wrapper {
            width: 100%;
            position: relative;

            .react-datepicker-wrapper {
                .react-datepicker__input-container {
                    display: flex;
                }

                input {
                    width: 100%;
                    font-size: 0.8rem;
                    color: var(--grey-800);
                    background-color: white;
                    border: 0.056rem solid var(--grey-100);
                    padding: 1.3rem 0.667rem 0.5rem 0.667rem;
                    border-radius: 0.444rem;
                }
            }

            .label {
                top: 0.9rem;
                left: 0.667rem;
                color: var(--grey-600);
                font-size: 0.667rem;
                position: absolute;
                pointer-events: none;
                transition: 0.2s ease all;
                line-height: 1.75;
                font-weight: 400;
            }

            .focused-label {
                top: 0.278rem;
            }
        }

        .disabled {
            .react-datepicker-wrapper {
                input {
                    color: var(--grey-400);
                    background-color: #fdfdfd;
                }
            }

            .label {
                color: var(--grey-400);
            }
        }

        .checkbox-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 0.4rem;
        }

        .upload-file-container {
            display: flex;
            flex-direction: column;
            width: 100%;

            .upload-file-wrapper {
                display: flex;
                //gap: 0.75em;

                .upload-file-input {
                    .start-icon {
                        left: 0.478rem;

                        svg {
                            width: 1.5rem;
                            height: 1.8rem;
                        }
                    }
                }

                .upload-file-collapse {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 2.8rem;
                    width: 2.8rem;
                }
            }

            .file-items {
                display: flex;
                flex-direction: column;

                .item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    font-size: var(--body-text-size-lg);
                    position: relative;

                    .close-button {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        opacity: 0;
                        height: 2.5rem;
                        width: 2.5rem;
                    }

                    &:hover .close-button {
                        opacity: 1;
                    }

                    .wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                        padding: 0.41rem 0;
                        border-bottom: 2px solid var(--blue-100);

                        svg {
                            width: 1rem;
                            height: 1rem;
                            cursor: pointer;
                        }

                        .left {
                            position: relative;
                            width: 80%;
                            display: flex;
                            align-items: center;

                            span {
                                width: 90%;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                                line-height: normal;
                            }
                        }

                        .right {
                            display: flex;
                            align-items: center;
                        }
                    }

                    .border-line {
                        width: calc(100% - 1.5rem);
                        height: 2px;
                        background: var(--blue-100);
                        position: absolute;
                        bottom: -0.61rem;
                        right: 0;
                    }
                }
            }
        }
    }

    .checkbox-wrapper {
        display: flex;
        column-gap: 0.5rem;
    }
}

.update {
    width: 95%;
    overflow: hidden;

    .modal-body {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: calc(100vh - 10.333rem);

        .left-container {
            width: 63%;
            overflow: auto;
            padding-right: 0.5rem;
        }

        .right-container {
            width: 35%;
            height: inherit;
            padding-right: 0.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .right-container-content {
                position: relative;
                width: 100%;
                overflow: auto;
                height: inherit;
            }

            .right-container-content + .modal-line {
                border-top: 2px solid var(--yellow-800);
                padding-top: 1rem;
            }

            .cart {
                border: 1px solid var(--grey-050);
                border-radius: 8px;
                padding: 0 4px;
                margin-bottom: 0.5rem;

                .divider {
                    border: 2px solid var(--blue-100);
                    border-radius: 4px;
                    background-color: var(--blue-100);
                }

                &-header {
                    padding: 1rem;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .cart-title {
                        font-size: var(--body-text-size-lg);
                        font-weight: 700;
                        color: var(--blue-900);
                    }
                }

                &-body {
                    padding: 1rem;

                    .cart-title {
                        font-size: var(--body-text-size-lg);
                        font-weight: 700;
                        color: var(--blue-900);
                    }
                }
            }

            .action-button {
                padding: 0.5rem;
            }

            .checkbox-wrapper {
                padding: 0.5rem 4px;

                .deleteIcon {
                    position: absolute;
                    right: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    .monittor-table-container {
        background: none;
        padding-bottom: 5px;

        .MuiTable-root {
            border-collapse: collapse;
            border-spacing: 0 2px;
        }
    }
}

.delete-contract-modal {
    .modal-body {
        height: auto;
    }
}

.update-info-modal {
    .modal-body {
        height: auto;
        display: flex;
        flex-direction: column;
        row-gap: 1rem;

        label {
            display: flex;

            span {
                margin-left: 0.5rem;
            }
        }
    }
}

.upload-file-modal {
    .modal-body {
        height: auto;

        .modal-container {
            width: 100%;
        }
    }
}
