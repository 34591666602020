.edit-product-modal {
    .modal-body {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
    }
    .modal-line {
        display: flex;
        width: 100%;
        justify-content: space-between;
        gap: 0.75rem;
        .input-wrapper {
            width: 100%;
        }
    }
    .upload-image {
        .modal-line {
            margin-top: 1.5rem;
        }
    }

    .documents {
        gap: 0.5rem;
        &__title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: var(--body-text-size-xl);
            padding: 1rem 0;
            svg {
                height: 1rem;
                width: 1rem;
            }
        }
        &__search .input {
            border-radius: 0.5rem;
        }
        &__action {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            color: var(--blue-900);
            font-weight: 700;
        }
        &__uploaded-documents {
            display: flex;
            flex-direction: column;
            color: var(--blue-900);
            font-weight: 700;
            font-size: var(--body-text-size-sm);
            .documents__list {
                display: flex;
                flex-direction: column;
                height: 8rem;
                overflow-y: scroll;
                color: var(--grey-800);
                font-weight: 400;
                &__item {
                    width: 93%;
                    padding: 0.5625rem 1rem;
                    border-bottom: 1px solid var(--grey-050);
                    font-size: var(--body-text-size-md);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }
            }
        }
    }
}
