.equipment-drawing-parts-container {
    margin-top: 0.5rem;

    .parts-container {
        display: flex;
        flex-direction: column;
    }

    .addPart-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3rem;
        padding: 0;
        margin-right: 5px;
    }

    .parts-actions-buttons {
        display: flex;
        justify-content: right;
        width: 50%;
    }

    .equipment-detail__drawing-parts {
        display: flex;
        justify-content: space-between;
        margin-bottom: 1.25rem;

        .parts-actions-buttons {
            gap: 0.3rem;
        }

        & .equipment-detail-select-list-dropdown {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;
            width: 40%;

            input {
                width: 100%;
            }

            .basic-dropdown-container {
                min-width: 50%;
            }

            .input-wrapper {
                min-width: 50%;
            }
        }

        & .small-width {
            .basic-dropdown-container {
                min-width: 70%;
            }

            .input-wrapper {
                min-width: 70%;
            }
        }
    }

    .list-wrapper .MuiTableContainer-root table.MuiTable-root {
        padding: 0;
    }
}

.hortizal-row {
    display: flex !important;
    justify-content: space-between;

    .drawing-container {
        width: calc(55% - 0.75rem);

        .drawing-dashboard-container {
            position: sticky;
            top: 0;
        }
    }

    .parts-table-container {
        width: calc(45% - 0.75rem);
    }
}
