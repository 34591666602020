.customer {
    &__product {
        display: flex;
        justify-content: space-between;
        gap: 1.31rem;
    }
}

.customer-detail-page-header {
    background-color: #d6ead8;
    margin: -36px;
    margin-top: calc(-0.889rem - 36px);
    padding: 1.5rem;
    margin-bottom: 2rem;
    padding-bottom: 0;

    .customer-info-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 2.5rem;

        .left {
            display: flex;
            flex-direction: column;

            .company-name {
                font-size: var(--body-text-size-2xl);
            }

            .manager-info {
                display: flex;
                align-items: center;
                column-gap: 0.5rem;

                .name {
                    font-size: var(--body-text-size-md);
                    color: var(--blue-900);
                    font-weight: 700;
                }

                .underLine {
                    font-size: var(--body-text-size-sm);
                    font-weight: 400;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .other {
                    font-size: var(--body-text-size-sm);
                    color: var(--grey-600);

                    span:after {
                        content: ' • ';
                    }

                    & :last-child:after {
                        content: none;
                    }
                }
            }
        }
    }

    .add-contract {
        margin-left: 10px;

        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0.2rem;
        }
    }

    .company-status-wrapper {
        .button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            margin-bottom: 0.2rem;
        }
    }

    .input-wrapper {
        .input {
            height: 2.5rem;
        }
    }

    .tab-container {
        .tabs {
            margin-bottom: 0;
        }

        .search-wrapper {
            display: flex;
            margin: 0 1rem;
        }

        .monittor-table-container {
            padding-bottom: 80px;

            .custom-button {
                button {
                    max-height: 30px;
                    padding: 0 !important;
                }
            }
        }
    }
}

.activity-right-buttons {
    display: flex;
    flex-direction: row !important;
    justify-content: flex-end;
    column-gap: 0.5rem;
    margin-bottom: 10px;

    .historicalLogSwitch {
        .Mui-checked .MuiSwitch-thumb,
        .Mui-checked + .css-1yjjitx-MuiSwitch-track {
            background-color: gold !important;
        }
    }

    .block-dropdown-input {
        width: 30%;
    }

    .add-task {
        height: 2.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
            margin-right: 10px;
        }
    }
}

.activityContent {
    display: flex;
    justify-content: space-between;

    .activity-table {
        width: 100%;
    }

    .onBox {
        width: 68%;
    }

    .partial-update-box {
        height: calc(100vh - 280px);
        display: flex;
        width: 30%;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        right: 1rem;
    }
}

.filter-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    border: 2px solid var(--yellow-100);
    background-color: transparent;
}

.filter-menu {
    display: flex;
    justify-content: flex-end !important;
    flex-direction: row;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
    column-gap: 0.5rem;

    .block-dropdown-input {
        width: 15% !important;
    }

    .MuiFormControl-root {
        width: 12rem;
    }
}

.monittor-table-container {
    margin-top: 0 !important;
}
