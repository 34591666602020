$containerWidth: 150px;
$containerHeight: 40px;
$space: 5px;

.activity-frequency-container {
    position: relative;
    width: $containerWidth;
    height: $containerHeight;
    overflow: visible;
    z-index: 3;

    .selected-one {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border-radius: calc($containerHeight / 2);

        svg {
            margin-right: 5px;
        }

        .enabled-dropdown {
            cursor: pointer;
        }

        .disabled-dropdown {
            cursor: default;
        }

        &.empty-freq {
            background-color: #f5f6f7;
            border: 2px dashed #a2adbb;
            color: #a2adbb;

            svg {
                opacity: 0.5;
            }
        }

        &.enabled-dropdown:hover {
            background-color: white;
            border: 1px solid #68737f;
            color: #68737f;

            svg {
                opacity: 1;
            }
        }

        &.enabled-dropdown.focused-dropdown {
            border: 1px solid #fdc00e;
        }
    }

    .frequency-side-option {
        width: calc($containerWidth * 1.8);
        height: auto;
        background-color: white;
        border-radius: calc($containerHeight / 2);
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);
        z-index: 2;
        transition: top 0.2s ease-in-out;
        overflow: hidden;

        &.right {
            position: absolute;
            left: calc(100% + $space);
            top: calc($containerHeight + $space);
        }

        &.left {
            position: absolute;
            right: calc(100% + $space);
            top: calc($containerHeight + $space);
        }

        .side-option-header {
            position: relative;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            padding-left: 10px;
            color: #767676;
            font-size: var(--body-text-size-sm);
            width: 100%;
        }

        .side-option-body {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 5px;
            color: #212121;
            font-size: var(--body-text-size-md);
            width: 100%;
            height: auto;

            .option-radio-group {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                height: auto;

                .MuiRadio-root {
                    transform: scale(0.9);
                }

                input[type='number'] {
                    position: relative;
                    margin-inline: 5px;
                    padding: 5px;
                    border: 1px solid #68737f;
                    border-radius: 5px;
                    width: calc($containerWidth / 2);
                    outline: none;
                }

                .datepicker-wrapper {
                    position: relative;
                    width: $containerWidth !important;
                    margin-left: 5px;
                    padding-inline: 15px;
                    border: 1px solid #68737f;
                    border-radius: 5px;

                    input[type='text'] {
                        border: none !important;
                        outline: none !important;
                    }

                    svg {
                        transform: scale(0.7) translateY(20%);
                    }
                }

                .react-datepicker {
                    transform: translateX(20%);
                }
            }

            .frequency-setter {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                height: auto;
                border-bottom: 1px solid #e7e7e7;
                padding-bottom: 5px;

                .basic-dropdown-container {
                    width: 50%;
                    position: relative;
                    transform: scaleY(0.8);
                }

                input[type='number'] {
                    position: relative;
                    margin-inline: 5px;
                    padding: 5px;
                    border: 1px solid #e7e7e7;
                    border-radius: 5px;
                    width: calc($containerWidth / 2);
                    outline: none;
                    height: 37px;
                    transform: scaleY(0.8);

                    &:focus {
                        border: 1px solid #fedf86;
                    }
                }
            }
        }

        .side-option-footer {
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;
            padding-block: 10px;
            border-top: 2px solid #f5f6f7;
            text-decoration: underline;
            font-size: var(--body-text-size-sm);

            .disabled-button {
                cursor: not-allowed !important;
            }

            .save-button {
                cursor: pointer;
                color: #0e1f33;
            }

            .cancel-button {
                cursor: pointer;
                color: #68737f;
            }
        }

        &.daily-option {
            top: calc($containerHeight * 2 + $space);
        }

        &.weekly-option {
            top: calc($containerHeight * 3 + $space);
        }

        &.monthly-option {
            top: calc($containerHeight * 4 + $space);
        }

        &.annually-option {
            top: calc($containerHeight * 5 + $space);
        }

        &.custom-option {
            top: calc($containerHeight * 6 + $space);
        }
    }

    .freq-options {
        position: relative;
        width: 100%;
        height: auto;
        overflow: visible;
        z-index: 2;
        margin-top: $space;
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);
        border-radius: calc($containerHeight / 2);

        .freq-option {
            position: relative;
            width: 100%;
            height: $containerHeight;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background-color: white;

            &:hover {
                background-color: #f5f6f7;
            }

            &.active-one {
                background-color: #f5f6f7;
            }

            &:first-child {
                border-radius: calc($containerHeight / 2) calc($containerHeight / 2) 0 0;
            }

            &:last-child {
                border-radius: 0 0 calc($containerHeight / 2) calc($containerHeight / 2);
            }
        }
    }
}
