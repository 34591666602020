.drawing-modal-upload-section {
    position: relative;
    width: 100%;
    height: 2.5rem;
    border-radius: 0.5rem;
    border: 1px solid #e7e7e7;
    margin-block: 0.665rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    span {
        position: relative;
        font-weight: 400;
        line-height: 18px;
        font-size: var(--body-text-size-sm);
        color: #767676;
        margin-left: 0.665rem;
    }

    .uploader-button {
        margin-right: 0.665rem;
        width: 0.667rem;
        height: 0.667rem;
        background-color: white;
        box-shadow: none !important;
        position: relative;
        overflow: visible;
        cursor: default;

        &.css-sghohy-MuiButtonBase-root-MuiButton-root {
            max-width: 0.667rem !important;
            width: 0.667rem !important;
        }

        &:hover {
            background-color: white;
        }

        svg {
            width: 0.667rem;
            height: 0.667rem;
            position: absolute;
            right: 0;
            cursor: pointer;
        }
    }
}
