.document-list-container {
    display: flex;
    flex-direction: column;

    .list-control {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;

        .title {
            font-size: var(--body-text-size-md);
            color: var(--blue-900);
            font-weight: 700;
        }
    }

    .document-list {
        display: flex;
        flex-direction: column;

        .document-title-container {
            padding-left: 0.5rem;
            border-bottom: 2px solid var(--blue-10);
            .title {
                font-size: var(--body-text-size-md);
            }
        }
    }
}
