.new-tree-view-button {
    .checked {
        box-shadow: 3px 3px 16px rgb(53 74 99 / 30%);
    }
    .button {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        padding: 1rem;
        &:hover {
            border: 1px solid #fdc00e;
        }
    }
}
