.requirements-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.25rem;
    background-color: white;
    width: 100%;

    .type-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.875rem;
        border-bottom: 4px solid var(--blue-10);
        padding: 0 1.25rem;
        margin: -1.25rem;

        .type-button {
            border: 1px solid var(--yellow-300);
            border-radius: 30px;
            background-color: white;
            width: 50%;
            margin: 0 -15px;
            color: black;
            white-space: nowrap;
        }

        .checked {
            z-index: 1;
            background-color: var(--yellow-300);
        }
    }

    .requirement-container {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 17.225rem);
        overflow: auto;
        margin-right: -0.5rem;
        padding-right: 0.5rem;

        .requirement-box {
            padding: 0.5rem 0;
            &:first-child {
                padding-top: 0;
                padding-bottom: 0.5rem;
            }

            &-title {
                font-size: var(--body-text-size-lg);

                & b {
                    color: var(--red-500);
                }
            }

            border-bottom: 1px solid #acaba9;

            .input {
                height: 2.6rem;
            }

            .autoSize {
                height: 3rem;
            }

            .label-wrapper {
                display: flex;
                column-gap: 1rem;

                span {
                    font-size: var(--body-text-size-lg);
                }
            }

            &:last-child {
                border-bottom: none;
            }
            .address.form-container {
                row-gap: 0.5rem;
                .form-container-line .input-wrapper {
                    input,
                    textarea {
                        margin-bottom: 0;
                    }
                    textarea {
                        margin-bottom: -10px;
                    }
                }
            }
        }
    }

    .send-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 4px solid var(--yellow-100);
        padding: 0 1.25rem;
        margin: -1.25rem;
        height: 5.25rem;

        .button {
            width: 100%;
            height: 2.5rem;
            background-color: var(--yellow-100);
        }
    }
}
