.current-order-preview {
    background-color: white;
    padding: 2.5rem;

    &-body {
        .header-title {
            .title {
                font-size: var(--body-text-size-md) !important;
            }
        }

        & > .item-container {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            width: 100%;

            & > .section {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                & > .order-number,
                .section-title {
                    color: var(--blue-900);
                    font-weight: bold;
                }

                & > .total-price,
                .total-price {
                    color: var(--grey-800);
                    font-weight: bold;
                }
            }

            & > .row {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;

                & > .column {
                    & > .title {
                        color: var(--grey-600);
                    }

                    & > .content {
                        color: var(--grey-800);
                    }

                    & > .unit-price {
                        color: var(--grey-600);
                    }
                }

                .section-title {
                    color: var(--blue-900);
                    font-weight: bold;
                }
            }
        }

        & > .section-spacing {
            padding-bottom: 0.833rem;
        }
    }

    .powered-by {
        display: flex;
        align-items: center;
        margin-top: 5rem;

        .powered {
            color: var(--grey-600);
            font-size: var(--body-text-size-sm);
            line-height: normal;
        }

        .logo {
            margin-left: -0.8rem;
        }
    }
}

.current-order-preview-body {
    padding: 1rem 0;
    border-bottom: 2px solid var(--blue-050);

    .header-title {
        .title {
            color: var(--grey-800);
            font-size: var(--body-text-size-md);
            font-weight: 700;
        }
    }

    .body {
        padding: 0 1rem;
    }

    .total-price {
        background-color: var(--blue-100);
        height: 3rem;
        margin-top: 1rem;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: var(--blue-900);

        .title {
            font-size: var(--body-text-size-md);
            margin-right: 0.5rem;
        }

        .value {
            font-weight: 700;
            font-size: var(--body-text-size-lg);
            margin-right: 0.5rem;
            color: var(--blue-900);
        }
    }
}

.current-order-preview-top {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    border-bottom: 2px solid var(--blue-050);

    .header-title-info {
        border-bottom: 2px solid var(--blue-050);

        .title {
            font-size: var(--body-text-size-lg);
            color: var(--blue-900);
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        .header-line {
            display: flex;
            flex-direction: row;
            line-height: normal;

            .title {
                font-size: var(--body-text-size-md);
                color: var(--grey-600);
                font-weight: 400;
            }

            .value {
                color: var(--grey-800);
                margin-left: 0.3rem;
                font-size: var(--body-text-size-md);
            }
        }
    }

    .address-line {
        display: flex;
        margin-bottom: 1rem;
        column-gap: 1rem;

        .content {
            width: 50%;

            .line {
                display: flex;
                column-gap: 6px;

                .title {
                    font-size: var(--body-text-size-md);
                    color: var(--blue-900);
                    font-weight: 700;
                }

                .value {
                    color: var(--grey-800);
                    font-size: var(--body-text-size-md);
                    line-height: normal;
                }
            }

            .notes-line {
                display: flex;
                flex-direction: column;

                .title {
                    font-size: var(--body-text-size-md);
                    color: var(--grey-600);
                    font-weight: 700;
                }

                .value {
                    margin-left: 0.3rem;
                    font-size: var(--body-text-size-md);
                    margin-left: 0.7rem;
                    line-height: normal;
                    color: var(--grey-800);
                }
            }
        }
    }
}

.current-order-preview-footer {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;

    .item {
        display: flex;
        flex-direction: column;
        padding: 0.5rem 0;

        .title {
            font-size: var(--body-text-size-md);
            color: var(--grey-600);
            font-weight: 700;
            margin-bottom: 0.5rem;
        }

        .item-body {
            display: flex;
            padding: 0 0.8rem;

            .line {
                width: 50%;
                display: flex;
                flex-direction: column;

                &-title {
                    color: var(--blue-900);
                    font-size: var(--body-text-size-md);
                    font-weight: 700;
                }

                .value {
                    margin-left: 0.3rem;
                    font-size: var(--body-text-size-md);
                    margin-left: 0.5rem;
                    color: var(--grey-800);
                }

                .attachment {
                    column-gap: 0.5rem;
                    display: flex;
                    background: #f5f6f7;
                    border-radius: 18px;
                    width: fit-content;
                    align-items: center;
                    padding: 0.1rem 0.5rem;
                    color: var(--grey-060);
                    cursor: pointer;
                }
            }
        }

        .notes-line {
            display: flex;
            flex-direction: column;
            margin-left: 0.8rem;
            margin-top: 0.8rem;

            .notes-title {
                font-size: var(--body-text-size-md);
                font-weight: 700;
                color: var(--grey-600);
            }

            .value {
                margin-left: 0.3rem;
                line-height: normal;
                font-size: var(--body-text-size-md);
                margin-left: 0.7rem;
                color: var(--grey-800);
            }
        }

        &:first-child {
            border-bottom: 1px solid var(--blue-050);
        }
    }
}

.order-item {
    padding: 0.75rem 0;
    border-bottom: 1px solid var(--blue-050);
    width: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 0;
        margin: 0;
        line-height: normal;

        &-name {
            color: var(--blue-900);
            font-weight: 700;
            font-size: var(--body-text-size-xl);
        }

        &-price-info {
            font-size: var(--body-text-size-md);

            .isWarranty {
                font-weight: 700;
                height: 1.2rem;
                text-align: right;

                & > span {
                    font-size: var(--body-text-size-lg);
                }

                .green {
                    color: var(--green-100);
                }

                .crossed-out {
                    margin-right: 5px;
                    text-decoration: line-through;
                    font-weight: 400;
                }
            }

            .unit-price {
                font-size: var(--body-text-size-lg);
                color: var(--grey-600);
            }
        }
    }

    .body {
        line-height: normal;
        padding: 12px;
        padding-top: 0;

        .top-line {
            width: 90%;
            display: flex;
            flex-direction: column;
            gap: 0.5rem;

            .row-item {
                display: flex;
                flex-direction: row;

                .line {
                    white-space: nowrap;

                    .title {
                        font-size: var(--body-text-size-md);
                        color: var(--grey-600);
                    }

                    .value {
                        margin-left: 0.3rem;
                        font-size: var(--body-text-size-md);
                        color: var(--grey-800);
                        margin-left: 10px;
                    }

                    .warranty {
                        line-height: 2rem;
                        padding: 4px 16px;
                        border-radius: 18px;
                        font-size: var(--body-text-size-md);
                    }

                    .active {
                        background-color: var(--green-100);
                        color: #fff;
                    }

                    .expired {
                        background-color: var(--red-100);
                        color: #fff;
                    }

                    .no-info {
                        background-color: var(--blue-100);
                        color: var(--grey-600);
                    }

                    strong {
                        color: var(--yellow-800);
                        margin: 0 10px;
                    }
                }
            }
        }

        .notes-line {
            display: flex;
            flex-direction: column;
            width: 100%;
            word-wrap: break-word;
            margin-top: 0.5rem;

            .title {
                font-size: var(--body-text-size-md);
                color: var(--grey-600);
                line-height: normal;
            }

            .value {
                margin-left: 0.3rem;
                font-size: var(--body-text-size-md);
                color: var(--grey-800);
                margin-left: 1rem;
                line-height: normal;
            }
        }
    }

    &:last-child {
        border-bottom: none;
    }
}
