.searchPartsTableBodyContainer {
    position: relative;
    width: 100%;
    margin-top: 5px;
    border-radius: 8px;
    overflow: hidden;
    height: auto;
    background-color: #ffffff;
    margin-bottom: 20px;

    .searchPartsTableMainRowContainer {
        &:not(:last-child) {
            border-bottom: 0.5px solid #f0f0f0;
        }

        &:not(:first-child) {
            border-top: 0.5px solid #f0f0f0;
        }
    }
}
