.cart-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.889rem 0.5rem;
    -webkit-animation: fadein 0.5s linear;
    animation: fadein 0.5s linear;

    .cart-left {
        width: 50%;

        .name {
            font-size: var(--body-text-size-sm);
            color: var(--blue-900);
            font-weight: bold;
        }

        .serial-number {
            font-size: var(--body-text-size-xs);
            color: var(--grey-600);
        }

        .description {
            font-size: var(--body-text-size-sm);
            color: var(--grey-600);
        }
    }

    .cart-right {
        display: flex;
        flex-direction: column;
        width: 50%;

        .price-info {
            text-align: right;
            font-size: var(--body-text-size-md);
            color: var(--grey-600);
            font-weight: 700;
        }

        .action {
            display: flex;
            align-items: center;
            justify-content: right;

            .counter {
                display: flex;
                justify-content: center;
                background-color: var(--blue-100);
                border-radius: 0.222rem;
                padding: 0.444rem;
                width: 4.444rem;

                .count-changer {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                input {
                    background-color: transparent;
                    border-width: 0;
                    width: 50%;
                    text-align: center;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type='number'] {
                    -moz-appearance: textfield;
                }
            }

            .remove-wrapper {
                display: flex;
                cursor: pointer;
            }
        }
    }

    @-webkit-keyframes fadein {
        0%,
        100% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes fadein {
        0%,
        100% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    &:last-child {
        border-bottom-width: 0;
    }
}
