.quick-cart-container {
    display: flex;
    flex-direction: column;
    min-width: 25rem;
    padding: 1rem;
    background-color: white;
    border-radius: 20px;
    min-height: 500px;
    position: sticky;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
    width: max-content;
    margin-top: 10px;

    .cart-header {
        display: flex;
        justify-content: space-between;

        .cart-title {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 0.5rem;
            font-size: var(--body-text-size-lg);

            svg {
                color: var(--yellow-300);
            }
        }

        .cart-close {
            margin-right: 1rem;

            svg {
                width: 1rem;
                height: 1rem;
                color: var(--grey-600);
            }
        }
    }

    .customer-info {
        .without-custmer-section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &-note {
                color: #767676;
                font-size: var(--body-text-size-sm);
            }

            .input-wrapper {
                position: relative;
                width: 100%;

                span.label {
                    color: #767676;
                    font-size: var(--body-text-size-sm);
                }
            }
        }

        span {
            font-size: var(--body-text-size-lg);
            color: var(--grey-600);
        }

        padding: 1rem;
        border-bottom: 4px solid var(--blue-10);
        margin-inline: -1rem;

        .select-customer-note {
            font-size: var(--body-text-size-sm);
            color: var(--grey-600);
            line-height: 18px;
        }

        .selected-customer-name {
            font-size: var(--body-text-size-sm);
            color: black;
            background-color: #a2cdb3;
            border-radius: 18px;
            padding: 5px;
        }
    }

    .cart-body {
        margin-top: 1rem;
        padding: 0.5rem;
        // border: 1px solid var(--yellow-300);
        border-radius: 13px;

        .title {
            font-size: var(--body-text-size-md);
            color: var(--blue-900);
            font-weight: 700;
        }

        .additional-input {
            display: flex;
            flex-wrap: wrap;
            row-gap: 0.5rem;

            .input-button-wrapper {
                width: 40%;
                display: flex;
                column-gap: 0.5rem;
            }
        }
    }

    .cart-footer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 1rem;
        padding: 0.5rem;
        // border: 1px solid var(--yellow-300);
        border-radius: 13px;

        .total-price {
            display: flex;

            &-title {
                color: var(--blue-900);
            }

            &-value {
                color: var(--blue-900);
                font-weight: 700;
            }
        }
    }
    .cart-add-more {
        max-width: 30rem;
        display: flex;
        .additional-input {
            display: flex;
            flex-wrap: wrap;
            column-gap: 3%;

            .line {
                width: 30% !important;
            }
        }
    }
}
