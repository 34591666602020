.block-dropdown-input {
    position: relative;
    display: inline-block;
    width: 100%;

    .notes {
        font-size: 12.1px;
        font-weight: 400;
        padding: 5px;
    }

    &-container {
        position: relative;

        & .input-wrapper {
            position: relative;
            width: 100%;
            display: flex;
            align-items: center;

            & > .down-icon {
                position: absolute;
                top: 10px;
                right: 30px;
                pointer-events: none;
            }

            & > .clear-icon {
                position: absolute;
                right: 10px;
                height: 1.7rem;
                width: 1.7rem;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;

                svg {
                    width: 14px;
                    height: 14px;
                    color: var(--grey-600);
                }
                &:hover {
                    background-color: var(--blue-050);
                }
            }

            & .input {
                width: 100%;
                color: var(--grey-800);
                border: 0.056rem solid var(--grey-100);
                box-sizing: border-box;
                border-radius: 0.444rem;
                font-size: var(--body-text-size-sm);

                &-white-bg {
                    background-color: white !important;
                }

                &-active {
                    border: 1px solid var(--yellow-80) !important;
                    background-color: var(--yellow-25) !important;
                }

                &-sm {
                    padding: 0.444rem;

                    &:focus ~ .label,
                    &:not(:placeholder-shown) ~ .label {
                        top: -0.889rem;
                    }

                    + .label {
                        left: 0.444rem;
                        top: 0.333rem;
                    }
                }

                &-md {
                    padding: 0.667rem;

                    &:focus ~ .label,
                    &:not(:placeholder-shown) ~ .label {
                        top: -0.167rem;
                    }

                    + .label {
                        left: 0.667rem;
                        top: 0.722rem;
                    }
                }

                &-lg {
                    height: 2.8rem;
                    padding: 1.222rem 1rem 0.4rem 1rem !important;

                    + .label {
                        left: 0.667rem;
                    }
                }

                &:disabled,
                &[disabled] {
                    opacity: 0.5;
                }
            }

            /*input:focus ~ .label,
      input:not(:placeholder-shown) ~ .label {
        top: 0.278rem;
      }*/

            &.start-input-icon {
                & .start-icon {
                    margin: auto;
                    position: absolute;
                    font-size: 0.611rem;
                    left: 0.778rem;
                    top: calc(50% - (0.611rem / 2.9));
                    transform: translateY(-50%);
                }

                & .focus-icon {
                    top: 55% !important;
                }

                & .input {
                    &-lg {
                        padding-left: 2.222rem !important;

                        ~ .label {
                            left: 2.222rem !important;
                        }
                    }
                }
            }

            & .label {
                top: 0.9rem;
                left: 1.111rem;
                color: var(--grey-600);
                font-size: 0.667rem;
                position: absolute;
                pointer-events: none;
                transition: 0.2s ease all;
            }

            &.end-input-icon {
                & .end-icon {
                    margin: auto;
                    position: absolute;
                    font-size: 0.611rem;
                    right: 0.778rem;
                    top: calc(50% - (0.611rem / 2.9));
                    transform: translateY(-50%);
                }

                & .input {
                    &-lg {
                        padding-right: 2.222rem !important;

                        ~ .label {
                            right: 2.222rem !important;
                        }
                    }
                }
            }

            & > .dropdown-input-transparent-container {
                background-color: transparent;

                & .dropdown-input-content {
                    margin-top: 0.556rem;
                    border-radius: 0.667rem;
                    background-color: white;
                    min-width: 100%;
                    box-shadow: 0 0.444rem 0.889rem 0 rgba(0, 0, 0, 0.2);
                    z-index: 3;
                    max-height: 290px;
                    overflow: auto;

                    & .search-line {
                        display: flex;
                        padding: 0.5rem;

                        .input-wrapper {
                            .start-icon {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                top: 28px;
                            }
                        }
                    }

                    & .item-content {
                        column-gap: 0.5rem;
                        cursor: pointer;
                        color: black;
                        padding: 0.667rem;
                        text-decoration: none;
                        display: block;
                        border-bottom: 0.056rem solid var(--yellow-500);

                        .filter-type {
                            display: flex;
                            align-items: center;
                            gap: 0.5rem;
                        }

                        .filter-status {
                            border-radius: 1.1rem;
                            padding: 0.25rem 0.5rem;
                        }
                    }

                    & .item-content:first-child:hover {
                        border-radius: 0.667rem 0.667rem 0 0;
                    }

                    & .item-content:last-child {
                        border-bottom: none;
                    }

                    .item-content:hover {
                        background-color: #f1f1f1;
                    }
                }
            }
        }
    }
}
