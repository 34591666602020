.page-wrapper {
    height: 100vh;
    padding: 0 0;
    align-items: center;

    a {
        text-decoration: none;
    }

    &-space {
        height: 94px;
        width: 100%;
    }

    .page-header {
        position: fixed;
        width: 100%;
        min-height: 64px;
        top: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: white;

        .header-left {
            padding-left: 30px;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-size: 16px;
            color: var(--blue-900);
            font-weight: 400;

            span {
                color: var(--grey-600);
            }

            h1 {
                font-size: 24px;
                color: var(--grey-800);
            }

            .left-bottom {
                display: flex;
                align-items: center;
                line-height: normal;
                padding-top: 0.2rem;

                .powered {
                    font-size: 12px;
                }

                .logo {
                    &-mini {
                        margin-left: -10px;
                        margin-top: -2px;
                    }

                    &-margin {
                        margin-bottom: -10px;
                        margin-left: -10px;
                    }
                }
            }
        }

        .header-right {
            text-align: center;
            padding-right: 50px;
            display: flex;
            align-items: flex-end;
            row-gap: 20px;

            .right-user-info {
                display: flex;
                flex-direction: column;

                span {
                    line-height: 20px;
                }

                .company-name {
                    color: var(--grey-800);
                    font-size: var(--body-text-size-lg);
                    font-weight: 700;
                }

                .user-name {
                    color: var(--grey-600);
                    font-size: var(--body-text-size-sm);
                }
            }

            .right-notification {
                svg {
                    width: 21px;
                    height: 26px;
                }
            }

            .cart-button-container {
                position: relative;

                .order-counter {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--red-100);
                    border-radius: 50%;
                    height: 1.25rem;
                    width: 1.25rem;
                    font-size: var(--body-text-size-sm);
                    color: var(--blue-100);
                    font-weight: 700;
                }
            }

            .header-button {
                width: 50px;
                height: 50px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                border-radius: 8px;

                .icon {
                    color: var(--grey-600);
                }

                .search {
                    width: 24px;
                    height: 24px;
                }

                &:hover {
                    background-color: #f5f6f7 !important;

                    .icon {
                        color: var(--blue-900) !important;

                        path {
                            fill: var(--blue-900) !important;
                        }
                    }
                }
            }

            .active {
                .header-button {
                    background-color: var(--blue-200);

                    .icon {
                        path {
                            fill: white;
                        }
                    }
                }
            }

            .divider {
                width: 2px;
                height: 48px;
                background-color: var(--blue-10);
            }
        }

        @media screen and (max-width: 768px) {
            padding: 0 20px;
        }
    }

    .page-breadcrumb {
        position: fixed;
        top: 69px;
        width: 100%;
        z-index: 1;
    }

    .page-content {
        display: flex;
        overflow-y: auto;
        height: calc(100vh - 94px);
        border-radius: 8px 8px 0px 0px;
        padding: 0;
        background-color: var(--blue-100);
        position: relative;

        .bottom {
            display: flex;
            height: 100%;

            .side-bar-container {
                display: flex;
                justify-content: flex-start;
                flex-direction: column-reverse;
                width: 3.8125rem;
                height: 100%;
                position: relative;

                .side-bar {
                    display: flex;
                    flex-direction: column;
                    height: calc(100vh - 94px);
                    background-color: #ffffff;
                    position: fixed;
                    width: 3.8125rem;

                    .divider {
                        background-color: var(--blue-100);
                        border-radius: 4px;
                        width: 90%;
                        height: 4px;
                        margin-bottom: 6px;
                    }

                    & .side-bar-button-wrapper {
                        width: 100%;
                        height: 100%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }

                    &-top {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-top: 0.5rem;
                        height: 70%;
                        row-gap: 1rem;

                        .buttons {
                            width: 2.75rem;
                            height: 2.75rem;
                            font-size: var(--body-text-size-xs);
                            text-transform: none;
                            font-weight: normal;
                            line-height: normal;
                            color: var(--grey-600);
                            background-color: transparent;
                            border-radius: 8px;
                            border: 1px solid white;
                            position: relative;

                            &:hover {
                                background-color: var(--blue-10);
                                color: var(--blue-900) !important;
                                border: 1px solid var(--grey-050);

                                & .tooltip-container {
                                    display: block !important;
                                }
                            }
                        }

                        .active {
                            background: var(--blue-200);
                            color: #ffffff;
                        }
                    }

                    &-bottom {
                        height: 30%;
                        display: flex;
                        flex-direction: column;
                        justify-content: flex-end;
                        align-items: center;
                        margin-bottom: 1rem;
                        row-gap: 0.5rem;

                        & > .buttons {
                            height: 1.75rem;
                            width: 3.25rem;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            flex-direction: column;
                            font-size: var(--body-text-size-xs);
                            text-transform: none;
                            font-weight: normal;
                            line-height: normal;
                            color: var(--grey-600);
                            background-color: transparent;
                            border-radius: 8px;
                            border: 1px solid white;

                            div {
                                display: flex;
                                align-items: center;
                            }

                            &:hover {
                                background-color: var(--blue-10);
                                color: var(--blue-900) !important;
                                border: 1px solid var(--grey-050);

                                & .tooltip-container {
                                    display: block !important;
                                    left: 4.2rem;
                                }
                            }

                            & svg {
                                width: 0.8rem;
                                height: 0.8rem;
                            }
                        }

                        .quick {
                            & svg {
                                width: 1.2rem;
                                height: 1.2rem;
                            }
                        }

                        .active {
                            background-color: #0e1f33;
                            color: var(--blue-100);
                        }

                        .cart-button {
                            width: 100%;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            padding: 0.5rem 0;

                            & > div {
                                display: flex;
                                align-items: center;
                                position: relative;
                            }

                            .cart-button-icon {
                                color: var(--grey-600);
                            }

                            .cart-button-chip {
                                text-align: center;
                                background-color: var(--red-600);
                                padding: 3px;
                                width: 0.5rem;
                                font-size: var(--body-text-size-xs);
                                border-radius: 40%;
                                color: #ffffff;
                                position: absolute;
                                top: 2px;
                                right: -7px;
                                line-height: normal;
                            }
                        }
                    }
                }
            }

            .children {
                -webkit-transition: all 0.5s ease-in-out;
                -moz-transition: all 0.5s ease-in-out;
                -o-transition: all 0.5s ease-in-out;
                transition: all 0.5s ease-in-out;
                width: calc(100% - 3.8125rem);
            }
        }
    }

    @media screen and (max-width: 768px) {
        margin: 0;
        padding: 0;

        .page-content {
            padding: 0;
        }
    }

    .cart-button-container {
        overflow: hidden;
    }

    .cart-button-wrapper {
        overflow: hidden;
        display: flex;
        justify-content: center;
    }

    .cart-content-container {
        right: 0px;
        width: 25%;
        z-index: 998;
        height: calc(100% - 94px);
        top: 94px;
        position: fixed;
        overflow: auto;
    }

    .cart-content-wrapper {
        overflow: inherit;
        height: -webkit-fill-available;
    }

    .page-main {
        width: 100%;
    }

    &.mobile {
        justify-content: space-between;

        .page-header {
            margin: 0;
            padding: 0;

            .header-right {
                gap: 8px;

                .cart-icon {
                    position: relative;
                    width: 50px;
                    height: 50px;

                    .cart-count {
                        position: absolute;
                        top: 5px;
                        right: 0;
                        background: var(--red-500);
                        color: var(--white);
                        padding: 4px;
                        border-radius: 50%;
                        height: 16px;
                        width: 16px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    svg {
                        position: absolute;
                        bottom: 5px;
                    }
                }
            }
        }

        .bell-icon-wrapper {
            position: relative;

            .red-dot {
                position: absolute;
                top: 5px;
                right: 0;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: var(--red-500);
            }
        }

        .menu-icon-wrapper {
            position: relative;

            .red-dot {
                position: absolute;
                top: -3px;
                right: -3px;
                height: 10px;
                width: 10px;
                border-radius: 50%;
                background-color: var(--red-500);
            }
        }
    }

    .drop-panel {
        background-color: var(--white);
        box-shadow: 1px 3px 8px rgb(53 74 99 / 30%);
        overflow: hidden;
        max-height: calc(100% - 88px);
        overflow: scroll;
    }

    .mobile-content-wrapper {
        max-height: calc(100% - 88px);
        overflow: scroll;
    }

    .menu-item {
        display: flex;
        padding: 16px;
        gap: 16px;
        cursor: pointer;
        border-bottom: 1px solid var(--blue-100);

        &-right {
            font-size: 18px;
        }
    }

    .animation {
        -webkit-transition: all 0.5s ease-in-out;
        -moz-transition: all 0.5s ease-in-out;
        -o-transition: all 0.5s ease-in-out;
        transition: all 0.5s ease-in-out;
    }

    .account-menu-wrapper {
        position: relative;

        .account-menu {
            position: absolute;
            background-color: var(--white);
            border: 2px solid var(--blue-100);
            right: 0;
            z-index: 1;
        }
    }
}

.tooltips {
    background-color: #0e1f33;
}
