$addNewAttachmentSize: 48px;

.activity-attachments-container {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;

    & + .uploaderButton {
        width: $addNewAttachmentSize;
        height: $addNewAttachmentSize;
        background-color: white;
        box-shadow: none !important;
        border: 2px dashed #a2adbb;
        background-color: #f5f6f7;
        border-radius: calc($addNewAttachmentSize / 3);
        margin-left: 12px;

        &.css-sghohy-MuiButtonBase-root-MuiButton-root {
            min-width: $addNewAttachmentSize !important;
        }

        svg {
            color: #68737f;
            opacity: 0.5;
            transform: scale(1.3);
        }

        &:hover {
            background-color: white;

            svg {
                opacity: 1;
            }
        }
    }

    .attachment-list-item {
        display: flex;
        flex-direction: row;
        position: relative;
        width: 100%;
        margin-bottom: 10px;
        border: 2px solid #f5f6f7;
        border-radius: 8px;
        overflow: hidden;
        padding-block: 3px;
        justify-content: space-between;

        .content-section {
            position: relative;
            width: 60%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-left: 5px;

            .file-name {
                color: #212121;
                font-size: var(--body-text-size-md);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }

            .file-description {
                color: #767676;
                font-size: var(--body-text-size-sm);
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box !important;
                -webkit-line-clamp: 1;
                -webkit-box-orient: vertical;
            }
        }

        .icon-section {
            position: relative;
            width: auto;
            padding: 0 0.5rem;
            border-left: 2px solid #f5f6f7;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            .edit-button-wrapper {
                &:hover {
                    background: none;
                }
            }

            .download-button,
            .edit-button,
            .delete-button {
                position: relative;
                width: 30px;
                height: 30px;
                border-radius: 50%;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }

            .download-button {
                &:hover {
                    background-color: #a2adbb;
                }
            }

            .delete-button {
                svg {
                    color: #d84315;
                    transform: scale(0.8);
                }

                &:hover {
                    background-color: #fbe9e7;
                }
            }

            .edit-button {
                &:hover {
                    background-color: #a2adbb;
                }
            }
        }
    }
}
