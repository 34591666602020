.mapping-ui {
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        justify-content: space-between;

        .mapping-circle-list {
            display: flex;
            align-items: center;
            gap: 0.5rem;

            .mapping-circle {
                height: 1.5rem;
                width: 1.5rem;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 0.25rem;
                border: 1px solid var(--grey-100);
                cursor: pointer;

                .circle {
                    height: 1rem;
                    width: 1rem;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                &.active {
                    border: 2px solid var(--yellow-100);
                }
            }
        }

        span {
            color: var(--grey-600);
            font-size: var(--body-text-size-sm);
        }
    }
}
