.checklistContainer {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
    border-radius: 10px;
    overflow: hidden;
    overflow-y: auto;

    .unCompletedOnes {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        background-color: #ffffff;
        padding-top: 25px;

        .addNewListItem {
            margin-top: 20px;
            text-decoration: underline;
            color: #5f9074;
            font-size: var(--body-text-size-md);
            cursor: pointer;
            margin-left: 10px;
            width: 50px;
            white-space: nowrap;

            svg {
                transform: scale(0.6);
            }
        }

        .add-new-todo-section {
            margin-bottom: 15px;
            display: flex;
            flex-direction: row;
            align-items: center;

            .add-new-todo {
                position: relative;
                flex: 6;
            }

            .add-new-todo-buttons {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                position: relative;
                flex: 1;

                span {
                    position: relative;
                    width: 30px;
                    height: 30px;
                    border-radius: 50%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    &:hover {
                        background-color: #a2adbb;
                    }

                    svg {
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .completedOnes {
        flex: 1;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        background-color: #f5f6f7;
        padding-block: 10px;

        .completedTaskTitle {
            margin-bottom: 20px;
            margin-left: 10px;
            color: #354a63;
            font-size: var(--body-text-size-lg);
        }
    }
}
