.equipment-installation-table {
    .equipment-name-container {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        .sub-icon {
            width: 1rem;
        }

        .name-info {
            display: flex;
            flex-direction: column;

            .sub-top-equipment-name {
                font-size: var(--body-text-size-sm);
                color: var(--grey-600);
            }
        }
    }
}
