.activity-customer-line-container {
    display: block;
    cursor: pointer;
    margin-bottom: 2.5rem;
    .value {
        font-size: var(--body-text-size-md);
    }
    .disabled {
        color: var(--blue-050);
    }
}
