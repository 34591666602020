.settings-page {
    & .content-box {
        & > .content-body {
            & > .content-container {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                @media only screen and (min-width: 1400px) {
                    & > :first-child {
                        width: 40%;
                    }

                    // & > :last-child {
                    //     width: 60%;
                    // }
                }

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    & > * {
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1400px) {
                    & > :first-child {
                        width: 50%;
                    }

                    // & > :last-child {
                    //     width: 55%;
                    // }
                }

                & .company-info-tab-container {
                    width: 100%;

                    & > .section {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 1rem 0.5rem;
                        border-bottom: 1px solid var(--grey-050);

                        & > .section-icon {
                            color: var(--blue-900);
                            margin-right: 0.667rem;
                            width: 1.25rem;
                            height: 1.25rem;
                        }

                        & > .section-container {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-direction: column;
                            width: 100%;

                            & > .title {
                                color: var(--blue-900);
                                font-size: var(--body-text-size-sm);
                            }

                            & > .content {
                                width: 100%;
                                padding: 0 0.417rem;
                                color: var(--grey-800);

                                & > .content-row {
                                    display: flex;
                                    flex-direction: column;

                                    & .title {
                                        padding-right: 10px;
                                        color: var(--blue-900);
                                    }

                                    & .content {
                                        color: var(--grey-800);
                                    }
                                }

                                & > .image-wrapper {
                                    position: relative;
                                    width: 5.25rem !important;
                                    height: 5.25rem !important;
                                    border-radius: 0.444rem;
                                    background-color: var(--grey-050);

                                    & .image-item {
                                        position: absolute;
                                        width: 5.25rem !important;
                                        height: 5.25rem !important;
                                        object-fit: cover;
                                        object-position: center;
                                        border-radius: 0.444rem;
                                    }

                                    & > .icon {
                                        cursor: pointer;
                                    }

                                    & > .image-add-icon {
                                        position: absolute;
                                        color: var(--light-500);
                                        width: 2.5rem;
                                        height: 2.5rem;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                    }

                                    & > .image-update-icon {
                                        position: absolute;
                                        color: var(--grey-600);
                                        background-color: rgba(255, 255, 255, 0.5);
                                        width: 1.5rem;
                                        height: 1.5rem;
                                        bottom: 0.15rem;
                                        right: 0.15rem;
                                        padding: 0.2rem;
                                        border-radius: 50%;

                                        &:hover {
                                            background-color: var(--blue-100);
                                        }
                                    }

                                    &:hover {
                                        background-color: var(--blue-100);

                                        & > .image-add-icon {
                                            color: var(--blue-500);
                                        }
                                    }

                                    & .file-input-area {
                                        position: absolute;
                                        width: 5.25rem !important;
                                        height: 5.25rem !important;
                                        cursor: pointer;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}
