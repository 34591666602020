.custom-equipment-info-cart-container {
    display: flex;
    padding: 2rem;
    justify-content: space-around;
    background-color: white;
    border-radius: 16px;

    .divider {
        width: 1px;
        background-color: var(--grey-100);
        margin: 0.5rem 0;
    }

    .warranty-container {
        display: flex;
        flex-direction: column;
        width: 27%;
        row-gap: 0.5rem;

        .warranty-box {
            border: 1px solid #a2cdb3;
            border-left: 8px solid #a2cdb3;
            width: 85%;
            min-height: min-content;
            height: 8rem;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 1.5rem;
        }

        .installation {
            display: flex;

            .title {
                font-size: var(--body-text-size-sm);
                width: 50%;
            }

            .value {
                font-size: var(--body-text-size-sm);
                width: 50%;
            }
        }

        .no-info {
            border: 1px solid var(--grey-400);
            border-left: 8px solid var(--grey-400);
        }

        .active {
            border: 1px solid var(--green-100);
            border-left: 8px solid var(--green-100);
        }

        .expired {
            border: 1px solid var(--red-100);
            border-left: 8px solid var(--red-100);
        }
    }

    .left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 25.3%;

        .serial-box {
            width: 85%;
            height: 45%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 1.5rem;
            background-color: var(--yellow-800);

            .value {
                font-size: var(--body-text-size-2xl);
            }
        }

        .age-box {
            width: 85%;
            height: 45%;
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            padding-left: 1.5rem;
            border: 1px solid var(--grey-100);

            .value {
                font-size: var(--body-text-size-2xl);
            }
        }
    }

    .address-box {
        width: 25.3%;
        display: flex;
        flex-direction: column;
        padding-left: 1.5rem;
        .content {
            span {
                font-size: var(--body-text-size-sm);
            }
        }
    }

    .title {
        font-size: var(--body-text-size-sm);
        color: var(--blue-900);
        font-weight: 700;
    }

    .content {
        display: flex;
        flex-direction: column;
        color: var(--grey-800);

        .warranty-info {
            .warranty {
                padding: 0.25rem 0.5rem;
                color: white;
                font-size: var(--body-text-size-sm);
                border-radius: 18px;
                width: fit-content;

                span {
                    line-height: normal;
                }
            }

            .date {
                font-size: var(--body-text-size-md);
            }

            .no-info {
                background-color: var(--grey-400);
            }

            .noInfo-date {
                color: var(--grey-400);
            }

            .active {
                background-color: var(--green-100);
            }

            .active-date {
                color: var(--green-100);
            }

            .expired {
                background-color: var(--red-100);
            }

            .expired-date {
                color: var(--red-100);
            }
        }
    }

    .add-button {
        font-weight: 400;
        font-size: var(--body-text-size-sm);
        color: #5f9074;
        text-decoration: underline;
        cursor: pointer;
    }
}
.horizontal-divider {
    width: 100%;
    height: 1px;
    background-color: var(--grey-100);
    margin: 1rem 0.5rem;
}

.custom-container-wrap {
    flex-wrap: wrap;

    .left-container {
        width: 45%;
    }

    .warranty-container {
        width: 45%;
    }

    .address-box {
        width: 100%;
    }
}
