.form {
    display: flex;
    flex-direction: column;

    &-line {
        gap: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}
