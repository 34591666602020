.contact-modal {
    span {
        color: var(--blue-900);
        font-weight: 700;
    }
    label {
        display: flex;
        align-items: center;
        color: var(--grey-600);
        column-gap: 0.5rem;
        svg {
            margin-bottom: 1px;
        }
    }
    .modal-body {
        row-gap: 8px;
        .modal-line {
            display: flex;
            column-gap: 10px;
            .input-wrapper {
                width: 100%;
            }
        }
    }
}
