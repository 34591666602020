.update-shipping-information-modal {
    .sub-title {
        color: var(--blue-900);
        font-size: var(--body-text-size-xl);
        font-weight: 700;
    }

    .line-title {
        color: var(--grey-600);
        font-size: var(--body-text-size-sm);
    }

    .line-value {
        display: flex;
        justify-content: space-between;

        .input-wrapper {
            width: 88%;
        }

        .update-shipping-copy {
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: var(--blue-100);
            border-radius: 0.444rem;
            width: 10%;

            &:hover {
                box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.25);
            }
        }
    }
}
