.create-product-modal {
    .modal-section {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem 0;
        border-bottom: 2px solid var(--blue-10);

        &__line {
            width: 100%;
            display: flex;
            justify-content: space-between;
            gap: 10px;

            .input-wrapper {
                width: 100%;
            }

            .input {
                width: 100%;
            }
        }

        &__image-title {
            font-weight: 400;
        }

        &--product-images {
            border-bottom: 4px solid var(--blue-10);
        }

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__document {
            padding: 1rem 0;
            border-bottom: 4px solid var(--blue-10);
            &__list {
                &__title {
                    display: flex;
                    align-items: center;
                    gap: 0.5rem;
                    font-size: var(--body-text-size-md);
                    color: var(--blue-900);
                    font-weight: 700;
                }

                &__item {
                    padding: 0.5625rem 1rem;
                    border: 1px solid var(--grey-050);
                    display: inline-flex;
                    width: 95%;
                }
            }
        }
    }
}
