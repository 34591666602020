.product-detail-page {
    & .tab-container {
        .upload-button {
            background-color: white;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 5;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
            margin-right: 0.5rem;
        }

        .right-actions {
            display: inline-flex;
            gap: 0.5rem;
            svg {
                padding: 0.62rem;
                cursor: pointer;
            }
        }
        .right-actions-documents {
            display: inline-flex;
            gap: 0.5rem;
            align-items: stretch;
            .create-document-button {
                display: inline-flex;
                gap: 0.75rem;
                align-items: center;
                height: 2.8rem;
            }
        }
    }

    & .documents-search-input {
        width: 35%;
        margin-bottom: 0.556rem;

        @media only screen and (max-width: 900px) {
            width: 100%;
        }
    }

    & .content-box {
        .product-name {
            color: var(--grey-800);
            font-size: var(--body-text-size-3xl);
            font-weight: 400;
        }

        .subtitle {
            display: flex;
            align-items: center;
            .brand-container {
                background-color: var(--orange-50);
                border-radius: 1.125rem;
                display: flex;
                align-items: center;
                gap: 1rem;
                position: relative;
                padding: 0.25rem 0.5rem;
                &--empty {
                    border: 1px dashed var(--yellow-100);
                    border-radius: 1rem;
                    padding: 0.25rem 0.5rem;
                }
            }
        }

        .equipment-type {
            background-color: #a2cdb3;
            color: var(--blue-200);
            padding: 0.2rem 0.5rem;
            border-radius: 18px;
            margin-top: 1rem;
            font-weight: 300;
        }

        .part-number {
            color: var(--grey-600);
            font-size: var(--body-text-size-sm);
            font-weight: 400;
            margin-left: 0.5rem;

            b {
                color: var(--grey-800);
            }
            .add-button {
                color: #5f9074;
                font-size: var(--body-text-size-sm);
                text-decoration: underline;
            }
        }

        & > .content-body {
            & > .content-container {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                @media only screen and (min-width: 1200px) {
                    & > :first-child {
                        width: 70%;
                    }

                    & > :last-child {
                        width: 30%;
                    }
                }

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    & > * {
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1200px) {
                    & > :first-child {
                        width: 70%;
                    }

                    & > :last-child {
                        width: 30%;
                    }
                }
            }

            & .yellow-wrapper {
                display: block;
                border-radius: 8px;
                padding: 10px;
                align-self: stretch;
                background-color: white;
                margin-bottom: 16px;
                justify-content: center;
                align-items: center;
                min-height: 930px;
                position: relative;

                div[data-rmiz-wrap='visible'] {
                    width: 100%;
                }

                & .product-image {
                    max-width: 100%;
                    width: calc(100vw);
                }
            }

            & .yellow-wrapper.drawing-parts {
                min-height: 575px;

                & .product-image {
                    max-width: 100%;
                    max-height: 575px;
                    width: auto;
                }
            }
        }
    }
}
