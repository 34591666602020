.upload-product-modal {
    display: flex;
    flex-direction: column;
    justify-content: center;
    .divider {
        margin-top: 1.25rem;
        border-bottom: 4px solid var(--blue-10);
    }
}

.download-row {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding-bottom: 0.5rem;
    &__link {
        text-decoration: none;
        color: var(--blue-900);
        font-weight: 700;
    }
}
