$leftSideWidth: 6%;

.popup-sidebar-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    left: 0;
    top: 0;
    overflow: hidden;
    display: flex;
    flex-direction: row;
    background-color: rgba($color: #5d6e82, $alpha: 0.5);

    & > .left-side {
        position: relative;
        width: $leftSideWidth;
        height: 100vh;
        background-color: transparent;

        .close {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 2.5rem;
            height: 2.5rem;
            background-color: white;
            border-radius: 5px;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            svg {
                color: #354a63;
            }

            &:hover {
                box-shadow: 0 0 2px rgba($color: #5d6e82, $alpha: 0.9);
            }
        }
    }

    & > .right-side {
        position: relative;
        width: calc(100% - $leftSideWidth);
        height: 100vh;
        background-color: white;
        border-radius: 30px 0 0 30px;
        box-shadow: -2px 0 2px rgba($color: #5d6e82, $alpha: 0.9);
        padding: 10px;
        overflow: hidden;
    }
}
