@font-face {
    font-family: Sansation;
    src: url('../src/fonts/sansation/Sansation-Regular.ttf');
}

@font-face {
    font-family: Signika;
    src: url('../src/fonts/Signika/Signika-VariableFont_wght.ttf');
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./fonts/nunito/NunitoSans-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito Sans';
    src: url('./fonts/nunito/NunitoSans-Bold.ttf');
    font-weight: 700;
    font-style: normal;
}

* {
    font-family: 'Nunito Sans', 'Signika', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
}
::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
    background-color: #f5f5f5;
    border-radius: 0.444rem;
}

::-webkit-scrollbar {
    width: 0.4rem !important;
    background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
    background-color: #aaa;
    border-radius: 0.4rem;
}

body {
    background-color: var(--blue-100);
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Signika, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

body {
    font-weight: 400;
    line-height: 1.75;
    color: #000000;
}

p {
    margin-bottom: 1rem;
}

:root {
    /* Colors */
    --white: #fff;
    --orange-50: #ffcb9b;
    --orange-100: #f58926;
    --yellow-25: #fffaed;
    --yellow-80: #fedf87;
    --yellow-100: #fdc00e;
    --yellow-200: #e5ab00;
    --yellow-300: #fdc00e;
    --yellow-500: #ffefc3;
    --yellow-800: #fedf87;
    --light-500: #c4c4c4;
    --grey-050: #f0f0f0;
    --grey-100: #e7e7e7;
    --grey-060: #676767;
    --grey-300: #bdbdbd;
    --grey-400: #9e9e9e;
    --grey-600: #767676;
    --grey-800: #212121;
    --blue-10: #f5f6f7;
    --blue-050: #a2adbb;
    --blue-060: #68737f;
    --blue-100: #f5f6f7;
    --blue-200: #0e1f33;
    --blue-500: #9aa4b1;
    --blue-900: #354a63;

    --green-050: #d0e6d9;
    --green-100: #459b68;
    --green-300: #a2cdb3;
    --green-500: #4caf50;
    --green-900: #689f38;
    --red-050: #fbe9e7;
    --red-100: #d84315;
    --red-500: #f44336;
    --red-600: #d84315;

    --toastify-color-progress-light: #fdc00e;

    /* Font Sizes */
    --body-text-size-3xl: 1.52081rem; /* 24,33296 px */
    --body-text-size-2xl: 1.3225rem; /* 21,16 px */
    --body-text-size-xl: 1.15rem; /* 18,4 px */
    --body-text-size-lg: 1rem; /* 16 px */
    --body-text-size-md: 0.87rem; /* 13,92 px */
    --body-text-size-sm: 0.756rem; /* 12,096 px */
    --body-text-size-xs: 0.656rem; /* 10,496 px */

    --status-open: #e0eeff;
    --status-in-progress: #eefbf3;
    --status-on-hold: #fff5eb;
    --status-closed: #f5f6f7;
}

body > #zoom-wrapper .zoom-image {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-start {
    justify-content: flex-start;
}

.flex-center {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.flex-gap-0-5 {
    gap: 0.5rem;
}

h1,
h2,
h3,
h4,
h5 {
    margin: 0;
    font-weight: 700;
    line-height: 1.492rem;
}

h1 {
    font-size: 2.011rem;
}

h2 {
    font-size: 1.749rem;
}

h3 {
    font-size: 1.521rem;
}

h4 {
    font-size: 1.322rem;
}

h5 {
    font-size: 1.15rem;
}

/* Small screens */
@media only screen and (max-width: 480px) {
    html {
        font-size: 86.9%;
    } /*13.91px*/
}

/* Medium screens */
@media only screen and (min-width: 481px) and (max-width: 1440px) {
    html {
        font-size: 100%;
    } /*16px*/
}

/* Large screens */
@media only screen and (min-width: 1441px) {
    html {
        font-size: 115%;
    } /*18.4px*/
}
