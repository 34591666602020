.customer-checkout-page {
    background-color: white;
    .page-wrapper-space {
        height: 64px;
    }

    .page-content {
        height: calc(100vh - 124px);

        .side-bar-container {
            height: calc(100vh - 64px) !important;

            .side-bar {
                height: calc(100vh - 68px) !important;
            }
        }
    }

    &-container {
        display: flex;
        overflow: auto;
        height: calc(100vh - 124px);
        background-color: white;

        .left-box {
            width: 70%;
            padding-top: 1rem;

            .cart-action-container {
                display: flex;
                column-gap: 1rem;

                .save-button {
                    display: flex;
                    align-items: center;
                    column-gap: 1rem;
                    color: black;
                    font-size: var(--body-text-size-lg);
                }

                .action-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--grey-600);
                    margin-right: 0.5rem;

                    svg {
                        width: 18px;
                        height: 18px;
                    }

                    &:hover {
                        color: black;
                    }
                }
            }

            .cart-title-action-container {
                display: flex;
                column-gap: 0.5rem;

                .edit-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--grey-600);

                    svg {
                        width: 1rem;
                        height: 1rem;
                    }

                    &:hover {
                        color: black;
                    }
                }
            }
        }

        .right-box {
            display: flex;
            width: 30%;
            height: calc(100vh - 124px);
            align-items: center;
        }
    }
}
