.confirmationModal {
    z-index: 1010 !important;

    & + .modal-overlay {
        z-index: 1006 !important;
    }

    & .confirmation-modal-divider {
        margin: 1.5rem 0 0.5rem 0;
    }
}

.delete-document-modal {
    max-width: 33.333rem;

    & .warning-text {
        display: block;
        padding: 0.278rem;
        font-size: var(--body-text-size-sm);
    }
}

.delete-assembly-modal {
    width: 40%;

    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 10px;
    }

    & .important-note {
        font-size: var(--body-text-size-sm);
        color: var(--red-100);
    }

    & .important-inline-note {
        color: var(--red-100) !important;
    }
}

.confirm-delete-part-list-modal {
    width: 40%;

    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 10px;
    }

    & .important-note {
        font-size: var(--body-text-size-sm);
        color: var(--red-100);
    }

    & .important-inline-note {
        color: var(--red-100) !important;
    }
}

.delete-equipment-modal {
    width: 40%;

    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 10px;
    }

    & .important-note {
        font-size: var(--body-text-size-sm);
        color: var(--red-100);
    }

    & .important-inline-note {
        color: var(--red-100) !important;
    }
}

.unlink-document-modal {
    max-width: 33.333rem;

    & .warning-text {
        display: block;
        padding: 0.278rem;
        font-size: var(--body-text-size-sm);
    }
}

.delete-address-modal {
    width: 40%;

    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 10px;
    }

    & .important-note {
        font-size: var(--body-text-size-sm);
        color: var(--red-100);
    }

    & .important-inline-note {
        color: var(--red-100) !important;
    }
}

.delete-team-member-modal {
    width: 40%;

    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 10px;
    }

    & .important-note {
        font-size: var(--body-text-size-sm);
        color: var(--red-100);
    }

    & .important-inline-note {
        color: var(--red-100) !important;
    }
}
