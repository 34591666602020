div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.action-cell {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-hover {
        background-color: var(--blue-050);
    }
    &:hover {
        background-color: var(--blue-050);
    }
}

.green-tick {
    width: 70%;
    display: flex;
    justify-content: center;
}

.add-to-cart {
    &:hover {
        background-color: var(--yellow-100);
    }
    &:active {
        transform: scale(0.95);
        box-shadow: none;
        border: 4px solid var(--yellow-25);
        & .button:active + & {
            border-radius: 0;
        }
    }
}
