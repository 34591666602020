.edit-document-modal {
    max-width: 33.333rem;

    & .input-wrapper {
        width: auto !important;
        margin-bottom: 1rem;
    }

    & .warning-text {
        display: block;
        padding: 0.5rem;
        font-size: var(--body-text-size-sm);
        color: red;
    }
}
