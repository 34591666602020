.mapping-details {
    display: none;
    position: absolute;
    z-index: 4;
    max-width: 15.875rem;
    min-height: 1rem;
    width: 17.875rem;
    //height: 12rem;
    border-radius: 0.5rem;
    background: white;
    padding: 1.25rem;
    border: 1px solid var(--grey-050);
    opacity: 0;
    box-shadow: 0 0 12px rgba(53, 74, 99, 0.25);

    &--visible {
        display: flex;
        opacity: 1;
    }

    .close-details {
        padding: 0;
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
        height: 2rem;
        width: 2rem;
        z-index: 1;
        background: transparent;
        border-radius: 50%;

        &:hover {
            background: var(--blue-10);
        }
    }

    .wrapper {
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;

        .part {
            display: flex;
            flex-direction: column;

            &__number {
                font-size: var(--body-text-size-sm);
                color: var(--grey-600);
            }

            &__name {
                font-size: var(--body-text-size-md);
                color: var(--grey-800);
            }

            &__brand {
                background: var(--blue-10);
                font-size: var(--body-text-size-sm);
                border-radius: 1.125rem;
                color: var(--blue-900);
                padding: 0.25rem 0.5rem;
                text-align: center;
                width: auto;
                max-width: 30%;
            }
        }

        .action-buttons {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 95%;
            border-top: 1px solid var(--blue-10);
            padding-top: 0.5rem;
            margin-top: 1rem;

            span {
                cursor: pointer;
                display: flex;
                gap: 0.4rem;
                align-items: center;
                padding: 0.25rem 0.5rem;
                border-radius: 0.5rem;
                width: 7rem;
                justify-content: center;
                font-size: var(--body-text-size-sm);

                &:hover {
                    background: var(--blue-100);
                }
            }
        }

        .quick-cart {
            display: flex;
            flex-direction: column;
            gap: 0.25rem;

            .price {
                font-size: var(--body-text-size-md);
                color: var(--grey-800);
            }

            & > .add-to-cart-wrapper {
                height: 3rem;
                display: flex;
                align-items: center;

                & > .button {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 0.5rem;
                    width: 60%;
                    padding: 0.625rem 1rem;
                    color: var(--blue-900);

                    & svg > path {
                        fill: var(--blue-900);
                    }

                    border: 2px solid transparent;

                    &:hover {
                        box-shadow: none;
                    }

                    &:active {
                        transform: scale(0.95);
                        box-shadow: none;
                        border: 4px solid rgba(255, 250, 237, 1);
                    }
                }
            }

            & > span.updated-cart {
                color: rgba(69, 155, 104, 1);
                font-size: var(--body-text-size-sm);
            }

            &__counter {
                display: flex;
                justify-content: center;
                background-color: var(--blue-100);
                border-radius: 0.222rem;
                padding: 0.444rem;
                width: 4.444rem;

                .count-changer {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                }

                input {
                    background-color: transparent;
                    border-width: 0;
                    width: 50%;
                    text-align: center;
                }

                input::-webkit-outer-spin-button,
                input::-webkit-inner-spin-button {
                    -webkit-appearance: none;
                    margin: 0;
                }

                input[type='number'] {
                    -moz-appearance: textfield;
                }
            }
        }
    }
}
