.pdf-viewer-container {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    margin-bottom: 1.25rem;

    & .zoom-button-controller {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: relative;
        height: 3.5rem;
        padding-right: 1.25rem;
        border-radius: 1rem 1rem 0 0;
        border: 1px solid var(--grey-100);
        border-bottom: 0;
        .edit-hotspot {
            padding: 0.625rem 0;
            height: 2.5rem;
            background: var(--blue-100);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: 1rem;
            svg {
                width: 2.5rem;
                padding-left: 0.4rem;
            }
            &.disabled {
                color: var(--grey-200);
                box-shadow: none;
                cursor: initial;
            }
            span {
                padding-right: 1rem;
            }
        }
    }

    & > .pdf-viewer {
        display: flex;

        //flex-direction: column;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        min-height: 8.43rem;
        border: 1px solid var(--grey-100);
        border-top: 0;
        padding: 0 1rem;
        .btn {
            height: 2.5rem;
            width: 2.5rem;
            background-color: var(--blue-900);
            border-radius: 0.5rem;
            padding: 0;
            svg {
                fill: white;
            }
        }
        & .loader {
            position: absolute;
            top: 300px;

            & > .center-content {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;

                & > span {
                    text-align: center;
                }
            }
        }
    }

    & > .pdf-viewer-sm {
        min-height: 575px;
        max-height: 575px;
        width: auto;
    }

    & > .pdf-viewer-footer {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: white;
        padding: 10px 0;
        bottom: 0;
        margin-top: -0.5rem;
        border-radius: 0 0 1rem 1rem;
        border: 1px solid var(--grey-100);

        & > .total-page-number {
            margin: 0 10px 0 10px;
        }
    }
}
