.page-container {
    display: flex;
    flex-direction: column;
    height: calc(100% - 8px);

    .header {
        height: 2.5rem;
        display: flex;
        align-items: center;
        padding-left: 2.5rem;
        background-color: var(--yellow-300);
        margin: -2px;
        z-index: 1;
        column-gap: 0.5rem;

        .close-button {
            display: flex;
            align-items: center;
        }

        .title {
            color: var(--blue-900);
            font-size: var(--body-text-size-md);
        }
    }

    .content {
        height: calc(100vh - 2.5rem) !important;
    }
}

.border {
    border: 4px solid var(--yellow-300);
}
