.tag-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-size: 0.8rem;
    gap: 0.5rem;

    .tag-item {
        gap: 0.5rem;
        height: 1.42rem;
        width: fit-content;
        padding: 0 0.4rem;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #cbe2ec;

        //cursor: pointer;
        .tag-name {
            font-size: var(--body-text-size-md);
            color: var(--grey-800);
        }

        .tag-close {
            display: flex;
            justify-content: center;
            margin-right: 2px;
            cursor: pointer;

            svg {
                path {
                    fill: var(--grey-800);
                }
            }
        }

        //&:hover {
        //    background-color: var(--blue-050);
        //    .tag-name {
        //        font-size: .7rem;
        //        color: var(--blue-200);
        //    }
        //}
    }

    .tag-input-wrapper {
        display: flex;
        width: fit-content;
        border-radius: 6px;
        border: 1px solid var(--blue-200);
        gap: 0.5rem;
        padding: 0 0.2rem;

        .tag-input {
            border-width: 0;
            font-size: 0.8rem;
        }

        .tag-input-button {
            cursor: pointer;
        }
    }

    .tag-input-button {
        height: 1.42rem;
        width: 1.74rem;
        border-radius: 13px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #cbe2ec;
        cursor: pointer;

        svg {
            path {
                fill: #3067e9;
            }
        }

        &:hover {
            background-color: var(--blue-050);
        }
    }
}
