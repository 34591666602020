.searchPartsTableContainer {
    position: relative;
    width: 100%;
    margin-top: 15px;
    .tableHeadCell {
        border-right: none !important;
    }
    .subRowColumn {
        border-right: none !important;
    }
}
