.label-with-line {
    position: relative;
    width: 100%;
    border-bottom: 2px solid #f5f6f7;
    height: 0;
    overflow: visible;
    z-index: 1;
    margin-block: 20px;

    .label-text {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
        z-index: 2;
        background-color: white;
        padding-right: 25px;
    }
}
