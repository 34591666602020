//.new-details-tab-container {
//    display: flex;
//    flex-direction: row;
//    row-gap: 2rem;
//
//    .equipment-info {
//        display: flex;
//        flex-direction: column;
//        row-gap: 2rem;
//
//        .top {
//            display: flex;
//            column-gap: 2rem;
//
//            &-right {
//                display: flex;
//                flex-direction: column;
//                row-gap: 2rem;
//
//                .tags-container {
//                    display: flex;
//                    flex-direction: column;
//                    width: fit-content;
//
//                    .title {
//                        font-size: var(--body-text-size-sm);
//                        color: var(--blue-900);
//                        font-weight: 700;
//                    }
//                }
//            }
//        }
//
//        .bottom {
//            display: flex;
//            flex-direction: column;
//        }
//
//        .line {
//            display: flex;
//            flex-direction: column;
//
//            .title {
//                font-size: var(--body-text-size-sm);
//                color: var(--blue-900);
//                font-weight: 700;
//            }
//
//            .value {
//                font-size: var(--body-text-size-md);
//                color: #2c2d2e;
//            }
//
//            .go-to {
//                font-size: var(--body-text-size-md);
//                color: var(--blue-200);
//                background: var(--yellow-100);
//                border-radius: 8px;
//                padding: 0 0.5rem;
//                cursor: pointer;
//            }
//            .add-button {
//                font-size: var(--body-text-size-sm);
//                color: #5f9074;
//                text-decoration: underline;
//                cursor: pointer;
//            }
//        }
//    }
//}
.product-details-tab-container {
    display: flex;
    flex-direction: column;

    .contract-details-below {
        margin-top: 30px;
    }

    .contract-description {
        padding-top: 32px;
    }

    .product-info-cart {
        display: flex;
        align-items: flex-start;
        flex-direction: row;
        gap: 2rem;
        width: auto;
    }
    .description {
        .header {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: var(--body-text-size-sm);
                color: var(--blue-900);
                font-weight: 700;
            }

            .add-button {
                font-size: var(--body-text-size-sm);
                color: #5f9074;
                text-decoration: underline;
                cursor: pointer;
            }
        }

        .value {
            font-size: var(--body-text-size-md);
            color: #2c2d2e;
        }
    }
    .product-image-description {
        display: inline-flex;
        gap: 2rem;
        margin-top: 1.25rem;
    }
}

.contract-description-wrapper {
    width: 100%;
    .header {
        display: flex;
        .title {
            font-size: var(--body-text-size-sm);
            color: var(--blue-900);
            font-weight: 700;
        }

        .add-button {
            font-size: var(--body-text-size-sm);
            color: #5f9074;
            text-decoration: underline;
            cursor: pointer;
        }
        &--has-description {
            justify-content: space-between;
        }
    }
    .description {
        font-size: var(--body-text-size-md);
        line-height: 1.25rem;
    }
}
