.add-mapping {
    display: none;
    flex-direction: column;
    position: absolute;
    width: 17.875rem;
    height: 20.4375rem;
    background: white;
    box-shadow: 0 0 5px grey;
    border-radius: 1rem;
    z-index: 3;
    padding: 1.5rem 0.75rem;

    & > .header {
        padding-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 0.5rem;

        .add-mapping-search {
            width: 100%;
        }

        .close-add-mapping {
            background: var(--blue-10);
            padding: 0.1rem 0.5rem;
            border-radius: 100%;
        }
    }

    & > .body {
        display: flex;
        width: 100%;
        height: calc(100% - 15%);
        flex-direction: column;
        gap: 1rem;

        .part-list {
            display: flex;
            flex-direction: column;
            gap: 0.3rem;
            overflow-y: scroll;

            .part {
                cursor: pointer;
                display: flex;
                flex-direction: column;
                gap: 0.25rem;

                &:hover {
                    background: var(--blue-100);
                }

                & > span {
                    line-height: 1.25rem;
                }

                &__part-number {
                    color: var(--grey-600);
                    font-size: var(--body-text-size-sm);
                }
            }
        }

        .divider {
            width: calc(17.875rem - 0.5rem);
            border-bottom: 1px solid var(--blue-10);
        }
    }
}
