div.MuiTableContainer-root#product-table > table > tbody > tr {
    & td.MuiTableCell-root {
        background-color: transparent !important;
    }
    &:hover {
        background-color: var(--blue-10);
    }
    &:hover {
        td.MuiTableCell-root {
            font-weight: 700 !important;

            & > span.ellipsis-text {
                font-weight: 700 !important;
            }
        }

        .manu {
            font-weight: 700 !important;
        }
    }
    & > td.MuiTableCell-root > span.ellipsis-text {
        width: calc(100% - 16px);
        height: auto;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
    }

    .product-name {
        .ellipsis-text {
            color: #212121 !important;
        }

        .manu {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: #767676;
            font-weight: 400;
            font-size: var(--body-text-size-sm);

            .seperator-dot {
                position: relative;
                font-weight: bolder;
                font-size: 30px;
                margin-inline: 5px;
                transform: translateY(-7px);
                line-height: 1px;
            }
        }
    }

    .checkbox-cell {
        padding: 0 0 0 0 !important;
        width: 5%;

        .checkbox-container {
            transform: translateX(-10px);
        }
    }
}

.action-cell {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-hover {
        background-color: var(--blue-050);
    }
    &:hover {
        background-color: var(--blue-050);
    }
}
