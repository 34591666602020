.customer-page-wrapper-space {
    height: 124px;
}

.customer-page {
    .customer-page-header {
        margin-bottom: 4px;

        .header-left {
            display: flex;
            align-items: center;
            width: 100%;

            a {
                display: flex;
                align-items: center;
            }

            .dropdownbutton-text {
                font-size: var(--body-text-size-lg);
                color: black;
                font-weight: 500;
            }

            .dropdownbutton-text-disable {
                @extend .dropdownbutton-text;
                color: var(--grey-600);
                pointer-events: none;
            }

            .dropdowninner-button {
                border: none !important;
                width: 100%;
            }

            .manufacturer-logo {
                width: 3.75rem;
                height: 3.75rem;
                border-radius: 8px;
            }

            .manufacturer-name {
                margin-left: 1rem;
                font-size: var(--body-text-size-3xl);
                color: black;
                font-weight: 700;
            }
        }

        .header-right {
            display: flex;
            align-items: center;

            .cart-button-container {
                position: relative;
                padding: 0.4rem;
                padding-left: 0;

                .button {
                    width: 2.5rem;
                    height: 2.5rem;
                    display: flex;
                    align-items: center;
                    padding: 0 !important;
                    justify-content: center;
                }

                .order-counter {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: var(--red-100);
                    border-radius: 50%;
                    height: 1.25rem;
                    width: 1.25rem;
                    font-size: var(--body-text-size-sm);
                    color: var(--blue-100);
                    font-weight: 700;
                }
            }

            .action-button {
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                padding: 0 !important;
                justify-content: center;
                color: var(--grey-600);
            }
        }
    }

    .page-content {
        height: calc(100vh - 124px);

        .cart-content-container {
            height: calc(100% - 120px);
            top: 123px;
            z-index: 1;
        }
    }

    .cart-content-wrapper {
        display: flex;
        align-items: center;
    }

    &-header {
        display: flex;
        height: 80px;
        position: absolute;
        top: 0;
        width: calc(100vw - 7rem);
        justify-content: space-between;
        padding: 0 3.5rem;
        align-items: center;
        background-color: white;
    }

    &-tab {
        position: absolute;
        top: 76px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: white;
        height: 40px;
        width: calc(100vw - 7rem);
        padding: 0 3.5rem;
        border: 4px solid var(--blue-10);
        border-left: none;
        border-right: none;

        .group {
            display: flex;
            column-gap: 2rem;

            span {
                color: var(--grey-600) !important;
                font-size: var(--body-text-size-sm);
            }
        }
    }
}
