$containerWidth: 120px;
$containerMaxWidth: 120px;
$containerHeight: 40px;
$space: 5px;

.activity-partial-status-container {
    position: relative;
    height: $containerHeight;
    margin-inline: $space;
    z-index: 4;

    .open {
        border: 1px solid #a8c2e1;
        background-color: #e0eeff;
        color: #456b9a;

        &:not(.disabled-dropdown):hover {
            border: 2px solid #a8c2e1;
            font-weight: bold;
        }
    }

    .in-progress {
        background-color: #eefbf3;
        color: #459b68;
        border: 1px solid #bce0cb;

        &:not(.disabled-dropdown):hover {
            border: 2px solid #459b68;
            font-weight: bold;
        }
    }

    .on-hold {
        background-color: #fff5eb;
        color: #f58926;
        border: 1px solid #ffcb9b;

        &:not(.disabled-dropdown):hover {
            border: 2px solid #ffcb9b;
            font-weight: bold;
        }
    }

    .closed {
        background-color: #f5f6f7;
        color: #767676;
        border: 1px solid #c4c4c4;

        &:not(.disabled-dropdown):hover {
            border: 2px solid #767676;
            font-weight: bold;
        }
    }

    .selected-one {
        width: fit-content;
        height: 100%;
        padding: 0 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;
        border-radius: calc($containerHeight / 2);
        column-gap: 0.5rem;

        &.disabled-dropdown {
            cursor: default;
        }

        &.focused-dropdown.enabled-dropdown {
            border: 2px solid #fdc00e !important;
            background-color: white !important;
            color: black !important;
        }

        svg {
            transform: translateY(20%);
        }

        .selected-one-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-size: var(--body-text-size-md);
        }
    }

    .status-options {
        position: absolute;
        left: 0;
        top: calc($containerHeight + $space * 2);
        width: calc($containerWidth * 4 / 3);
        display: flex;
        flex-direction: column;
        z-index: 2;
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);
        border-radius: calc($containerHeight / 2);
        background-color: white;

        .status-option {
            position: relative;
            width: 100%;
            background-color: white;
            cursor: pointer;
            margin-block: 5px;

            .status-text {
                margin-left: 20px;
                position: relative;
                width: fit-content;
                column-gap: 0.5rem;
                padding: 0 0.5rem;
                border-radius: calc($containerHeight / 2);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: var(--body-text-size-md);

                svg {
                    margin-left: 5px;
                }
            }

            &:hover {
                background-color: #f5f6f7;
            }

            &:first-child {
                border-radius: calc($containerHeight / 2) calc($containerHeight / 2) 0 0;

                .status-text {
                    margin-top: 10px;
                }
            }

            &:last-child {
                border-radius: 0 0 calc($containerHeight / 2) calc($containerHeight / 2);

                .status-text {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
