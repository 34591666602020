$topSectionHeight: 5rem;
$sectionsVerticalSpace: 1.8rem;

.update-activity-popup-sidebar {
    .activity-section-space {
        position: relative;
        width: 100%;
        height: $sectionsVerticalSpace;
    }

    .label-with-line {
        width: 100%;
        margin-bottom: 30px;
    }

    &-content {
        position: relative;
        width: 100%;
        height: 100%;

        .top-section {
            position: relative;
            width: 100%;
            height: $topSectionHeight;
            border-bottom: 1px solid #f0f0f0;
            display: flex;
            flex-direction: row;
            align-items: center;

            .title-section {
                position: relative;
                width: 45%;
                padding-inline: 30px;

                .last-modified {
                    position: relative;
                    color: #767676;
                    font-size: var(--body-text-size-sm);
                }
            }

            .vertical-seperator {
                width: 0;
                height: 50px;
                border-right: 2px solid #f5f6f7;
                position: relative;
            }

            .right-section {
                display: flex;
                flex-direction: row;
                justify-content: space-evenly;
                align-items: center;
                position: relative;
                width: 55%;
                padding-inline: 10px;

                .delete-button {
                    background-color: white;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border: 1px solid #f5f6f7;

                    &:disabled {
                        color: gray;
                    }

                    svg {
                        transform: scale(0.8);
                        margin-right: 10px;
                    }
                }

                .activity-save-button {
                    &:disabled {
                        background-color: #ffefc3;
                        color: #a2adbb;
                    }
                }
            }
        }

        .body-section {
            position: relative;
            width: 100%;
            height: calc(100% - $topSectionHeight);
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding-top: 20px;

            .left-side {
                position: relative;
                width: 50%;
                height: 95%;
                overflow-y: auto;
                padding-inline: 20px;

                .checklistContainer {
                    .completedOnes {
                        border-radius: 10px;
                    }

                    .unCompletedOnes {
                        padding-top: 5px;
                    }

                    .delete-todo {
                        svg {
                            color: #fe0d0d;
                        }
                    }
                }

                .product-note {
                    position: relative;
                    color: #767676;
                    font-size: var(--body-text-size-sm);
                    padding-left: 10px;
                }

                .equipment-note {
                    position: relative;
                    color: #767676;
                    font-size: var(--body-text-size-sm);
                    padding-left: 10px;
                }

                .monittor-table-container {
                    padding-bottom: 0 !important;
                }

                .delete-entire-series-section {
                    position: relative;
                    width: 100%;

                    .delete-series-text {
                        position: absolute;
                        right: 0;
                        background-color: white;
                        padding-right: 3px;
                        padding-left: 10px;
                        text-decoration: underline;
                        top: -30px;
                        transform: translateY(-50%);
                        font-size: var(--body-text-size-sm);
                        z-index: 2;
                        cursor: pointer;
                    }
                }

                .add-new-todo {
                    & > .input {
                        border: 1px solid white;

                        &:hover {
                            border: 1px solid #edeff2;
                        }

                        &:focus {
                            border: 1px solid #fdd768;
                        }
                    }

                    input {
                        outline: none !important;
                    }

                    span.label {
                        font-size: var(--body-text-size-md);
                    }

                    &.filled-input {
                        & > input {
                            padding-top: 0.4rem !important;
                        }
                    }

                    &.empty-input {
                        & > .input {
                            &:not(:hover) {
                                background-color: #f5f6f7;
                                border: 2px dashed #a2adbb;
                            }
                        }
                    }
                }
            }

            .right-side {
                position: relative;
                width: 48%;
                height: 95%;
                overflow-y: auto;
                margin-left: 1.5%;
                padding-right: 20px;

                .customer-with-switch {
                    position: relative;
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    &.customer-selected {
                        .activity-customer-container {
                            width: 87%;
                        }
                    }

                    .MuiSwitch-root {
                        transform: scale(1.1);
                        padding-inline: 5px !important;

                        .MuiSwitch-thumb {
                            background-color: #767676;
                            transform: scale(0.8) translateY(22%) translateX(-20%);
                        }

                        .MuiSwitch-track {
                            background-color: #e7e7e7;
                            border: 1px solid gray;
                            height: 20px;
                            border-radius: 10px;
                        }

                        &:hover .MuiSwitch-thumb {
                            background-color: #c4c4c4;
                        }

                        &:hover .MuiSwitch-track {
                            background-color: #f0f0f0;
                        }

                        .Mui-checked {
                            .MuiSwitch-thumb {
                                background-color: #0e1f33;
                                transform: scale(0.8) translateY(22%) translateX(12%);
                            }

                            & + .MuiSwitch-track {
                                background-color: #a2adbb;
                            }

                            &:hover .MuiSwitch-thumb {
                                background-color: #354a63;
                            }
                        }

                        &:hover .Mui-checked + .MuiSwitch-track {
                            background-color: #f5f6f7;
                        }
                    }
                }

                .share-customer-note {
                    position: relative;
                    width: 100%;
                    color: #767676;
                    font-size: var(--body-text-size-sm);
                    margin-top: 10px;
                }

                .contract-note {
                    position: relative;
                    width: 100%;
                    color: #767676;
                    font-size: var(--body-text-size-sm);
                }
            }
        }
    }
}
