.customer-requirements-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1.25rem;
    background-color: white;
    width: 85%;
    height: 90%;
    border-radius: 20px;
    filter: drop-shadow(0px 0px 12px rgba(53, 74, 99, 0.15));
    border: 2px solid #f0f0f0;

    .type-button-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4.875rem;
        border-bottom: 4px solid var(--blue-10);
        padding: 0 1.25rem;
        margin: -1.25rem;

        .type-button {
            border: 1px solid var(--yellow-300);
            border-radius: 30px;
            background-color: white;
            width: 50%;
            margin: 0 -15px;
            color: black;
            white-space: nowrap;
        }

        .checked {
            z-index: 1;
            background-color: var(--yellow-300);
        }
    }

    .requirements-body {
        display: flex;
        flex-direction: column;
        height: calc(100% - 8rem);
        padding: 0 1.25rem;
        margin: 0 -1.25rem;
        overflow-y: auto;
        overflow-x: hidden;

        .requirement-container {
            display: flex;
            flex-direction: column;
            padding: 0 1.25rem;
            margin: 0 -1.25rem;
            padding-bottom: 1rem;
            border-bottom: 4px solid var(--blue-10);

            .requirement-label {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 0.75rem;
                cursor: pointer;

                span {
                    font-size: var(--body-text-side-sm);
                    font-weight: 700;
                    color: #354a63;
                }
            }
            &:last-child {
                border-bottom: none;
            }
        }
    }

    .send-button {
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: 4px solid var(--yellow-100);
        padding: 0 1.25rem;
        margin: -1.25rem;
        height: 5.25rem;

        .button {
            width: 100%;
            height: 2.5rem;
            background-color: var(--yellow-100);
        }
    }
}
