.sign-up-page {
  position: absolute;
  background-image: url('../../img/login-bg.png');
  background-size: cover;
  height: 100%;
  width: 100%;

  .form-wrapper {
    margin-top: 5%;
    margin-left: 15%;
    padding-bottom: 3%;

    .login-form {
      display: flex;
      width: 20.444rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 2.222rem 1.111rem;
      border: 2px solid var(--grey-600);
      background: rgba(255, 255, 255, 0.15);
      /* Glassmorph 32blur */

      backdrop-filter: blur(32px);
      /* Note: backdrop-filter has minimal browser support */

      h1 {
        margin: 0.833rem 0;
      }

      .brand-title {
        width: 100%;
        display: block;
        color: var(--white);
        font-size: 1.176rem;
        font-weight: 700;
        text-align: center;
      }

      .subtitle {
        color: var(--yellow-200);
        text-align: center;
      }

      .description {
        width: 100%;
        text-align: left;
        color: var(--white);
        font-size: 0.778rem;
      }

      .sign-up-password-hint{
        background-color: rgba(255, 255, 255, 0.8);
        margin: 5px 0px 0px 0px;
        border-color: #efe5e0a1;
        width: 100%;
        border-width: 0px;
        border-style: solid;
        border-radius: 1px 1px 8px 8px;
        
        .password-hint-item{
          font-family: 'Sansation';
          font-style: italic;
          font-weight: 400;
          font-size: 12.1px;
          line-height: 14px;
          align-items: center;

          color: #767676;
        }
      }

      .sign-up-password-input {
        input {
          border-radius: 8px 8px 0px 0px;
        }
      }

      .group-label {
        text-align: left;
        margin-top: 1rem;
        color: var(--white);
        font-size: 0.778rem;
      }

      .input-wrapper {
        margin: 0.667rem 0 0 0;
        width: 100%;

        .input {
          width: 100%;
        }
      }

      .button {
        width: 100%;
        margin-top: 0.556rem;

        &[disabled] {
          opacity: 1;
          color: var(--blue-050);
          background: var(--yellow-500);
        }
      }
    }

    .radio-group {
      display: flex;
      margin-top: 0.556rem;

      label {
        display: flex;
        cursor: pointer;
        font-weight: 500;
        position: relative;
        overflow: hidden;
        margin-bottom: 0.375em;
        /*
          &:focus-within {
              outline: .125em solid var(--blue-200);
          }
        */
        input {
          position: absolute;
          left: -9999px;

          &:checked + span {
            &:before {
              background: #0E1F33;
              box-shadow: inset 0 0 0 0.2375em #FDC00E;
            }
          }
        }

        span {
          display: flex;
          color: var(--white);
          align-items: center;
          font-size: 0.778rem;
          border-radius: 99em;
          transition: 0.25s ease;
          padding: 0.375em 0.75em 0.375em 0.375em;

          &:before {
            display: flex;
            flex-shrink: 0;
            content: "";
            background-color: #fff;
            width: 1.5em;
            height: 1.5em;
            border-radius: 50%;
            margin-right: 0.375em;
            transition: 0.25s ease;
            box-shadow: inset 0 0 0 0.125em #68737F;
          }
        }
      }
    }
  }

  &.sign-up-container {
    height: auto;

    .form-wrapper {
      margin-top: 5%;
    }
  }

  .foot-note {
    color: var(--white);
    font-size: 0.667rem;
    margin-top: 1.111rem;

    a {
      font-weight: bold;
      color: var(--white);
      text-decoration: none;
    }
  }

  .message {
    font-weight: bold;

    &.success {
      color: var(--green-500);
    }

    &.error {
      color: var(--red-500);
    }
  }

  @media screen and (max-width: 768px) {
    .form-wrapper {
      margin-top: 33%;
      width: 100%;
      margin-left: 0;

      .login-form {
        max-width: unset;
      }
    }
  }

  .loading-span {
    width: 1.111rem;
    height: 1.111rem;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    border: 0.278rem solid var(--yellow-500);
    border-bottom-color: transparent;
    position: relative;
    margin: 0 auto;
    animation: spinner 1s linear infinite paused;
    -webkit-animation: spinner 1s linear infinite;
  }

  @keyframes spinner {
    to {
      transform: rotate(1turn);
      -webkit-transform: rotate(1turn);
      -moz-transform: rotate(1turn);
      -ms-transform: rotate(1turn);
      -o-transform: rotate(1turn);
    }
  }
}
