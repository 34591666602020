.searchPartsTableHeadContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    height: 60px;
    background-color: #354a63;
    color: #ffffff;
    border-radius: 8px;
    position: sticky;
    top: 0;
    z-index: 2;

    .tableHeadCell {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: relative;
        height: 100%;
        &:not(:last-child) {
            border-right: 2px solid #f5f6f7;
        }

        .columnTitle {
            margin-left: 15px;
        }
    }
}
