.list-card-container {
  & > .list-card-item {
    border: 1px solid var(--yellow-800);
    border-radius: 0.444rem;
    margin-bottom: 0.556rem;    

    & > .content {
      display: flex;
      justify-content: space-around;
      align-items: flex-start;
      padding: 0 0.556rem;

      & > * {
        padding-top: 0.833rem;
        padding-bottom: 0.833rem;
      }
    
      & > .checkbox-container {
        padding-right: 1.667rem;
      }
    
      & > .content-container {
        flex: 8;
        display: block;
        padding-right: 1.667rem;
      }
    
      & > .button-group-container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        
        & > * {
          margin-left: 0.389rem;
        }
      }
    }

    &:hover {
      box-shadow: 0 0 0.889rem rgba(253, 192, 14, 0.5);
    }

    & > .expand-content {
      border-top: 1px solid var(--yellow-300);
      padding: 0 0.556rem;
    }
  }
}
