.upload-product-modal {
    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    .download-link {
        color: var(--blue-200);
        text-decoration: underline;
    }

    p {
        margin: 10px;
    }
}

.master-page {
    .card-first {
        display: flex;
        width: 2.3333333333%;
        align-items: flex-start;

        .category-image {
            width: 100%;

            @media (max-width: 768px) {
                display: none;
            }
        }
    }

    .product-search-area {
        .input-wrapper {
            z-index: 900;
        }
    }
}

.product-search-area {
    display: inline-flex;
    gap: 1rem;

    .input-wrapper {
        margin: 0;
    }
}

.product-table-container {
    overflow-y: auto;
}
