.setting-page {
    .setting-title {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
    }

    .setting-detail-wrapper {
        display: flex;
        gap: 1rem;
        flex-direction: column;
        width: 50%;
    }

    .setting-alert {
        color: var(--red-500);
        font-size: 0.7rem
    }
}