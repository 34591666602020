.new-tree-view-container {
    & > .hierarchial-tree-view-container {
        top: 64px;
        left: 0;
        z-index: 5;
        background-color: white;
        display: block;
        min-width: 17.3rem;
        height: 35.75rem;
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.25);
        border-radius: 1rem;
        border: 1px solid var(--grey-050);
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 1rem;
        row-gap: 0.75rem;

        .hierarchial-tree-view-container-header {
            display: flex;
            align-items: center;
            border-bottom: 2px solid var(--yellow-80);
            height: 2.5rem;
            margin: -1rem calc(-1rem + 4px) 0 calc(-1rem + 4px);
            padding: 0.4rem calc(1rem - 4px);
            justify-content: space-between;

            & > .title {
                color: var(--blue-900);
                line-height: normal;
            }

            & > .action-container {
                display: flex;
                align-items: center;
                column-gap: 0.5rem;

                & > .action-button {
                    border-radius: 50%;

                    svg {
                        path {
                            fill: var(--grey-600);
                        }
                    }

                    &:hover {
                        background-color: var(--blue-050);
                    }
                }
            }
        }

        .hierarchial-tree-view-container-body {
            display: flex;
            flex-direction: column;
            overflow-y: auto;
            margin: 0 -0.5rem 0 -1rem;

            &::-webkit-scrollbar-track {
                -webkit-box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
                box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
                background-color: #f5f5f5;
                border-radius: 0.444rem;
            }

            &::-webkit-scrollbar {
                width: 0.4rem !important;
                background-color: #f5f5f5;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #aaa;
                border-radius: 0.4rem;
            }

            & .node-container {
                width: 100%;
                box-sizing: border-box;
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: relative;
                height: 2.375rem;

                & .node-left {
                    display: inline-flex;
                    align-items: center;
                    width: 100%;

                    & .node-text {
                        width: 100%;
                        padding-left: 1rem;
                        color: var(--grey-800);
                        line-height: 20px;
                        cursor: pointer;
                        font-size: var(--body-text-size-md);
                    }
                }

                & .node-right {
                    display: inline-flex;
                    width: fit-content;

                    & .node-buttons {
                        width: 100%;
                        display: inline-flex;
                        justify-content: right;

                        svg {
                            color: var(--light-500);

                            path {
                                fill: var(--light-500);
                            }
                        }
                    }

                    & .node-buttons > * {
                        margin-left: 0.278rem;
                    }
                }

                &:hover {
                    background-color: var(--blue-100);
                }

                &:hover .node-text,
                &:hover .node-icon {
                    color: var(--blue-900);
                    font-weight: 700;
                }
            }

            & .checked {
                background-color: var(--blue-100);

                .node-text {
                    color: var(--blue-900) !important;
                    font-weight: 700;
                }
            }

            & .left-border {
                position: absolute;
                height: 2.375rem;
                width: 1.389rem;
                top: 0;
                border-left: 1px solid var(--blue-050);
                z-index: 1;
            }

            & .bottom-border {
                position: absolute;
                height: 1.2rem;

                width: 1.15rem;
                left: 1.444rem;
                top: 0;
                border-bottom: 1px solid var(--blue-050);
                z-index: 1;
            }

            & .half-height-bottom-border {
                height: 1.2rem !important;
            }

            & .child-nodes > .node-container {
                &:last-child > div {
                    &:first-child {
                        height: 1.2rem !important;
                    }
                }
            }
        }
    }
    .none {
        display: none !important;
    }
}

.absolute {
    position: absolute;
    z-index: 3;
}
