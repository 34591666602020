.quick-cart-content-box {
    .content-header-child {
        .content-header-child-top {
            svg {
                width: 1rem;
                height: 1rem;

                path {
                    fill: var(--light-500);
                }
            }
        }
    }
}
