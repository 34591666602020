.customer-page{
  .customer-header-right{
    display: flex;
    gap: 1rem;
    .customer-cta{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.5rem;
     height: 2.5rem;
     padding: 0;
    }
  }
}
.create-customer-modal {
    .modal-line {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .input-wrapper {
        width: 100%;
      }
      .input {
        width: 100%;
        margin-bottom: 10px;
      }
    }

    p {
      margin: 10px;
    }
  }

  .customer-list-container {
    & > .customer-list-item {
      cursor: pointer;
      border: 1px solid var(--yellow-800);
      border-radius: 0.444rem;
      margin-bottom: 0.556rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0.556rem;

      &:hover {
        box-shadow: 0 0 0.889rem rgba(253, 192, 14, 0.5);
      }

      & > .expand-content {
        border-top: 1px solid var(--yellow-300);
        padding: 0 0.556rem;
      }
    }

    .customer-list-info{
      width: 80%;
    }
  }
