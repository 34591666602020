.edit-equipment-part-store-modal {
    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 10px;
    }

    & .important-note {
        font-size: var(--body-text-size-sm);
        color: var(--red-100);
    }

    & .important-inline-note {
        color: var(--red-100) !important;
    }
}

.edit-customer-equipment-modal {
    width: 35%;

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        & > .checkbox-line {
            display: flex;

            & > .checkbox-container {
                margin-right: 10px;
            }

            & > .modal-line-text {
                color: var(--grey-800);
            }
        }

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }

        &.small-line {
            max-width: 120px;
        }
    }

    p {
        margin: 10px;
    }
}

.visible-icon {
    display: flex;
    padding-left: 10px;
    margin-top: 15px;
}

.delete-approval-modal {
    width: 40%;
}

.fix-margin {
    margin-bottom: 16px;
}

.tab-container {
    width: 100%;
    display: inline-flex;

    .visible-switch-container {
        display: flex;
        align-items: center;
        width: 2.8rem;
        height: 2.8rem;
        margin-right: 0.5rem;

        .switch-button {
            padding: 6px;
            width: 60px;

            & .MuiSwitch-thumb {
                background-color: var(--grey-600);
            }

            & .MuiSwitch-track {
                border-radius: 20px;
                background-color: #dedbdb;
                border: 1px solid var(--grey-600);
            }

            & .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
                background-color: white;
                border: 1px solid var(--blue-050);
            }
        }

        & .Mui-checked {
            .MuiSwitch-thumb {
                background-color: var(--blue-900);
            }
        }
    }

    .upload-button {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 4;
        width: 2.8rem;
        height: 2.8rem;
        padding: 0;
        margin-right: 0.5rem;
    }

    & .toggle-button {
        width: 2.8rem;
        height: 2.8rem;
        margin-right: 0.5rem;

        &:hover {
            border: 1px solid #fdc00e;
            background-color: white;
        }
    }

    & .disabled {
        pointer-events: none;
    }

    & .divider {
        width: 2px;
        background-color: var(--grey-100);
        margin: 0 0.5rem;
        height: 2.8rem;
    }

    .tree-view-container {
        position: relative;

        .tree-view-button {
            background-color: var(--yellow-800);
            width: 55px;
            height: 50px;
            margin-right: 10.5px;
            margin-bottom: 0;
            padding: 0;
        }

        .tree-view {
            position: absolute;
            top: 64px;
            left: 0;
            z-index: 5;
        }
    }

    .edit-assembly-button {
        width: 2.8rem;
        height: 2.8rem;
        padding: 0;
    }

    .create-document-button {
        height: 2.8rem;
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
    }
}

.equipment-detail {
    display: flex;
    column-gap: 1rem;

    input {
        height: 2.8rem !important;
    }
}

.manufacturer-customer-equipment-detail-page {
    & .content-box {
        .equipment-type {
            background-color: #a2cdb3;
            color: var(--blue-200);
            padding: 0.2rem 0.5rem;
            border-radius: 18px;
            margin-top: 1rem;
            font-weight: 300;
        }

        .part-number {
            color: var(--grey-600);
            font-size: var(--body-text-size-sm);
            font-weight: 400;
            margin-left: 0.5rem;

            b {
                color: var(--grey-800);
            }
        }

        & .detail-page-container {
            display: flex;
            column-gap: 1rem;
            width: 100%;

            & > .absolute {
                position: absolute;
                z-index: 1;
            }

            & > .detail-page {
                width: 100%;

                & > .content-container {
                    display: flex;
                    justify-content: space-between;
                    gap: 1rem;

                    @media only screen and (min-width: 1200px) {
                        & > :first-child {
                            width: 70%;
                        }

                        & > :last-child {
                            width: 30%;
                        }
                    }

                    @media only screen and (max-width: 800px) {
                        flex-direction: column;

                        & > * {
                            width: 100%;
                        }
                    }

                    @media only screen and (min-width: 801px) and (max-width: 1200px) {
                        & > :first-child {
                            width: 70%;
                        }

                        & > :last-child {
                            width: 30%;
                        }
                    }
                }

                & .yellow-wrapper {
                    display: block;
                    border-radius: 8px;
                    padding: 10px;
                    align-self: stretch;
                    border: 1px solid var(--yellow-500);
                    margin-bottom: 16px;
                    justify-content: center;
                    align-items: center;
                    min-height: 930px;
                    position: relative;

                    div[data-rmiz-wrap='visible'] {
                        width: 100%;
                    }

                    & .product-image {
                        max-width: 100%;
                        width: calc(100vw);
                    }
                }

                & .yellow-wrapper.drawing-parts {
                    min-height: 575px;

                    & .product-image {
                        height: auto;
                        max-width: 100%;
                        max-height: 575px;
                        width: auto;
                    }
                }
            }
        }
    }

    .warranty-wrapper {
        width: 100%;

        .datepicker-wrapper {
            position: relative;
            width: 100%;

            .react-datepicker-wrapper {
                width: 100%;

                input {
                    width: fit-content;
                    font-size: 0.8rem;
                    color: var(--grey-800);
                    border: 0.056rem solid var(--grey-100);
                    padding: 1.3rem 0.667rem 0.5rem 0.667rem;
                    border-radius: 0.444rem;
                }
            }

            .label {
                top: 0.9rem;
                left: 0.667rem;
                color: var(--grey-600);
                font-size: 0.667rem;
                position: absolute;
                pointer-events: none;
                transition: 0.2s ease all;
                line-height: 1.75;
                font-weight: 400;
            }

            .focused-label {
                top: 0.278rem;
            }
        }
    }

    .custom-equipment-drawing-parts-container {
        margin-top: 0.5rem;

        .parts-table-container {
            .parts-table-action-menu {
                display: flex;
                justify-content: space-between;
                margin-bottom: 1.25rem;

                .addPart-button {
                    width: 2.8rem;
                    height: 2.8rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0;
                }
            }

            .monittor-table-container {
                .MuiTable-root {
                    padding: 0;
                }
            }
        }
    }

    & .equipment-detail-select-list-dropdown {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
        width: 40%;

        input {
            width: 100%;
        }

        .basic-dropdown-container {
            min-width: 50%;
        }

        .input-wrapper {
            min-width: 50%;
        }
    }

    & .small-width {
        .basic-dropdown-container {
            min-width: 100%;
        }

        .input-wrapper {
            min-width: 100%;
        }
    }

    .hortizal-row {
        display: flex !important;
        justify-content: space-between;

        .drawing-container {
            width: calc(55% - 0.75rem);

            .drawing-dashboard-container {
                position: sticky;
                top: 0;
            }
        }

        .parts-table-container {
            width: calc(45% - 0.75rem);
            .parts-table-action-menu {
                margin-bottom: 0;
                padding-bottom: 1.25rem;
                position: sticky;
                top: 0;
                background-color: #f5f6f7;
            }
        }
    }

    .overflow {
        width: 100%;
        overflow-x: auto;
        column-gap: 0.5rem;

        .drawing-container {
            width: -webkit-fill-available;
        }

        .parts-table-container {
            width: -webkit-fill-available;
        }
    }
}

.equipment-life {
    width: 100%;

    .datepicker-wrapper {
        position: relative;
        width: 100%;

        .react-datepicker-wrapper {
            width: 100%;

            input {
                width: 95%;
                font-size: 0.8rem;
                color: var(--grey-800);
                border: 0.056rem solid var(--grey-100);
                padding: 1.3rem 0.667rem 0.5rem 0.667rem;
                border-radius: 0.444rem;
            }
        }

        .label {
            top: 0.9rem;
            left: 0.667rem;
            color: var(--grey-600);
            font-size: 0.667rem;
            position: absolute;
            pointer-events: none;
            transition: 0.2s ease all;
            line-height: 1.75;
            font-weight: 400;
        }

        .focused-label {
            top: 0.278rem;
        }
    }
}

.custom-equipment-page {
    .technician-view-content {
        overflow-y: auto;
        height: calc(100vh - 190px);
    }
}
