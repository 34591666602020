.customer-checkout-email-form {
    .form-line {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 0;

        &-title {
            color: var(--blue-900);
            font-size: var(--body-text-size-sm);
        }

        &-body {
            margin-left: 12px;
            font-size: var(--body-text-size-lg);
        }

        .recipientsVisible-line {
            display: flex;
            column-gap: 0.5rem;
            margin-bottom: 1rem;
        }

        .dropdown-container {
            position: relative;

            .dropdown-selected {
                display: flex;
                position: relative;
                border-radius: 8px;
                font-size: var(--body-text-size-lg);
                justify-content: space-between;
                align-items: center;
                width: 100%;

                .input {
                    padding-right: 1rem;
                }

                .down-icon {
                    position: absolute;
                    right: 0.8rem;
                    width: 5%;
                }
            }

            .dropdown-list-container {
                top: 3.5rem;
                width: 94%;
                z-index: 1;
                position: absolute;
                background-color: white;
                box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);
                border-radius: 8px;
                padding: 3%;

                .action-container {
                    display: flex;
                    align-items: center;

                    .search-line {
                        width: 100%;

                        span {
                            font-size: var(--body-text-size-sm);
                            color: var(--grey-600);
                        }

                        .action-form-container {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            column-gap: 0.5rem;

                            .input {
                                background-color: var(--grey-050) !important;
                            }
                        }
                    }
                }

                .contact-list {
                    display: flex;
                    flex-direction: column;

                    .contact {
                        display: flex;
                        align-items: center;
                        margin: 0.5rem 0;
                        column-gap: 0.5rem;

                        .contact-info {
                            display: flex;
                            flex-direction: column;
                            width: 50%;

                            .name {
                                font-size: var(--body-text-size-lg);
                                color: var(--grey-800);
                                font-weight: 700;
                                line-height: 22px;
                            }

                            .email {
                                font-size: var(--body-text-size-md);
                                color: var(--grey-600);
                                line-height: 20px;
                            }
                        }

                        .add-contact-button {
                            position: absolute;
                            right: 0.5rem;
                            color: var(--grey-600);
                            cursor: pointer;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 3rem;
                            height: 3rem;

                            &:hover {
                                background-color: var(--blue-500);
                            }
                        }
                    }
                }
            }
            .action-button {
                color: var(--grey-600);
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                padding: 0.5rem;
                margin-bottom: 10px;

                &:hover {
                    background-color: var(--blue-500);
                }
            }
        }

        .selected-contact-list {
            display: flex;
            flex-direction: column;
            margin-top: 0.5rem;
            width: 100%;

            .contact {
                display: flex;
                font-size: var(--body-text-size-lg);
                border: 1px solid var(--grey-050);
                max-width: 100%;
                padding: 0.5rem;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                justify-content: space-between;

                .create-contact-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 0.5rem;
                    margin: -0.5rem;
                    border-left: 1px solid var(--grey-050);

                    &:hover {
                        background-color: var(--blue-500);
                    }
                }
            }
        }
    }
    .form-line-label {
        display: flex;
        color: #767676;
        font-size: var(--body-text-size-sm);
        column-gap: 0.3rem;
        svg {
            margin-top: 0.1rem;
        }
    }
}
