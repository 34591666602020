.MuiInputBase-root {
    height: 2.8rem !important;
    background: white;
    border-radius: 0.5rem !important;
    border: none;

    & fieldset {
        border: none;
    }

    &:hover fieldset {
        border: 0.056rem solid var(--grey-100) !important;
    }

    &.MuiOutlinedInput-root {
        border: none;

        & fieldset {
            border: 0.056rem solid var(--grey-100) !important;
        }

        &.Mui-focused fieldset {
            border: 1px solid var(--yellow-100) !important;
        }
    }

    .MuiBox-root {
        span {
            overflow: hidden;
        }
    }
}

.MuiFormLabel-root.MuiInputLabel-root.Mui-focused {
    color: var(--grey-600);
    font-weight: 700;
}

.MuiFormControl-root {
    width: 100%;

    .basic-input-container label.MuiInputLabel-root {
        font-size: var(--body-text-size-xs);
    }
}

.basic-dropdown-container {
    .basic-input-container {
        position: relative;

        .required {
            color: var(--red-500);
        }

        .clear-icon {
            position: absolute;
            z-index: 2;
            right: 2rem;
            top: calc(1.4rem - 12px);
            padding: 4px;
            border-radius: 50%;

            &:hover {
                background-color: var(--grey-100);
            }
        }
    }
}
