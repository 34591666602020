$cartContainerPadding: 16px;

.shopping-cart-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: $cartContainerPadding;
    background-color: white;
    height: -webkit-fill-available;

    .cart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: $cartContainerPadding;
        border-bottom: 4px solid var(--blue-10);
        margin: -$cartContainerPadding;
        margin-bottom: 0;

        .cart-title {
            display: flex;
            align-items: center;
            justify-content: center;
            column-gap: 0.5rem;
            font-size: var(--body-text-size-lg);

            svg {
                color: var(--yellow-300);
            }
        }

        .cart-close {
            margin-right: 1rem;

            svg {
                width: 1rem;
                height: 1rem;
                color: var(--grey-600);
            }
        }
    }

    .cart-body {
        height: -webkit-fill-available;
        overflow-y: auto;

        .selected-customer-name {
            font-size: var(--body-text-size-sm);
            color: black;
            background-color: #a2cdb3;
            border-radius: 18px;
            padding: 5px;
        }

        .cart-wrapper {
            padding: 8px;

            .cart-parent-name {
                font-size: 0.6rem;
                font-weight: bold;
                color: var(--blue-900);
            }

            .cart-content-name {
                font-size: 0.889rem;
                font-weight: bold;
                color: var(--blue-900);
            }

            .cart-content-description {
                font-size: var(--body-text-size-sm);
                color: var(--grey-600);
            }
        }
    }

    .cart-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        padding: 0.5rem;
        // border: 1px solid var(--yellow-300);
        border-radius: 13px;

        .total-wrapper {
            width: 100%;
            display: flex;
            justify-content: flex-end;

            .total-price {
                display: flex;

                &-title {
                    color: var(--blue-900);
                }

                &-value {
                    color: var(--blue-900);
                    font-weight: 700;
                }
            }
        }
    }

    .cart-add-more {
        .additional-input {
            display: flex;
            column-gap: 3%;

            .line {
                width: 30% !important;
            }
        }
    }

    .reorder-notes {
        font-size: var(--body-text-size-sm);
        color: red;
    }
}
