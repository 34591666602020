.footer-container {
    display: flex;
    flex-direction: column;

    .top {
        height: 8.75rem;
        background-color: var(--blue-900);
        display: flex;
        padding: 1.25rem 3.75rem;
        align-items: center;

        .manufacturer-info {
            display: flex;
            align-items: center;
            column-gap: 1rem;
            padding-right: 2.75rem;
            border-right: 1px solid var(--blue-200);
            height: 80%;

            .name {
                font-size: var(--body-text-size-3xl);
                font-weight: 700;
                color: white;
            }
        }

        .about-container {
            display: flex;
            justify-content: space-between;
            width: 51%;
            margin-left: 9%;

            .about-item {
                display: flex;
                flex-direction: column;
                color: white;

                .title {
                    font-size: var(--body-text-size-xl);
                    font-weight: 700;
                }

                .go-to {
                    font-size: var(--body-text-size-sm);
                }
            }
        }
    }

    .bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 4.75rem;
        background-color: var(--blue-200);

        .powered-by {
            font-size: var(--body-text-size-sm);
            color: var(--grey-400);
            margin-right: 3.75rem;
        }
    }
}
