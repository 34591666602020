.currency-input-wrapper {
    position: relative;
    width: 100%;

    input {
        width: 100% !important;
        color: var(--grey-800);
        border: 0.056rem solid var(--grey-100);
        box-sizing: border-box;
        border-radius: 0.444rem;
        font-size: var(--body-text-size-sm);
        background-color: white;
        height: 2.8rem;
        padding-top: 0.6rem;
        padding-left: 0.75rem;

        + .label {
            top: 0.85rem;
        }

        &:focus + .label {
            top: 0.3rem;
        }
    }

    .label {
        top: 25%;
        left: 0.75rem;
        color: var(--grey-600);
        font-size: 0.667rem;
        position: absolute;
        pointer-events: none;
        transition: 0.2s ease all;
        line-height: 1.75;
        font-weight: 400;
    }
}

.required {
    color: var(--red-500);
}

.has-value {
    top: 0.3rem !important;
}
