.notification-page {
    .notification-page-title {
        display:  flex;
        gap: 1rem;
    }

    .no-notification-info {
        color: var(--grey-600);
        display: flex;
        gap: 1rem;
        align-items: center;
    }

    .notification {
        margin-top: 10px;
        padding: 30px;
        background-color: white;
        border-radius: 6px;
        min-height: 100px;
        min-width: 200px;

 
        .notification-item {
            width: 450px;
            font-size: 18px;
            padding: 12px;

            .notification-title {
                display: flex;
                justify-content: space-between;
                color: var(--grey-600);

                .notification-button-wrapper {
                    display: flex;
                    align-items: center;
                    gap: 12px;
                }

                .notification-button {
                    cursor: pointer;
                    height: 30px;
                    width: 30px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    &:hover {
                        background-color: white;
                    }
                }
            }

            .notification-text {
                font-size: 16px;
            }

            .notification-date {
                font-size: 12px;
            }

            &:hover {
                background-color: var(--grey-050);
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--yellow-300);
            }

            &.new {
                background-color: var(--yellow-500);
            }
            
            @media screen and (max-width: 500px) {
                width: 96%;
            }
        }

    }
}