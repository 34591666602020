$typeContainerWidth: 240px;
$typeContainerHeight: 40px;
$space: 5px;

.activity-partial-type-container {
    position: relative;
    margin-bottom: 0.5rem;
    z-index: 3;

    .selected-one {
        position: relative;
        width: fit-content;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border-radius: calc($typeContainerHeight / 2);
        overflow: hidden;
        padding: 0 0.5rem;
        column-gap: 0.5rem;

        &.ticket {
            color: #579293;
            background-color: #d5e3e4;
            border: 1px solid #579293;

            &.enabled-dropdown:hover {
                border: 2px solid #579293;
                font-weight: bold;
            }
        }

        &.service {
            color: #97834e;
            background-color: #e5e0d2;
            border: 1px solid #97834e;

            &.enabled-dropdown:hover {
                border: 2px solid #97834e;
                font-weight: bold;
            }
        }

        &.product-replenishment {
            color: #695397;
            background-color: #dad4e5;
            border: 1px solid #695397;

            &.enabled-dropdown:hover {
                border: 2px solid #695397;
                font-weight: bold;
            }
        }

        &.disabled-dropdown {
            cursor: default;
        }

        &.focused-dropdown.enabled-dropdown {
            border: 2px solid #fdc00e !important;
            background-color: white !important;
            color: black !important;
        }

        svg {
            position: relative;
        }

        .type-icon {
            position: relative;
            transform: translateY(5px);
        }

        .selected-one-text {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box !important;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-size: var(--body-text-size-md);
        }
    }

    .type-options {
        position: absolute;
        left: 5px;
        top: calc($typeContainerHeight + $space - 5px);
        width: calc($typeContainerWidth * 8 / 7);
        display: flex;
        flex-direction: column;
        z-index: 2;
        box-shadow: 0px 0px 12px rgba(53, 74, 99, 0.5);
        border-radius: calc($typeContainerHeight / 2);
        background-color: white;

        .type-option {
            position: relative;
            width: 100%;
            background-color: white;
            cursor: pointer;
            margin-block: 5px;

            .type-text {
                margin-left: 20px;
                position: relative;
                width: fit-content;
                border-radius: calc($typeContainerHeight / 2);
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                font-size: var(--body-text-size-md);
                padding: 0 0.5rem;
                column-gap: 0.5rem;

                .type-icon {
                    position: relative;
                    transform: translateY(5px);
                }

                &.ticket {
                    color: #579293;
                    background-color: #d5e3e4;
                    border: 1px solid #579293;

                    &:hover {
                        border: 2px solid #579293;
                        font-weight: bold;
                    }
                }

                &.service {
                    color: #97834e;
                    background-color: #e5e0d2;
                    border: 1px solid #97834e;

                    &:hover {
                        border: 2px solid #97834e;
                        font-weight: bold;
                    }
                }

                &.product-replenishment {
                    color: #695397;
                    background-color: #dad4e5;
                    border: 1px solid #695397;

                    &:hover {
                        border: 2px solid #695397;
                        font-weight: bold;
                    }
                }
            }

            .billable {
                position: relative;
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                font-size: var(--body-text-size-md);
                margin-left: 30px;

                .MuiCheckbox-root {
                    transform: scale(0.7);
                }
            }

            &:hover {
                background-color: #f5f6f7;
            }

            &:first-child {
                border-radius: calc($typeContainerHeight / 2) calc($typeContainerHeight / 2) 0 0;

                .type-text {
                    margin-top: 10px;
                }
            }

            &:last-child {
                border-radius: 0 0 calc($typeContainerHeight / 2) calc($typeContainerHeight / 2);

                .type-text {
                    margin-bottom: 10px;
                }
            }
        }
    }
}
