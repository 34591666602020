.drawing-dashboard-container {
    display: flex;
    flex-direction: column;

    & .drawing-dashboard-controller {
        background-color: white;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        height: 3.5rem;
        padding: 0.5rem 1.25rem;
        border-radius: 1rem 1rem 0 0;
        border: 1px solid var(--grey-100);
        border-bottom: none;

        .left-actions {
            display: flex;
            gap: 0.5rem;
            align-items: center;

            .button.hotspot-settings {
                background-color: var(--blue-100);
                padding: 0.8rem;
                display: flex;
                align-items: center;
                border-radius: 50%;
                margin-right: auto;

                &:hover {
                    box-shadow: none;
                    background-color: var(--grey-300);
                }
            }

            .edit-hotspot {
                padding: 0.625rem 0;
                height: 2.5rem;
                background: var(--blue-100);
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 0.5rem;
                border: none;

                svg {
                    width: 2.5rem;
                    padding-left: 0.4rem;
                }

                &.disabled {
                    opacity: 0.5;
                    box-shadow: none;
                    cursor: initial;
                }

                span {
                    padding-right: 1rem;
                }
            }

            & > .mapping-mode-buttons {
                display: flex;
                gap: 0.5rem;

                .cancel-button {
                    background: var(--blue-10);
                }
            }
        }

        .right-actions {
            display: flex;
            align-items: center;
            gap: 0.5rem;
        }

        & .no-data {
            background-color: white;
            height: 8.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 1rem;
            border: 1px solid var(--grey-100);
            margin-bottom: 15px;

            .label {
                font-size: var(--body-text-size-sm);
                font-weight: 700;
            }
        }
    }

    .drawing-dashboard {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        border-radius: 0 0 1rem 1rem;
        padding: 1.25rem;
        align-self: stretch;
        background-color: white;
        margin-bottom: 1.25rem;
        border: 1px solid var(--grey-100);
        border-top: none;
        row-gap: 0.5rem;

        .pagination {
            width: 100%;
            height: 1.5rem;
            display: flex;
            justify-content: center;
            align-items: center;

            .wrapper {
                display: flex;
                gap: 0.5rem;

                .child {
                    height: 0.625rem;
                    width: 0.625rem;
                    border-radius: 50%;
                    background: #d9d9d9;

                    &.active {
                        background-color: var(--grey-600);
                    }
                }
            }
        }

        .btn {
            position: relative;
            height: 2.5rem;
            width: 2.5rem;
            background-color: var(--blue-900);
            border-radius: 0.5rem;
            padding: 0;
            z-index: 1;

            svg {
                fill: white;
            }
        }

        span {
            padding-right: 1rem;
        }
    }
}
