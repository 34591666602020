.equipment-info-cart-container {
    display: flex;
    padding: 2rem;
    justify-content: space-around;
    background-color: white;
    border-radius: 16px;

    .divider {
        width: 1px;
        background-color: var(--grey-100);
        margin: 0.5rem 0;
    }

    .warranty-box {
        border: 1px solid #a2cdb3;
        border-left: 8px solid #a2cdb3;
        text-align: center;
        width: 25.3%;
        height: 12.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .customer-amount-box {
        text-align: center;
        width: 25.3%;
        height: 12.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .installations-amount-box {
        text-align: center;
        width: 25.3%;
        height: 12.5rem;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
    }

    .title {
        font-size: var(--body-text-size-sm);
        color: var(--blue-900);
        font-weight: 700;
    }

    .content {
        display: flex;
        flex-direction: column;
        color: var(--grey-800);
        text-align: center;

        .amount {
            font-size: 3rem;
            line-height: normal;
        }

        .unit {
            line-height: normal;
            font-size: var(--body-text-size-md);
        }
    }

    .add-button {
        font-size: var(--body-text-size-sm);
        color: #5f9074;
        text-decoration: underline;
        cursor: pointer;
    }
}

.horizontal-divider {
    width: 100%;
    height: 1px;
    background-color: var(--grey-100);
    margin: 1rem 0.5rem;
}

.container-wrap {
    flex-wrap: wrap;

    .warranty-box {
        width: 45%;
    }

    .customer-amount-box {
        width: 45%;
    }

    .installations-amount-box {
        width: 100%;
    }
}
