.master-page {
    .create-activity-button {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
    }

    .filter-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        padding: 0;
        border: 2px solid var(--yellow-100);
        background-color: transparent;
    }

    .activity-right-buttons {
        display: flex;
        justify-content: flex-end;
        column-gap: 0.5rem;

        .block-dropdown-input {
            width: 30%;
        }
    }

    .filter-menu {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;

        .input {
            background-color: white;
        }
    }

    .content {
        display: flex;
        justify-content: space-between;

        .activity-table {
            width: 100%;
        }

        .onBox {
            width: 68%;
        }

        .partial-update-box {
            height: calc(100vh - 280px);
            display: flex;
            width: 30%;
            position: fixed;
            right: 1rem;
        }
    }
}
