.product-info-cart-container {
    display: flex;
    padding: 2rem;
    justify-content: space-around;
    background-color: white;
    border-radius: 16px;
    flex-direction: row;
    height: 8.25rem;
    width: 100%;

    .divider {
        width: 1px;
        background-color: var(--grey-100);
        margin: 0.5rem 0;
    }

    .group {
        display: inline-flex;
        gap: 0.5rem;
        align-items: center;
    }
    .left {
        width: 25.3%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .mid {
        width: 25.3%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 1.25rem;
        .item {
            display: flex;
            flex-direction: column;
        }
    }

    .right {
        width: 25.3%;
        height: 8rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .title {
        font-size: var(--body-text-size-sm);
        color: var(--blue-900);
        font-weight: 700;
    }

    .content {
        display: flex;
        flex-direction: column;
        color: var(--grey-800);
        text-align: center;

        .amount {
            font-size: 3rem;
            line-height: normal;
        }

        .unit {
            line-height: normal;
            font-size: var(--body-text-size-md);
        }
    }

    .add-button {
        font-size: var(--body-text-size-sm);
        color: #5f9074;
        text-decoration: underline;
        cursor: pointer;
    }
}

.price-section {
    display: flex;
    flex-direction: row;
    align-items: center;

    .slash-package {
        color: var(--grey-600);
        font-size: var(--body-text-size-sm);
    }
}

.product-card {
    border-radius: 1rem;
    padding: 2rem;
    background: #ffffff;
    &--left {
        width: 12.9375rem;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
    }

    &--right {
        width: calc(100% - 12.9375rem);
        display: flex;
        align-items: center;
        justify-content: center;
        .product-card__line {
            width: 12.5rem;
            height: 12.5rem;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            text-align: center;
            &--header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                &__title {
                    font-weight: 700;
                    color: var(--blue-900);
                    line-height: 1.125rem;
                    font-size: var(--body-text-size-sm);
                }
                &__dropdown {
                    font-size: var(--body-text-size-sm);
                    color: #6f947f;
                    text-decoration: underline;
                    line-height: 1.125rem;
                    cursor: pointer;
                }
            }
            &--content {
                font-weight: 300;
                font-size: 3rem;
            }
            &--footer {
                font-size: var(--body-text-size-sm);
                color: #6f947f;
                line-height: 1.125rem;
                text-decoration: underline;
                cursor: pointer;
            }
            &:first-child {
                padding-right: 2rem;
                border-right: 1px solid var(--grey-100);
            }
            &:last-child {
                padding-left: 2rem;
                &--header {
                    justify-content: center !important;
                    width: 100% !important;
                }
            }
        }
    }
    &__line {
        display: flex;
        flex-direction: column;
        &__title {
            font-weight: 700;
            font-size: var(--body-text-size-sm);
            color: var(--blue-900);
            line-height: 1.125rem;
        }
        &__value {
            font-size: var(--body-text-size-md);
            color: var(--grey-800);
            line-height: 1.25rem;
        }
        &__add {
            color: #5f9074;
            text-decoration: underline;
            font-size: var(--body-text-size-sm);
            cursor: pointer;
        }
        &--unit-price {
            border: 1px solid var(--light-500);
            border-radius: 0.5rem;
            padding: 0.75rem 1.5rem;
        }
    }
}

.product-info-card {
    display: flex;
    gap: 2rem;
    width: 100%;
    &--images {
        background: white;
        height: 100%;
        border-radius: 1rem;
        display: flex;
        justify-content: space-between;
        .product-card {
            display: flex;
            flex-direction: column;
            gap: 1.25rem;
            width: 100%;
            border-right: 1px solid grey;

            &__line {
                &__value {
                    font-size: var(--body-text-size-md);
                    line-height: 1.25rem;
                    color: var(--grey-800);
                }
                &:last-child {
                    border: 1px solid #eea561;
                    padding: 0.75rem 1.5rem;
                    border-radius: 0.5rem;
                }
            }
            &__header {
                font-weight: 700;
                font-size: var(--body-text-size-sm);
                display: flex;
                flex-direction: column;
                justify-self: flex-start;
            }
            &__content {
                font-size: 3rem;
            }
            &__footer {
                text-decoration: underline;
                font-size: var(--body-text-size-sm);
                color: #6f947f;
            }
            &--custom {
                align-items: center;
            }
        }
    }
}

.product-image-card {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 2rem;
    border-radius: 1rem;
    background: white;
    gap: 1.25rem;
    &__column {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 1.25rem;
        border-right: 1px solid var(--grey-100);
        padding-right: 1.25rem;
        padding-left: 1.25rem;
        &__line {
            display: flex;
            flex-direction: column;
            &__title {
                font-weight: 700;
                color: var(--blue-900);
                font-size: var(--body-text-size-sm);
                line-height: 1.125rem;
            }
            &__value {
                font-size: var(--body-text-size-md);
                color: var(--grey-800);
                line-height: 1.25rem;
            }
            &:last-child {
                padding: 0.75rem 1.5rem;
                border: 1px solid #eea561;
                border-radius: 0.5rem;
            }
            &__add {
                line-height: 1.125rem;
                text-decoration: underline;
                color: #5f9074;
                font-size: var(--body-text-size-sm);
                cursor: pointer;
            }
        }
        &:last-child {
            border-right: 0;
        }
        &__header {
            font-weight: 700;
            color: var(--blue-900);
            font-size: var(--body-text-size-sm);
            line-height: 1.125rem;
        }

        &__content {
            font-size: 3rem;
        }

        &__footer {
            text-decoration: underline;
            font-size: var(--body-text-size-sm);
            color: #6f947f;
            cursor: pointer;
        }
        &:not(:first-child) {
            align-items: center;
        }
        &:first-child + & {
            > div:first-child {
                width: 100%;
            }
        }
    }
}
