.dropdownbutton {
    position: relative;
    display: flex;

    &-container {
        position: relative;
        width: 100%;
        & .dropdownbutton-label {
            position: absolute;
            font-size: 0.66rem;
        }

        & .dropdowninner-button {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 15rem;
            border: none;
            height: 2.8rem;
            border-radius: 8px;
            font-size: var(--body-text-size-md);
            color: var(--grey-800);
            background-color: white;
            position: relative;
            gap: 0.9375rem;
            &-icon-container {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: 2.333rem;
                height: 100%;
                padding-right: 1.25rem;
            }
            .dropdownbutton-text {
                padding: 0.5rem 1rem;
                width: 100%;
                font-size: 0.85rem;
            }
        }

        & .focus {
            border: 1px solid var(--yellow-100) !important;
        }
    }

    &-transparent-container {
        display: flex;
        position: absolute;
        width: 100%;
        top: 2.9rem;
        background-color: transparent;

        & .dropdownbutton-content {
            border-radius: 0.667rem;
            position: absolute;
            display: block;
            background-color: white;
            min-width: 100%;
            box-shadow: 0 0.444rem 0.889rem 0 rgba(0, 0, 0, 0.2);
            z-index: 1;
            font-size: 0.85rem;
            //border-radius: 0.5rem;
            & a {
                color: black;
                padding: 0.667rem;
                text-decoration: none;
                display: block;
            }

            & a:first-child {
                border-radius: 0.667rem 0.667rem 0 0;
            }

            & a:last-child {
                border-radius: 0 0 0.667rem 0.667rem;
            }

            & a:last-child {
                border-bottom: none;
            }

            & a:hover {
                background-color: #f1f1f1;
            }

            &--selected {
                background: var(--blue-10);
                /* the color seems blue-100 in figma
                but there is blue-100 show a different color in index.scss that's why i used hex format */
                color: #354a63;
                font-weight: 700;
            }
        }
    }
}
