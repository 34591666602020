.tabs {
    display: flex;
    flex: 1 1 auto;
    margin-bottom: 20px;
    height: 40px;
    border-bottom: 2px solid var(--grey-050);
    .tab {
        height: 40px;
        cursor: pointer;
        font-size: 0.889rem;
        display: flex;
        align-items: center;
        line-height: normal;
        color: var(--grey-600);
        padding: 0 0.444rem 0 0.444rem;
        flex-direction: row-reverse;

        &.active {
            color: var(--blue-900);
            border-bottom: 2px solid var(--blue-900);
        }

        &-icon {
            display: flex;
            align-items: center;
            padding-right: 0.444rem;
        }
    }
}
