.contract-list-page {
    .contract-header-right {
        display: flex;
        column-gap: 1rem;
        .add-contract {
            .button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.8rem;
                height: 2.8rem;
                padding: 0;
                background-color: var(--yellow-800);
            }
        }
    }
}
