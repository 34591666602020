.input-wrapper {
    textarea {
        width: 100%;
        color: var(--grey-800);
        background-color: var(--blue-100);
        border: 0.056rem solid var(--grey-100);
        box-sizing: border-box;
        border-radius: 0.444rem;
        padding: 1.4rem 0.667rem 0.4rem 0.667rem !important;
        height: 3rem;
        resize: none !important;
        overflow: hidden;
        &:disabled,
        &[disabled] {
            opacity: 0.5;
        }
    }

    .autoSize {
        resize: vertical !important;
    }

    .label-wrapper {
        position: absolute;
        top: 0.12rem;
        left: 0.11rem;
        width: 98%;
        height: 1.2rem;
        background: white;
        z-index: 0;
        border-radius: 0.35rem;
    }
}
