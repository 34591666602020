.activity-title-input {
    & > .input {
        border: 1px solid white;
        font-size: var(--body-text-size-3xl);

        &:not(:disabled):hover {
            border: 1px solid #edeff2;
        }

        &:focus {
            border: 1px solid #fdd768;
        }

        &:disabled {
            background-color: white !important;
        }
    }

    input {
        outline: none !important;
    }

    span.label {
        font-size: var(--body-text-size-xl);
        transform: translateY(-20%);
    }

    &.filled-input {
        & > input {
            padding-top: 0.4rem !important;

            &:not(:focus) {
                font-size: var(--body-text-size-3xl);
            }
        }
    }

    &.empty-input {
        & > .input {
            &:not(:hover) {
                background-color: #f5f6f7;
                border: 2px dashed #a2adbb;
            }
        }
    }
}
