.re-captcha {
    margin: 0.8rem 0;
    transform: scale(1.08);
    -webkit-transform: scale(1.08);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0;
}

@media screen and (min-width: 1441px) {
    .re-captcha {
        transform: scale(1.24);
        -webkit-transform: scale(1.24);
        transform-origin: 0 0;
        -webkit-transform-origin: 0 0;
    }
}