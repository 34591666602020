.addPart-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    padding: 0;
    margin-right: 5px;
}
.parts-actions-buttons {
    display: flex;
    justify-content: right;
    width: 50%;
}

.installation-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    .show-to-customer {
        height: 2.7rem;
        width: 4rem;
        background: none;
        .MuiSwitch-switchBase {
            top: 0.3rem;
            left: 0.4rem;
            .MuiSwitch-thumb {
                background-color: var(--grey-600);
            }
            &.Mui-checked {
                left: 0;
                right: 0.95rem;
                & + .MuiSwitch-track {
                    background-color: var(--blue-050);
                    opacity: 1;
                }
                & .MuiSwitch-thumb {
                    background-color: var(--blue-200);
                }
            }
        }
        .MuiSwitch-track {
            background-color: var(--grey-100);
            border-radius: 1rem;
            opacity: 1;
        }
    }
    & > .popover-item {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    & > .popover-item:nth-child(2) {
        padding: 0 1rem;
    }
    .action-cell {
        padding: 0 1rem;
    }
}
