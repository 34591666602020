.contact-container {
    height: 19rem;
    padding: 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 2px solid var(--grey-050);
    border-radius: 8px;
    width: 30%;

    .contact-item {
        display: flex;
        flex-direction: column;
        height: 50%;

        .contact-title {
            font-size: var(--body-text-size-2xl);
        }

        .contact-body {
            padding: 1rem;
            border-bottom: 4px solid var(--blue-10);
            font-size: var(--body-text-size-lg);

            button {
                border: none;
                border-radius: 8px;
                padding: 1rem;
                background-color: var(--red-100);
                color: white;
                font-size: var(--body-text-size-lg);
                font-weight: 700;
                width: 100%;
            }

            .contact-info {
                display: flex;
                flex-direction: column;
                justify-content: space-around;

                .name {
                    color: var(--grey-800);
                }

                .dot {
                    background-color: var(--yellow-800);
                    width: 4px;
                    height: 4px;
                    border-radius: 50%;
                }

                .phone {
                    color: var(--grey-600);
                }

                .email {
                    width: min-content;
                    background-color: var(--blue-200);
                    border-radius: 18px;
                    color: var(--blue-10);
                    padding: 0 0.5rem;
                }
            }

            .top {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }

        & :last-child {
            border-bottom: none;
        }
    }
}
