.create-document-modal {
      & .modal-line {
      width: 100%;
      display: flex;
      justify-content: space-between;

      & > * {
        width: 100%;
        margin-bottom: 0.556rem;
      }
    }
}