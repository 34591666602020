.contacts-container {
    .header {
        padding: 0.32rem 0.833rem;

        .header-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
        }
    }

    .content {
        display: flex;
        padding: 1rem;

        .no-data {
            display: flex;
            flex-direction: column;
            width: 100%;
            align-items: center;

            span {
                font-size: var(--body-text-size-lg);
                color: var(--grey-800);
                text-align: center;
            }

            .add-button {
                display: flex;
                align-items: center;
                column-gap: 1rem;
            }
        }

        .contacts {
            display: flex;
            column-gap: 1rem;
            overflow-y: auto;
            width: 100%;
            padding: 0.8rem 0;

            .hover-card {
                min-width: 35%;
                max-width: 35%;
                padding: 0 0.5rem;
                column-gap: 2rem;
                height: auto;

                .header {
                    padding: 0.833rem;

                    .title {
                        display: flex;
                        word-break: break-all;
                        width: max-content;

                        h4 {
                            font-size: 1.15rem;
                        }
                    }

                    .right-button {
                        display: flex;
                        align-items: center;
                        width: fit-content;
                        column-gap: 1rem;

                        .contact-cart-action {
                            display: flex;
                            justify-content: right;
                            width: 100%;
                            float: left;
                            color: var(--grey-600);
                            display: flex;
                            column-gap: 0.9rem;

                            svg {
                                &:hover {
                                    color: var(--blue-900);
                                }
                            }
                        }
                    }


                }

                .content {
                    display: flex;
                    flex-direction: column;

                    .card-row {
                        display: flex;
                        column-gap: 1rem;
                        padding: 0.8rem 0;
                        border-bottom: 1px solid var(--blue-100);

                        .icon {
                            svg {
                                width: 1.25rem;
                                height: 1.25rem;
                            }
                        }

                        .row-content {
                            display: flex;
                            flex-direction: column;

                            .title {
                                color: var(--blue-900);
                                font-size: var(--body-text-size-sm);
                            }

                            .value {
                                color: var(--grey-800);
                                font-size: var(--body-text-size-lg);
                                margin-left: 0.5rem;
                            }
                        }

                        &:first-child {
                            padding-top: 0;
                        }

                        &:last-child {
                            padding-bottom: 0;
                            border: none;
                        }
                    }
                }
            }

            &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px transparent;
                border-radius: 10px;
            }

            &::-webkit-scrollbar {
                height: 8px;
                border-radius: 10px;
            }

            &::-webkit-scrollbar-thumb {
                background: var(--blue-050);
                border-radius: 10px;
            }


        }
    }
}