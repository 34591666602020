.shipping-form-container {
    .address-dropdown {
        margin: 0.8rem 0;
    }
    .customer-checkout-shipping {
        .input-wrapper {
            .input {
                margin-bottom: 0 !important;
            }
        }
    }
}
