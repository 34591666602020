.category-image {
    text-align: center;
    width: calc(100% / 12);

    img {
        width: 100%;
        height: auto;
    }
}

.element-wrapper {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;

    .card-wrapper {
        display: flex;
        justify-content: space-between;
        flex: 1;

        .title {
            color: var(--blue-900);
            font-size: 0.889rem;
            font-weight: bold;
        }

        .column-card {
            display: flex;
            line-height: normal;
            flex-direction: column;
        }

        .desc-wrapper {
            display: flex;
            flex: 1;

            .desc-alg {
                padding-right: 0.556rem;
                display: flex;
                align-items: center;
            }

            .desc-column {
                display: flex;
                flex-direction: column;
            }
        }

        .label {
            color: var(--grey-600);
            font-size: 0.778rem;
        }

        .desc {
            font-size: 0.778rem;
            padding-left: 0.356rem;
        }
    }

    .long-text {
        word-break: break-word;
        line-height: normal;
        color: var(--grey-600);
        font-size: 0.778rem;
        margin-top: 0.889rem;
    }
}

.customer-product-list-wrapper {
    .add-to-cart {
        height: 2rem;
        width: 2rem;
        padding: 0;
        path {
            fill: var(--blue-900);
        }
    }
}
