.activity-tabs-no-data-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 0.5rem;

    .message {
        font-size: var(-body-text-size-lg);
        color: var(--grey-800);
    }
    .no-data-button {
        display: flex;
        align-items: center;
        column-gap: 0.5rem;
    }
}
