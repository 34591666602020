.page-title-menu {
    display: flex;
    align-items: center;

    .equipment-name {
        font-size: var(--body-text-size-2xl);
    }

    .root-equipment-name {
        font-size: var(--body-text-size-2xl);
        color: var(--grey-600);
    }

    .chevron-right {
        margin: 0 1rem;
        font-size: var(--body-text-size-sm);
        color: var(--grey-600);
    }
}
