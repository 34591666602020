.shipping-method-form-container {
    display: flex;
    flex-direction: column;
    margin-top: 0.5rem;

    .form-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 0.8rem;
        }

        .datepicker-wrapper {
            width: 100%;
            position: relative;

            .react-datepicker-wrapper {
                .react-datepicker__input-container {
                    display: flex;
                }

                input {
                    width: 100%;
                    font-size: 0.8rem;
                    color: var(--grey-800);
                    background-color: white;
                    border: 0.056rem solid var(--grey-100);
                    padding: 1.3rem 0.667rem 0.5rem 0.667rem;
                    border-radius: 0.444rem;
                    margin-bottom: 0.8rem;
                }
            }

            .label {
                top: 0.9rem;
                left: 0.667rem;
                color: var(--grey-600);
                font-size: 0.667rem;
                position: absolute;
                pointer-events: none;
                transition: 0.2s ease all;
                line-height: 1.75;
                font-weight: 400;
            }

            .focused-label {
                top: 0.278rem;
            }
        }
    }
}
