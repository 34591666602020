.sign-up-page {
    height: 100%;

    .form-wrapper {
        margin-top: 15%;
        margin-left: 15%;
    }

    .message-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .foot-note {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 5px;
    }
    .re-captcha{
        margin-top: 0.5rem;
    }
}
