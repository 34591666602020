.upload-drawing-parts-modal {
    width: 30rem;
    padding: 1.25rem;

    .modal-title {
        h4 {
            font-weight: 400 !important;
            font-size: var(--body-text-size-2xl) !important;
            line-height: 28px !important;
        }
    }

    .modal-footer {
        border-top: 4px solid #f5f6f7;
        padding-top: 1.563rem;
    }

    .modal-content-title {
        font-weight: 400;
        font-size: var(--body-text-size-lg);
        color: #354a63;
        line-height: 22px;
        margin-bottom: 0.938rem;
    }

    .images-section {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.8rem;
    }

    .pdf-section {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.8rem;
        width: 100%;
    }

    .uploaded-part-lists {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 0.8rem;
        width: 100%;

        .file-input:last-child {
            margin-bottom: 0;
        }
    }

    .file-input {
        width: 100%;
        margin-bottom: 0.8rem;

        span.label {
            font-size: var(--body-text-size-sm);
            color: #767676;
        }

        input[type='text'] {
            color: #212121;
            font-size: var(--body-text-size-md);
            outline: none;
        }

        svg {
            color: #767676;
            width: 0.583rem;
        }
    }

    .info-text-section {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;

        svg {
            color: #c4c4c4;
            width: 0.833rem;
            margin-right: 0.625rem;
            transform: translateY(-20%);
        }
    }

    .modal-content-text {
        font-weight: 400;
        font-size: var(--body-text-size-sm);
        color: #767676;
        line-height: 18px;
    }

    .template-text {
        margin-top: 0.938rem;
    }

    .MuiDivider-root {
        background-color: #f5f6f7;
        margin-block: 1.8rem;
        opacity: 0.4;
    }

    .underlined-text {
        text-decoration: underline;
        cursor: pointer;

        a {
            color: #767676;
        }
    }
}
