.create-document-modal {
    display: flex;
    flex-direction: column;
    transition: 0.3s all ease-in-out;
    & .modal-footer {
        margin-top: auto;
    }
    & .form-container {
        display: flex;
        flex-direction: column;
        row-gap: 0.8rem;
    }
    &:has(.linked-documents .form-line .dropdown-container .dropdown-list-container) {
        height: 25rem;
    }
}
