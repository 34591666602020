.notification-container {
    height: 19rem;
    padding: 2rem;
    background-color: white;
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    border: 2px solid var(--grey-050);
    border-radius: 8px;
    width: 60%;

    .header {
        display: flex;
        justify-content: space-between;

        .cart-info {
            display: flex;
            align-items: center;
            column-gap: 0.5rem;

            .title {
                font-size: var(--body-text-size-2xl);
            }

            .notification-count {
                font-size: var(--body-text-size-sm);
                color: white;
                padding: 0 0.5rem;
                border-radius: 8px;
                line-height: normal;
                background-color: var(--red-100);
            }
        }

        .go-to-all {
            font-size: var(--body-text-size-sm);
            font-weight: 700;
            color: var(--blue-900);
            cursor: pointer;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        height: max-content;
        overflow: auto;
        row-gap: 0.5rem;
        padding-right: 1rem;

        .notification-item {
            display: flex;
            justify-content: space-between;
            background-color: var(--blue-10);
            border-radius: 8px;
            padding-left: 1rem;
            border-left: 8px solid var(--blue-10);

            .notificationItemRightSide {
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--yellow-800);
                padding: 0 0.5rem;
                border-radius: 0 8px 8px 0;

                .notification-button {
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    svg {
                        fill: var(--blue-100);
                    }
                }
            }

            .notification-title {
                font-size: var(--body-text-size-lg);
                font-weight: 700;
            }

            .notification-description {
                font-size: var(--body-text-size-md);
                color: var(--grey-600);
            }
        }

        .new {
            border-left: 8px solid var(--red-100);
        }
    }
}
