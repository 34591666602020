.equipments-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3.75rem 0;
    background-color: white;

    .equipments-content {
        display: flex;
        flex-direction: column;
        width: 72.5rem;
        row-gap: 1.25rem;

        .equipments-header {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: var(--body-text-size-2xl);
                color: var(-grey-800);
            }

            .go-to-all {
                font-size: var(--body-text-size-sm);
                font-weight: 700;
                color: var(--blue-900);
                cursor: pointer;
            }
        }

        .equipments-body {
            display: flex;
            column-gap: 1.25rem;
        }
    }
}
