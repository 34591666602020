.page-wrapper {
    .no-image-container {
        min-height: 80px;
        width: calc(100% / 12);
    }
}

.img-container {
    width: calc(100% / 12);

    img {
        width: 100%;
        height: 80px;
        object-fit: cover;
    }
}

div[role='dialog'] {
    .img-wrapper {
        top: 10%;
        position: relative;

        img {
            width: 100%;
            height: auto;
        }
    }
}

.create-equipment-modal,
.duplicate-equipment-modal {
    & .modal-line-title {
        color: var(--grey-800);
    }

    .modal-line {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .input-wrapper {
            width: 100%;
        }

        .input {
            width: 100%;
            margin-bottom: 10px;
        }
    }

    p {
        margin: 10px;
    }
}

table {
    .img-container {
        display: flex;

        img {
            width: 50px;
            height: 50px;
            object-fit: cover;
        }

        .icon-container {
            position: relative;
            text-align: center;
            align-self: center;

            .no-image-text {
                display: flex;
                color: var(--grey-600);
                font-size: 16px;
            }

            .pdf-text {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .no-image-container {
        min-height: 80px;
    }
}

.element-wrapper {
    & .card-wrapper {
        & .icon-button-container {
            display: flex;
            justify-content: center;
            align-items: center;

            & > :first-child {
                padding-right: 10px;
            }
        }
    }
}

.equipment-list-content-box {
    & > .content-header {
        & > .content-header-child {
            & > .create-equipment-button {
                display: flex;

                & > svg {
                    width: 24px;
                    height: 24px;
                }
            }
        }
    }

    .action-wrapper {
        display: flex;
        gap: 1rem;

        .create-equipment-button {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.5rem;
            height: 2.5rem;
            padding: 0;
        }
    }
}

.quick-button {
    height: 2.2rem;
}
