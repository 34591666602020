.overview-card {
    display: flex;
    padding: 1.25rem;
    background-color: white;
    box-shadow: 0px 4px 4px #efeeee;
    border-radius: 8px;
    width: 100%;

    .item {
        display: flex;
        flex-direction: column;
        width: 100%;

        span {
            font-size: var(--body-text-size-md);
        }

        &-header {
            display: flex;
            justify-content: space-between;

            .title {
                font-size: var(--body-text-size-sm);
                color: var(--blue-900);
                font-weight: 700;
                margin-bottom: 0.5rem;
            }
        }

        .address-line {
            display: flex;
            column-gap: 0.2rem;

            :after {
                content: ',';
            }

            span:last-child:after {
                content: none;
            }
        }

        .line {
            display: flex;
            column-gap: 0.2rem;
            align-items: center;

            .date {
                color: var(--grey-800);
                font-size: var(--body-text-size-md);
            }

            .value {
                color: var(--grey-600);
                font-size: var(--body-text-size-md);
            }

            .price-value {
                font-weight: 700;
                color: var(--grey-800);
                font-size: var(--body-text-size-md);
            }

            .count {
                font-size: 3rem;
            }

            .dot {
                width: 4px;
                height: 4px;
                background-color: var(--yellow-800);
                border-radius: 50%;
                margin: 0 0.2rem;
            }
        }

        .action-button {
            text-decoration-line: underline;
            cursor: pointer;
            color: #5f9074;
            font-size: var(--body-text-size-sm);
        }
    }
    .divider {
        width: 1px;
        background-color: var(--grey-100);
        border-radius: 4px;
    }

    .center {
        display: flex;
        justify-content: center;
    }
}
