.checkbox {
    &-container {
        display: flex;
        align-items: center;
        justify-content: center;

        &.disabled {
            opacity: 0.3;
            pointer-events: none;
        }

        .checkbox {
            width: 0.625rem;
            height: 0.625rem;
            display: flex;
            border-radius: 2px;
            align-items: center;
            transition: all 150ms;
            justify-content: center;
            padding: 2px;
            border: 2px solid var(--blue-900);

            &.checked {
                background: var(--blue-900);
            }
        }
        .radio-checkbox {
            width: 0.75rem;
            height: 0.75rem;
            display: flex;
            border-radius: 50%;
            align-items: center;
            transition: all 150ms;
            justify-content: center;
            padding: 1px;
            border: 3px solid var(--grey-600);

            &.radio-checked {
                background-color: var(--blue-050);
                border: 3px solid var(--blue-900);
            }
            .radio-button-content {
                width: 0.75rem;
                height: 0.75rem;
                background-color: var(--blue-900);
                border-radius: 50%;
            }
        }

        .checkbox-hidden {
            border: 0;
            height: 20px;
            width: 20px;
            margin: -1px;
            overflow: hidden;
            padding: 0;
            position: absolute;
            white-space: nowrap;
            &:focus + & {
                box-shadow: 0 0 0 3px pink;
            }
        }
    }
}
