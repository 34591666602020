.hover-card {
    background-color: white;
    border-radius: 0.444rem;
    margin-bottom: 0.556rem;
    width: 377px;
    height: fit-content;
    border: 1px solid var(--grey-050);

    &>* {
        padding: 0.833rem;
    }

    &:hover {
        box-shadow: 0px 0px 16px rgba(53, 74, 99, 0.2);
    }

    &>.header {
        display: flex;
        justify-content: space-between;

        .title {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--blue-900);
        }

        .right-button {
            display: flex;
            align-items: center;
            margin-left: 0.5rem;
            cursor: pointer;
        }
    }

    &>.content {
        border-top: 1px solid var(--blue-050);
        padding: 0.833rem;
    }

    &>.content:first-child {
        border-top: none;
    }
}