.come-back-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 8.25rem;
    background-color: white;
    h1 {
        font-size: var(--body-text-size-3xl);
        font-weight: 700;
        color: var(--blue-200);
    }
    span {
        color: var(--grey-600);
        font-size: var(--body-text-size-md);
    }
}
