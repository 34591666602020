.order-pdf {
    display: flex;
    z-index: -1;
    padding: 150px 150px 0 150px;
    flex-direction: column;

    &-header {
        display: flex;
        flex-direction: column;

        .title {
            font-size: 36px;
            font-weight: bold;
            color: var(--blue-900);
        }
        .section {
            display: flex;
            font-size: 30px;
            &-label {
                color: var(--grey-600);
            }
            &-value {
                margin-left: 0.5rem;
            }
        }
    }

    .address-wrapper {
        display: flex;
        align-items: flex-start;
        .column {
            display: flex;
            flex-grow: 1;
            flex-direction: column;

            .title {
                font-size: 36px;
                font-weight: bold;
                color: var(--blue-900);
            }
            .content {
                font-size: 24px;
            }
        }
    }

    .separator {
        width: 100%;
        height: 1px;
        margin: 0.75rem 0;
        background-color: var(--yellow-800);
    }

    .orders {
        display: flex;
        flex-direction: column;
        &-list {
            padding: 0.75rem 0 0.75rem 1.25rem;
        }
        .title {
            font-weight: bold;
            font-size: 30px;
        }

        .item-container {
            line-height: normal;
            padding-bottom: 0.75rem;
        }

        .section {
            display: flex;
            align-items: center;
            justify-content: space-between;
            &-title {
                font-weight: bold;
                font-size: 30px;
                color: var(--blue-900);
            }
            .total-price {
                font-weight: bold;
                font-size: 30px;
                color: var(--blue-200);
            }
        }
        .gray-text {
            font-size: 30px;
            color: var(--grey-600);
        }
        .total-price-wrapper {
            display: flex;
            justify-content: flex-end;
            .total-price {
                padding: 0.75rem;
                font-weight: bold;
                font-size: 30px;
                border-radius: 12px;
                border: 1px solid var(--yellow-300);

                span {
                    font-weight: 400;
                    padding-right: 1.5rem;
                }
            }
        }
    }
    .footer {
        margin-top: 2rem;
    }
}
