.circle-button {
    width: 1.778rem;
    height: 1.778rem;
    border-radius: 50% !important;
    padding: 1rem !important;
    margin: 0 !important;
    line-height: 0 !important;
    min-width: unset !important;

    &-icon {
        color: var(--grey-600);
    }
    &:hover &-icon {
        color: var(--blue-900);
    }
}

.MuiButton-root:disabled {
    .circle-button-icon-disabled {
        svg path {
            fill: var(--grey-400);
        }
    }
}
