.button {
    background-color: var(--yellow-800);
    color: var(--blue-900);
    border-width: 0;
    cursor: pointer;
    border-radius: 8px;
    font-weight: 700;
    font-size: var(--body-text-size-md);

    &-sm {
        padding: 4px 8px;
    }

    &-md {
        padding: 11px 32px;
    }

    &-lg {
        padding: 21px 12px;
    }

    &.secondary {
        background-color: #ffff;
        border: 1px solid #fdc00e;
    }

    &.tertiary {
        background-color: #ffff;
        border: 1px solid #ffff;
    }

    &.quaternary {
        background-color: transparent;
        border: none;
    }

    &:disabled,
    &[disabled] {
        color: var(--white);
        background-color: var(--grey-300);
        opacity: 0.5;
    }
}

.button:hover {
    box-shadow: 3px 3px 16px rgba(53, 74, 99, 0.3);
}

.toggle-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    background-color: white;
}

.create-product-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.5rem;
    height: 2.5rem;
    padding: 0;
    background-color: var(--yellow-800);
}
