.additional-form-container {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    .additional-input {
        display: flex;
        flex-wrap: wrap;
        row-gap: 0.5rem;

        .input-button-wrapper {
            width: 40%;
            display: flex;
            column-gap: 0.5rem;
        }
    }
}
