.overview-container {
    display: flex;
    flex-direction: column;
    row-gap: 1.25rem;
    width: 100%;

    &-top {
        display: flex;
        column-gap: 1.25rem;

        .left {
            display: flex;
            width: calc(67.7% - 0.625rem);
        }

        .right {
            display: flex;
            width: max-content;
            width: calc(33.3% - 0.625rem);
        }
    }

    &-bottom {
        display: flex;
        justify-content: space-between;
        column-gap: 1.25rem;
    }
}
