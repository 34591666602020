.link-equipment-modal {
    .link-equipment-table {
        margin-top: 1rem;
        .MuiTableContainer-root {
            box-shadow: none;
        }
        .MuiTableCell-head {
            color: white;
            border-right: 2px solid var(--blue-900) !important;
        }
    }
}
