.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
}

.modal {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-color: var(--white);
    height: fit-content;
    padding: 0.889rem;
    border-radius: 0.444rem;
    min-width: 27.778rem;
    max-height: calc(100vh - 8.333rem);
    overflow: auto;
    z-index: 1005;

    @media screen and (max-width: 27.778rem) {
        min-width: calc(100% - 46px);
    }

    & > .modal-header {
        display: flex;
        justify-content: space-around;

        & > .modal-title {
            flex: 2;
            display: flex;
            flex-direction: column;

            & > .modal-subtitle {
                font-size: var(--body-text-size-sm);
            }
        }

        & > .modal-header-button {
            flex: 1;
            display: flex;
            justify-content: flex-end;
        }

        & > .modal-header-close-button svg {
            color: var(--grey-600);
        }

        & > .modal-header-close-button svg:hover {
            color: var(--grey-800);
        }
    }

    .modal-body {
        display: flex;
        flex-direction: column;
        margin-top: 0.889rem;
    }

    .modal-footer {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        column-gap: 0.5rem;

        .button {
            width: 50%;
        }
        .create-product {
            display: flex;
            flex-direction: column;
            gap: 0.625rem;
            .first-row {
                display: flex;
                gap: 0.75rem;
            }
            .cancel-button {
                text-align: center;
                font-weight: 700;
                font-size: 1rem;
                color: var(--blue-900);
                cursor: pointer;
            }
        }
    }
    .row {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        .button {
            width: 100%;
        }
    }
}
