.empty-data-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    .label {
        color: #767676;
        font-size: var(--body-text-size-md);
    }
    .navigate-buttons {
        display: flex;
        column-gap: 0.75rem;
    }
}
