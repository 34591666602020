div.MuiTableContainer-root.customer-product-table {
    table {
        table-layout: fixed;
        span.ellipsis {
            width: 100%;
            height: auto;
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}
