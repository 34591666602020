.warranty-title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  &>.dot {
    color: var(--grey-600);
    margin-right: 10px;
  }

  &>.warranty-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &>* {
      line-height: 1;
    }

    & .checking-status-container {
      display: flex;
      align-items: center;

      &>.checking-info {
        color: var(--yellow-200);
        margin-right: 0.5rem;
      }

      &>.loading-span {
        width: var(--body-text-size-sm);
        height: var(--body-text-size-sm);
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border: 0.278rem solid var(--yellow-500);
        border-bottom-color: transparent;
        position: relative;
        animation: spinner 1s linear infinite paused;
        -webkit-animation: spinner 1s linear infinite;
      }

      @keyframes spinner {
        to {
          transform: rotate(1turn);
          -webkit-transform: rotate(1turn);
          -moz-transform: rotate(1turn);
          -ms-transform: rotate(1turn);
          -o-transform: rotate(1turn);
        }
      }
    }

    &>.title {
      font-weight: bold;

      &.checking-warranty-status {
        color: var(--blue-900);
      }

      &.no-warranty-info {
        color: var(--grey-600);
      }

      &.expired {
        color: var(--red-100);
      }

      &.not-expired {
        color: var(--green-100);
      }
    }

    &>.date {
      color: var(--grey-800);
    }
  }
}