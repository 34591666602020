div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.recurring-icon {
    float: right;
}
.due-date-recurring-icon {
    width: 0.75rem;
}
.expand-item-container {
    display: grid;
    grid-template-columns: repeat(4, 25%);
    width: 96%;
    gap: 1rem;
    .cart-container {
        padding: 1rem;
        display: flex;
        width: 100%;
        flex-direction: column;

        .cart-container-title {
            display: flex;
            align-items: center;
            color: var(--blue-900);
            column-gap: 0.5rem;
            font-size: var(--body-text-size-sm);
            font-weight: bold;

            svg {
                width: 1.5rem;
                height: 1.5rem;

                path {
                    fill: var(--blue-900);
                }
            }
        }

        .cart-container-body {
            display: flex;
            flex-direction: column;
            padding-left: 1rem;

            .cart-item {
                display: flex;
                border-bottom: 2px solid var(--blue-100) !important;
                padding-bottom: 0.625rem !important;
                padding-top: 0.625rem !important;
                // &:last-child {
                //     //border-bottom: none !important;
                // }
                a {
                    font-size: var(--body-text-size-md);
                    color: #5f9074;
                    text-decoration: underline;
                    width: 100%;
                    display: flex;
                }
            }

            .product {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                padding: 0.25rem !important;
                b {
                    margin-right: 10px;
                }
            }

            .equipment {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                .serialNo {
                    font-size: 10px;
                }
            }
        }
    }
}
.activity-status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-radius: 18px;
    width: fit-content;
    border: solid 0.5px;

    svg {
        margin-left: 5px;
    }
}

.Progress {
    color: var(--green-100);
    background: #eefbf3;
}

.Hold {
    color: var(--orange-100);
    background: #fff5eb;
}

.Open {
    color: var(--blue-900);
    background: #e0eeff;
}

.Closed {
    color: var(--grey-600);
    background: #f5f6f7;
}

.red {
    color: var(--red-600);
}

.activity-table-switch {
    .MuiSwitch-track {
        transform: scale(1.3);
        background-color: #e7e7e7 !important;
    }

    .MuiSwitch-thumb {
        transform: scale(0.7) translateX(-20%);
        background-color: #767676 !important;
    }

    .Mui-checked {
        .MuiSwitch-thumb {
            background-color: #0e1f33 !important;
            transform: scale(0.7) translateX(20%);
        }
    }

    .css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
        background-color: #a2adbb !important;
    }
}

.one-line {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 500px;
}

.highlightedRow {
    box-shadow: 0 0 0 5px #a2adbb inset;

    .MuiTableCell-root {
        border: none !important;
        background-color: transparent !important;

        .chevronRightContainer {
            background-color: #a2adbb;
        }
    }
}

.chevronRightContainer {
    background-color: transparent;
    height: 50px;
    width: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    right: 0;
    &:hover {
        background: var(--blue-050);
    }
}

.assignee-container {
    display: flex;
    column-gap: 0.5rem;
    .badge {
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 33px;
        height: 33px;
        font-size: 0.75rem;
    }
    .firstName {
        border-radius: 25%;
        display: flex;
        align-items: center;
    }
}
