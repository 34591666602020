.delete-mapping-confirmation-modal {
    .modal-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding-top: 1.5rem;
        margin-top: 0;
        & .button {
            width: 49%;
        }
        & .tertiary {
            width: 100%;
        }
    }
    hr {
        background: var(--blue-10);
        height: 4px;
        width: 100%;
        border-radius: 0.25rem;
        border: none;
        margin-top: 1.5rem;
        margin-bottom: 0;
    }
}
