.activityContent {
    display: flex;
    justify-content: space-between;

    .activity-table {
        width: 100%;
    }

    .onBox {
        width: 68%;
    }

    .partial-update-box {
        height: calc(100vh - 280px);
        display: flex;
        width: 30%;
        position: sticky;
        position: -webkit-sticky;
        top: 0;
        right: 1rem;
    }
}

.historicalLogSwitch {
    .Mui-checked .MuiSwitch-thumb,
    .Mui-checked + .css-1yjjitx-MuiSwitch-track {
        background-color: gold !important;
    }
}

.add-task {
    height: 2.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    svg {
        margin-right: 10px;
    }
}
