.save-confirmation-modal {
    width: 28rem;
    .modal-footer {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        border-top: 2px solid var(--blue-10);
        padding-top: 1.5rem;
        & .button {
            width: 49%;
        }
        & .tertiary {
            width: 100%;
        }
    }
}
