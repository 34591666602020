.validation-card {
    position: absolute;
    background-image: url('../../img/login-bg.png');
    background-size: cover;
    height: 100%;
    width: 100%;

    .card-wrapper{
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .box {
            width: 50%;
            display: flex;
            color: var(--white);
            align-items: center;
            flex-direction: column;
            justify-content: center;
            backdrop-filter: blur(32px);
            border: 2px solid var(--grey-600);
            background: rgba(255, 255, 255, 0.15);
        }

        .card-button{
            margin-top: 10px;
        }
    }

    .message {
        font-weight: bold;
        &.success {
            color: var(--green-500);
        }
        &.error {
            color: var(--red-500);
        }
    }

    .loading-span {
        width: 1.111rem;
        height: 1.111rem;
        border-radius: 50%;
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        -ms-border-radius: 50%;
        -o-border-radius: 50%;
        border: 0.278rem solid var(--yellow-500);
        border-bottom-color: transparent;
        position: relative;
        animation: spinner 1s linear infinite paused;
        -webkit-animation: spinner 1s linear infinite;
    }

    @keyframes spinner {
        to {
            transform: rotate(1turn);
            -webkit-transform: rotate(1turn);
            -moz-transform: rotate(1turn);
            -ms-transform: rotate(1turn);
            -o-transform: rotate(1turn);
        }
    }
}
