.share-modal-assigned-user-container {
  .share-modal-assigned-user-item {
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .share-modal-button {
      background-color: transparent;

      .share-modal-button-text {
        font-size: var(--body-text-size-sm);
        color: var(--blue-900);
        display: none;
      }

      .share-modal-cross-icon,
      .share-modal-undo-icon {
        color: rgba(0, 0, 0, 0.54);
        font-size: 0.875rem;
      }

      .share-modal-cross-icon {
        transform: rotate(45deg);
      }

      &:hover {
        background-color: var(--blue-100);
        border-radius: 30px;

        .share-modal-cross-icon,
        .share-modal-undo-icon {
          color: var(--blue-900);
          margin-right: 15px;
        }

        .share-modal-button-text {
          display: inline-block;
        }
      }
    }
  }

  .share-modal-name {
    color: var(--blue-900);
  }

  .removed-item {
    text-decoration: line-through red;
  }

  .share-modal-email {
    font-size: var(--body-text-size-sm);
    color: var(--grey-600);
  }

  .temporary {
    color: var(--yellow-200);
  }
}
