.no-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;

    &__title {
        font-size: 1rem;
        line-height: 1.375rem;
        color: var(--grey-800);
    }

    &__button {
        display: flex;
        gap: 0.75rem;
        align-items: center;
    }

    &__dropdown {
        display: flex;
        flex-direction: column;
        width: 10.5rem;
        padding: 0.5rem 0;
        background-color: var(--white);
        border-radius: 0.5rem;
        box-shadow: 0 0 12px rgba(53, 74, 99, 0.5);
        &__wrapper {
            display: flex;
            width: 100%;
            height: 2.375rem;
            &__item {
                width: 100%;
                padding: 0.5rem 0.93rem 0.5rem 1rem;
                cursor: pointer;
            }
            &:hover {
                background-color: var(--blue-10);
            }
            &:hover &__item {
                font-weight: 700;
            }
        }
    }
}
