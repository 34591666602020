.drawing-modal-uploaded-image {
    position: relative;
    width: 5rem;
    height: 5rem;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0;
    border-radius: 0.5rem;
    border: 1px solid #e7e7e7;
    margin-right: 1.5rem;
    margin-top: 1.5rem;

    .delete-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid #e7e7e7;
        background-color: white;
        transform: translate(70%, -70%);
        cursor: pointer;

        &:hover {
            background-color: var(--grey-300);
        }

        span {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        svg {
            width: 0.583rem;
        }
    }
}
