.settings-page {
    & .content-box {
        &>.content-body {
            &>.addresses-content-container {
                display: flex;
                justify-content: flex-start;
                flex-wrap: wrap;
                gap: 0 0.5rem;

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    &>* {
                        width: 100%;
                        height: auto;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1440px) {
                    &>* {
                        width: calc(50% - 0.4rem);
                        height: auto;
                    }
                }

                @media only screen and (min-width: 1441px) {
                    &>* {
                        width: calc(33.33% - 0.5rem);
                        height: auto;
                    }
                }

                & .addresses-tab-container {
                    width: 100%;

                    &>.section {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 1rem 0.5rem;
                        border-bottom: 1px solid var(--grey-050);

                        &>.section-icon {
                            color: var(--blue-900);
                            margin-right: 0.667rem;
                            width: 1.25rem;
                            height: 1.25rem;
                        }

                        &>.section-container {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-direction: column;
                            width: 100%;

                            &>.title {
                                color: var(--blue-900);
                                font-size: var(--body-text-size-sm);
                            }

                            &>.content {
                                width: 100%;
                                padding: 0 0.417rem;
                                color: var(--grey-800);

                                .content-line {
                                    display: flex;
                                    margin: 7px 0 12px 0;

                                    &-item {
                                        width: 100%;
                                        display: flex;
                                        flex-direction: column;
                                        text-align: left;

                                        .title {
                                            color: var(--blue-060);
                                            font-size: var(--body-text-size-sm);
                                        }

                                        .value {
                                            color: var(--grey-800);
                                            font-size: var(--body-text-size-lg);
                                        }

                                    }
                                }

                                &>.content-row {
                                    display: flex;
                                    gap: 0.5rem;

                                    & .title {
                                        padding-right: 10px;
                                        color: var(--blue-900);
                                    }

                                    & .content {
                                        color: var(--grey-800);
                                    }

                                    & .address-button {
                                        min-height: 40px;
                                    }
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}