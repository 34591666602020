.partial-parts-table {
    .table-body {
        overflow: auto;
        max-height: 1rem;
    }

    .name-info-container {
        .name {
            max-width: 12rem;
        }
    }
}
