.search-dropdown {
  position: relative;
  display: block;
  margin-right: 15px;

  .search-dropdown-transparent-container {
    margin-top: 0.35rem;
    background-color: transparent;

    .search-dropdown-content {
      border-radius: 0.667rem 0.667rem 0 0;
      position: absolute;
      background-color: white;
      min-width: 100%;
      box-shadow: 0 0.444rem 0.889rem 0 rgba(0, 0, 0, 0.2);
      z-index: 1;
      max-height: 290px;

      a {
        color: black;
        padding: 0.667rem;
        text-decoration: none;
        display: block;
        border-bottom: 0.056rem solid var(--yellow-500);
      }

      a:first-child:hover {
        border-radius: 0.667rem 0.667rem 0 0;
      }

      a:last-child {
        border-bottom: none;
      }

      a:hover {
        background-color: #f1f1f1;
      }
    }
  }

  .search-dropdown-input-container {
    position: relative;
  }
}
