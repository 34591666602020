.section {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    border-bottom: 2px solid var(--blue-10);
    &__title {
        color: var(--grey-800);
        font-size: var(--body-text-size-xl);
    }
}
