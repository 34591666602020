.settings-page {
    & .content-box {
        & > .content-body {
            & > .content-container {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                @media only screen and (min-width: 1400px) {
                    & > :first-child {
                        width: 40%;
                    }

                    // & > :last-child {
                    //     width: 60%;
                    // }
                }

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    & > * {
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1400px) {
                    & > :first-child {
                        width: 50%;
                    }

                    // & > :last-child {
                    //     width: 55%;
                    // }
                }

                & .account-info-tab-container {
                    width: 100%;

                    & > .section {
                        display: flex;
                        justify-content: flex-start;
                        align-items: flex-start;
                        padding: 1rem 0.5rem;
                        border-bottom: 1px solid var(--grey-050);

                        & > .section-icon {
                            color: var(--blue-900);
                            margin-right: 0.667rem;
                            width: 1.25rem;
                            height: 1.25rem;
                        }

                        & > .section-container {
                            display: flex;
                            justify-content: flex-start;
                            align-items: flex-start;
                            flex-direction: column;
                            width: 100%;

                            & > .title {
                                color: var(--blue-900);
                                font-size: var(--body-text-size-sm);
                            }

                            & > .content {
                                width: 100%;
                                padding: 0 0.417rem;
                                color: var(--grey-800);

                                & > .content-row {
                                    display: flex;
                                    flex-direction: column;

                                    & .title {
                                        padding-right: 10px;
                                        color: var(--blue-900);
                                    }

                                    & .content {
                                        color: var(--grey-800);
                                    }
                                }
                            }
                        }

                        &:last-child {
                            border-bottom: none;
                        }
                    }
                }
            }
        }
    }
}
