.monittor-table-container {
    background-color: var(--blue-100);
    padding-bottom: 80px;
    margin-top: 1rem;

    .MuiTable-root {
        padding: 0 10px;
        border-collapse: separate;
        border-spacing: 0 2px;
        min-width: 100%;

        .MuiTableHead-root {
            background-color: var(--blue-900);
            border-radius: 8px;
            margin: 4px;

            * {
                color: var(--white) !important;
            }

            tr {
                height: 3.4785rem !important;

                th {
                    padding: 0 0 0 16px;

                    &:first-child {
                        border-radius: 8px 0 0 8px;
                    }

                    &:last-child {
                        border-radius: 0 8px 8px 0;
                        border-right: none;
                    }
                }

                .sorting-menu {
                    display: inline-flex;
                    align-items: center;
                    cursor: pointer;

                    .sorting-button {
                        .up,
                        .down {
                            &:hover {
                                cursor: pointer;
                                fill: azure;
                            }
                        }
                    }

                    .asc {
                        .up {
                            fill: azure;
                        }
                    }

                    .dsc {
                        .down {
                            fill: azure;
                        }
                    }
                }
            }
        }
    }
    .MuiTableCell-root {
        z-index: 1 !important;
    }

    tbody {
        &:before {
            line-height: 0.5rem;
            content: '\200C';
            display: block;
        }

        .MuiTableRow-root {
            background-color: var(--white);
            border-bottom: 2px solid var(--blue-100);

            &:first-child {
                border-radius: 8px 0 0 8px;

                td {
                    &:last-child {
                        border-right: none;
                        border-radius: 0 8px 0 0;
                    }

                    &:first-child {
                        border-radius: 8px 0 0 0;
                    }
                }
            }

            &:last-child {
                border-radius: 0 8px 8px 0;
                border-right: none;

                td {
                    &:last-child {
                        border-right: none;
                        border-radius: 0 0 8px 0;
                    }

                    &:first-child {
                        border-radius: 0 0 0 8px;
                    }
                }
            }

            .table-hover {
                left: 1px;
                top: 2.7rem;
                display: none;
                position: absolute;
                background-color: var(--grey-050);
                text-align: center;
                padding: 12px;
                height: 1rem;
                width: fit-content;
                border-radius: 8px !important;
                font-size: 0.653rem;
                color: var(--blue-200);
                margin-top: 0.4rem;
                max-width: 80%;
                font-weight: normal;
                z-index: 1;
            }

            &:hover {
                .table-hover {
                    display: block;
                }
            }

            .tag-th {
                padding-right: 1rem;
            }

            th,
            td {
                background-color: var(--white);
                padding: 0 0 0 16px;

                &:last-child {
                    border-right: none;
                }

                &:hover {
                    background-color: var(--white);
                }

                .tag-item {
                    position: relative;
                    background-color: #cbe2ec;
                    border-radius: 13px;
                    padding: 4px 8px;
                    font-size: 14px;
                    //&:hover {
                    //    background-color: var(--blue-050);
                    //}
                }

                .more {
                    //overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;

                    .more-hover {
                        flex-direction: column;
                        z-index: 1;
                        position: absolute;
                        background-color: white;
                        border-radius: 8px;
                        display: none !important;
                        box-shadow: 0 0 16px 0 #354a6333;
                        padding: 0.4rem;
                        //width: 3.5rem;
                        max-width: 6.7rem;
                        word-break: break-all;
                        right: 0;

                        span {
                            font-size: 0.653rem;
                            color: var(--blue-900);

                            &:hover {
                                //background-color: var(--blue-050);
                                font-weight: 900;
                            }
                        }
                    }

                    &:hover {
                        //background-color: var(--blue-050);
                        .more-hover {
                            display: flex !important;
                        }
                    }
                }
            }
        }

        .MuiTableRow-hover {
            th,
            td {
                height: 2.25rem;
                background-color: var(--white);

                &.font-bold {
                    font-weight: 700;
                }
            }
        }

        tr {
            &:hover {
                box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);

                .button-cell {
                    cursor: pointer;
                    background-color: var(--yellow-800);
                }
            }
        }
    }

    th,
    td {
        border-width: 0;
    }

    img {
        height: 100px;
        width: 150px;
    }
}

.MuiTableHead-root .MuiTableRow-root th {
    background-color: var(--blue-900);
}
