.dropdown-input {
    position: relative;
    display: flex;
    gap: 0.5rem;
    align-items: center;

    .end-icon {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: absolute;
        top: 1.2rem;
        right: 1rem;
    }

    & .input {
        display: flex;
        padding: 0.8rem 0.667rem 0.4rem 0.667rem !important;
    }
}
