.chip-button {
    gap: 0.5rem;
    height: 1.42rem;
    width: fit-content;
    padding: 0 0.4rem;
    border-radius: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--red-050);
    color: var(--red-100);
    cursor: pointer;
    font-size: 0.8rem;
    font-weight: normal;

    &:hover {
        background-color: var(--blue-050);
        font-size: 0.8rem;
        color: var(--blue-200);
    }
}

.chip-button.green {
    background-color: var(--green-050);
    color: var(--green-100);

    &:hover {
        background-color: var(--blue-050);
        font-size: 0.8rem;
        color: var(--blue-200);
    }
}

.chip-button.default {
    background-color: var(--grey-050);
    color: var(--grey-100);

    &:hover {
        background-color: var(--blue-050);
        font-size: 0.8rem;
        color: var(--blue-200);
    }
}
