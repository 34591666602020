.input-wrapper {
    position: relative;

    .input {
        width: 100%;
        color: var(--grey-800);
        border: 0.056rem solid var(--grey-100);
        box-sizing: border-box;
        border-radius: 0.444rem;
        font-size: var(--body-text-size-md);
        background-color: white;

        &-sm {
            padding: 0.444rem;

            &:focus ~ .label,
            &:not(:placeholder-shown) ~ .label {
                top: -0.889rem;
            }

            + .label {
                left: 0.444rem;
                top: 0.333rem;
            }
        }

        &-md {
            padding: 0.667rem;

            &:focus ~ .label,
            &:not(:placeholder-shown) ~ .label {
                top: -0.167rem;
            }

            + .label {
                left: 0.667rem;
                top: 0.722rem;
            }
        }

        &-lg {
            height: 2.8rem;
            padding: 1.3rem 0.667rem 0.4rem 0.667rem !important;

            + .label {
                left: 0.667rem;
            }
        }

        &:disabled,
        &[disabled] {
            opacity: 0.5;
        }
    }

    .textarea {
        width: 100%;
        color: var(--grey-800);
        background-color: white;
        border: 0.056rem solid var(--grey-100);
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 0.444rem;
        font-size: var(--body-text-size-md);

        &-sm {
            padding: 0.444rem;

            &:focus-visible ~ .label,
            &:not(:placeholder-shown) ~ .label {
                top: -0.889rem;
            }

            + .label {
                left: 0.444rem;
                top: 0.333rem;
            }
        }

        &-md {
            padding: 0.667rem;

            &:focus-visible ~ .label,
            &:not(:placeholder-shown) ~ .label {
                top: -0.167rem;
            }

            + .label {
                left: 0.667rem;
                top: 0.722rem;
            }
        }

        &-lg {
            padding: 1.3rem 0.667rem 0.4rem 0.667rem !important;

            + .label {
                left: 0.667rem;
            }
        }

        &:disabled,
        &[disabled] {
            opacity: 0.5;
        }
    }

    input:focus ~ .label,
    input:not(:placeholder-shown) ~ .label {
        top: 0.3rem;
    }

    textarea:focus-visible ~ .label,
    textarea:not(:placeholder-shown) ~ .label {
        top: 0.278rem;
    }

    .label {
        top: 25%;
        left: 1.111rem;
        color: var(--grey-600);
        font-size: 0.667rem;
        position: absolute;
        pointer-events: none;
        transition: 0.2s ease all;
        line-height: 1.75;
        font-weight: 400;
    }

    &.start-input-icon {
        .start-icon {
            margin: auto;
            position: absolute;
            font-size: 0.611rem;
            left: 0.778rem;
            top: calc(50%);
            transform: translateY(-50%);

            svg {
                font-weight: 400;
            }
        }

        .input {
            &-lg {
                padding-left: 2.222rem !important;

                ~ .label {
                    left: 2.222rem !important;
                }
            }
        }
    }

    .end-item {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &.end-input-icon {
        .end-icon {
            margin: auto;
            position: absolute;
            font-size: 0.611rem;
            right: 0.778rem;
            top: calc(45%);
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;

            & > svg.icon {
                width: 14px;
                height: 14px;
            }
        }

        .end-icon-button {
            border-radius: 100%;
            padding: 10px 10px;
            right: 0.3rem;
        }

        .end-icon-button:hover {
            background-color: var(--grey-300);
            border-radius: 50%;
            cursor: pointer;
        }

        .input {
            &-lg {
                padding-right: 2.222rem !important;

                ~ .label {
                    right: 2.222rem !important;
                }
            }
        }
    }
}

.required {
    color: var(--red-500);
}

.disabled-input {
    background-color: var(--grey-100) !important;
}
