.breadcrumb {
    padding-left: 6.3125rem;
    display: flex;
    align-items: center;
    background-color: white;
    height: 26px;
    &-item {
        font-size: var(--body-text-size-sm);
        padding: 0 0.3rem;
        color: var(--grey-800);
        &.active {
            color: var(--blue-900);
            font-weight: 700;
        }

        &:hover {
            font-weight: 600;
        }
    }

    &-separator {
        svg {
            margin: 0 1rem;
            color: var(--grey-600);
        }
    }
}
