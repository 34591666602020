#mouse-over-popover {
    margin-top: 5px;
    pointer-events: none;

    & .popover-content {
        overflow: hidden;
        color: var(--grey-800);
        font-size: var(--body-text-size-md);
        border-radius: 8px;
        background-color: white;
        box-shadow: 2px 2px 4px rgba(53, 74, 99, 0.2);

        & > * {
            padding: 12px;
        }
    }
}
.popover-item-selection{
    display: flex;
    align-items: center;
    cursor: pointer;
}