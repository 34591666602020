.activity-customer-container {
    position: relative;
    width: 100%;

    .MuiAutocomplete-root {
        width: 100%;

        label {
            font-size: var(--body-text-size-sm) !important;
        }
    }

    .customer-logo {
        position: absolute;
        left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
        z-index: 2;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 0;
        pointer-events: none;
    }

    &.customer-with-logo {
        .MuiAutocomplete-root {
            input[type='text'] {
                padding-left: 40px;
            }
        }
    }

    .MuiIconButton-sizeMedium:last-child {
        margin-top: 25%;
        margin-right: 0.2rem;

        svg {
            color: var(---grey-500);
        }
    }

    .disabled-list-menu {
        .MuiIconButton-sizeMedium:last-child {
            display: none;
        }
    }

    svg {
        color: #e58365;
    }

    .dropdown-list-menu {
        margin-top: 20px !important;
        filter: drop-shadow(0px 0px 12px rgba(53, 74, 99, 0.8));
    }

    .activity-customer-dropdown {
        .MuiAutocomplete-clearIndicator {
            &:hover {
                background-color: transparent !important;
            }
        }

        .MuiAutocomplete-endAdornment {
            top: 0 !important;
            right: 0 !important;
        }

        .clear-button {
            width: 2rem;
            height: 2.3rem;
            display: flex;
            align-items: center;
            justify-content: center;
            border-left: 2px solid #f5f6f7;
        }
    }
}

.MuiAutocomplete-noOptions {
    display: none;
}
