div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.contracts-table-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
}

.action-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-width: 2.5rem;
    &-hover {
        background-color: var(--blue-050);
    }
    &:hover {
        background-color: var(--blue-050);
    }
}

.status-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-radius: 18px;
    width: fit-content;
    border: solid 0.5px;
    width: 75px;
    height: 30px;
    font-size: var(--body-text-size-sm);
}
.badge-offer {
    border-color: #4280cb;
    color: white;
    background: #4280cb;
}
.badge-active {
    color: white;
    border-color: #459b68;
    background: #459b68;
}
.badge-expired {
    color: white !important;
    border-color: #d84315 !important;
    background: #d84315 !important;
    width: 85px !important;
}
