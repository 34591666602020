.content-box {
    margin: 0 20px;

    .content-header {
        padding: 1.25rem;
        padding-bottom: 0;
        border-radius: 8px 8px 0 0;
        display: flex;
        justify-content: space-between;

        h3 {
            font-weight: 700;
            margin: 0;
        }

        .description {
            margin-left: 10px;
            color: var(--grey-600);
        }

        .add-button {
            border-radius: 100%;
            padding: 0.5rem 0.6rem;
            color: var(--grey-600);

            &:hover {
                color: var(--blue-200);
            }
        }

        @media screen and (max-width: 768px) {
            flex-direction: column;

            .content-box-button {
                margin-top: 0.5rem;
            }
        }

        .content-header-child {
            display: flex;
            align-items: center;
            column-gap: 0.75rem;

            .content-header-right {
                display: flex;
                flex-direction: column;
                margin-bottom: 0.222rem;

                &-top {
                    display: flex;
                    justify-content: left;
                    align-items: center;

                    h1 {
                        font-size: var(--body-text-size-3xl);
                        font-weight: 400;
                    }

                    & > svg {
                        width: 2.333rem;
                        height: 2.056rem;
                        color: var(--yellow-300);
                        padding-right: 0.417rem;
                    }
                }

                &-bottom {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-weight: 700;
                    margin-top: 0.5rem;

                    h5 {
                        font-size: var(--body-text-size-md);
                        color: var(--blue-900);
                    }
                }
            }
        }
    }

    .content-body {
        border-radius: 0 0 8px 8px;
        padding: 16px;
        margin-top: 4px;
    }

    &-sm {
        h3 {
            font-weight: 400 !important;
            font-size: 32px !important;
        }
    }

    @media screen and (max-width: 768px) {
        margin: 10px 0 0 0;
        padding: 0;
    }
}
