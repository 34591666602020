.new-tree-view-container {
    position: relative;

    & > .tree-view-button {
        background-color: white;
        width: 42px;
        height: 42px;
        margin-right: 10.5px;
        margin-bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
    }

    & > .hierarchial-tree-view {
        position: absolute;
        top: 64px;
        left: 0;
        z-index: 5;
        background-color: white;
        display: block;
        width: 25rem;
        max-height: 15.278rem;
        box-shadow: 0.056rem 0.056rem 0.444rem rgba(53, 74, 99, 0.3);
        border-radius: 0.444rem;
        padding: 1.111rem 0;
        overflow-y: auto;

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
            box-shadow: inset 0 0 0.333rem rgba(0, 0, 0, 0.3);
            background-color: #f5f5f5;
            border-radius: 0.444rem;
        }

        &::-webkit-scrollbar {
            width: 0.4rem !important;
            background-color: #f5f5f5;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #aaa;
            border-radius: 0.4rem;
        }

        & .node-container {
            padding: 0 1.111rem;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            & .node-left {
                display: inline-flex;
                align-items: center;
                width: 100%;

                & .node-icon {
                    display: flex;
                    margin-right: 0.556rem;
                    min-width: 0.833rem;
                    cursor: pointer;

                    & .icon {
                        align-items: center;
                        cursor: pointer;
                    }
                }

                & .node-text {
                    width: 100%;
                    color: var(--grey-800);
                    cursor: pointer;
                }
            }

            & .node-right {
                display: inline-flex;
                width: fit-content;
                visibility: hidden;

                & .node-buttons {
                    width: 100%;
                    display: inline-flex;
                    justify-content: right;

                    svg {
                        color: var(--grey-600);

                        path {
                            fill: var(--grey-600);
                        }
                    }
                }

                & .node-buttons > * {
                    margin-left: 0.278rem;
                }
            }

            &:hover {
                background-color: var(--blue-100);
            }

            &:hover .node-text,
            &:hover .node-icon {
                color: var(--blue-900);
            }

            &:hover .node-right {
                color: var(--blue-900);
                visibility: visible;
            }
        }

        & .child-nodes {
            display: none;
        }

        & .child-nodes-visible {
            display: block;
        }

        & .left-border {
            position: absolute;
            height: 2.028rem;
            width: 1.389rem;
            top: 0;
            border-left: 0.111rem solid var(--light-500);
            z-index: 1;
        }

        & .bottom-border {
            position: absolute;
            height: 0.85rem;
            width: 1.15rem;
            left: 1.444rem;
            top: 0;
            border-bottom: 0.111rem solid var(--light-500);
            z-index: 1;
        }

        & .half-height-bottom-border {
            height: 0.88rem !important;
        }

        & .child-nodes > .node-container {
            &:last-child > div {
                &:first-child {
                    height: 0.88rem !important;
                }
            }
        }
    }
}
