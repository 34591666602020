.table-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    div {
        box-shadow: none;
    }

    table {
        border: 1px solid var(--yellow-300);
        border-radius: 8px;
        border-collapse: separate;

        thead {
            th {
                border-bottom: 1px solid var(--yellow-300);
                font-size: var(--body-text-size-md);
                color: var(--blue-900);
                font-weight: 700;
            }
        }

        tbody {
            tr, td {
                border-bottom: 1px solid var(--yellow-500);
                font-size: var(--body-text-size-md);
            }
        }
    }
}

.m-list-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;

    .m-list-item {
        border-bottom: 1px solid var(--yellow-500);
        padding: 5px;
    }

    .m-list-obj-wrapper {
        display: flex;
        width: 100%;
        gap: 16px;
    }

    .m-list-text {
        font-size: 16px;
        &.large {
            font-size: 18px;
        }

        &.small {
            font-size: 14px;
        }

        &.blue {
            color: var(--blue-900)
        }
        &.bold {
            font-weight: 700;
        }
    }
    &.desktop-view{
        padding: 0;
        width: 100%;
        border-radius: 8px;
        border: 1px solid var(--yellow-800);

        .m-list-item{
            padding: 16px;
        }
    }

}
.list-no-data {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: var(--grey-600);
}
