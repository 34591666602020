div.MuiTableContainer-root.monittor-table-container > table > tbody > tr > td.MuiTableCell-root > span.ellipsis-text {
    width: calc(100% - 16px);
    height: auto;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .border {
        border: 5px solid red;
    }
}

div.MuiTableContainer-root.monittor-table-container > table > tbody {
    tr {
        border-bottom: 2px solid var(--blue-100);
    }
    .active-task {
        cursor: pointer;
        border: 2.5px solid var(--blue-050);
        background: red !important;
    }

    .table-title {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box !important;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
}

.action-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    .chevron-right {
        color: #767676;
        padding: 0.5rem;
        border-radius: 0;
        background-color: white;
        display: flex;
        align-items: center;
        height: 100%;
        justify-items: center;
    }
}

.activity-status {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem 0.5rem;
    border-radius: 18px;
    width: fit-content;
    border: solid 0.5px;
}

.Progress {
    color: var(--green-100);
    background: #eefbf3;
}

.Hold {
    color: var(--orange-100);
    background: #fff5eb;
}

.Open {
    color: var(--blue-900);
    background: #e0eeff;
}

.Closed {
    color: var(--grey-600);
    background: #f5f6f7;
}

.red {
    color: var(--red-600);
}
