.document-detail-page {
    & .yellow-wrapper {
        border-radius: 8px;
        padding: 10px;
        align-self: stretch;
        background-color: white;
        display: flex;
        margin-bottom: 16px;
        justify-content: center;
        align-items: center;
        min-height: 930px;
        position: relative;
        div[data-rmiz-wrap="visible"] {
            width: 100%;
        }
    }
    
    & .product-image {
        max-width: 100%;
        width: calc(100vw);
    }
}
