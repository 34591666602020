.customer-equipment-detail-page {
    .customer-equipment-list {
        display: flex;
    }

    .filter-wrapper {
        display: flex;
        align-items: center;
        column-gap: 1rem;

        input {
            background-color: white !important;
            height: 2.2rem !important;
        }

        & .equipment-detail-filter-menu {
            width: 12rem;

            & .MuiFormControl-root {
                height: 2.2rem !important;

                & .MuiInputLabel-root {
                    line-height: 0.7rem;
                }

                & .MuiInputBase-root {
                    height: 2.2rem !important;
                }
            }
        }
    }

    .tab-container {
        width: 100%;
        display: inline-flex;

        .tree-view-container {
            position: relative;

            .tree-view-button {
                background-color: var(--yellow-800);
                width: 55px;
                height: 50px;
                margin-right: 10.5px;
                margin-bottom: 0;
                padding: 0;
            }

            .tree-view {
                position: absolute;
                top: 64px;
                left: 0;
                z-index: 5;
            }
        }
    }

    & .content-box {
        & > .content-body {
            & > .content-container {
                display: flex;
                justify-content: space-between;
                gap: 1rem;

                @media only screen and (min-width: 1200px) {
                    & > :first-child {
                        width: 40%;
                    }
                }

                @media only screen and (max-width: 800px) {
                    flex-direction: column;

                    & > * {
                        width: 100%;
                    }
                }

                @media only screen and (min-width: 801px) and (max-width: 1200px) {
                    & > :first-child {
                        width: 55%;
                    }
                }
            }

            & .yellow-wrapper {
                display: block;
                border-radius: 8px;
                padding: 10px;
                align-self: stretch;
                background-color: white;
                margin-bottom: 16px;
                justify-content: center;
                align-items: center;
                min-height: 930px;
                position: relative;

                div[data-rmiz-wrap='visible'] {
                    width: 100%;
                }

                & .product-image {
                    max-width: 100%;
                    width: calc(100vw);
                }
            }

            & .yellow-wrapper.drawing-parts {
                min-height: 575px;

                & .product-image {
                    height: auto;
                    max-width: 100%;
                    max-height: 575px;
                    width: auto;
                }
            }
        }
    }

    .add-to-cart {
        path {
            fill: var(--blue-900);
        }
    }
}
