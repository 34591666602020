.equipment-list-item {
    display: flex;
    & .image {
        border: 1px solid var(--yellow-500);
        min-width: 7.167rem;
        max-width: 7.167rem;
        min-height: 5rem;
        max-height: 5rem;
        padding: 1px;
        object-fit: cover;
    }

    & > .equipment-detail-container {
        padding-left: 1.667rem;

        & > .equipment-name{
            color: var(--blue-900);
        }

        & > .equipment-detail {
            & > .detail-title {
                color: var(--grey-600);
            }

            & > .detail-description {
                color: var(--grey-800);
            }
        }

        & > .equipment-description {
          color: var(--grey-600);
      }
    }

    .grey-color{
        color: var(--grey-600);
    }
    .blue-color{
        color: var(--blue-900);
    }
}
