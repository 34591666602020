.image-box {
    display: flex;
    flex-direction: column;
    width: 13rem;
    height: 18rem;
    justify-content: space-between;
    row-gap: 0.5rem;

    &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: var(--body-text-size-sm);
            color: var(--blue-900);
            font-weight: 700;
        }

        .add-photo {
            font-size: var(--body-text-size-sm);
            color: #5f9074;
            text-decoration: underline;
            cursor: pointer;
        }
    }

    &-content {
        display: flex;
        height: 16rem;
        width: 13rem;

        .image-wrapper {
            position: relative;
            width: 100%;
            height: 13rem;
            border-radius: 8px;
            background-color: var(--grey-050);

            & .image-item {
                width: 100%;
                height: 13rem;
                border-radius: 8px;
                object-fit: cover;
                object-position: center;
                border-radius: 0.444rem;
            }

            & > .icon {
                cursor: pointer;
            }

            & > .image-remove-icon {
                position: absolute;
                color: var(--grey-600);
                background-color: var(--blue-100);
                width: 1.5rem;
                height: 1.5rem;
                top: 0.15rem;
                right: 2rem;
                border-radius: 50%;
                padding: 0.2rem;

                &:hover {
                    color: var(--blue-900);
                }
            }

            & > .image-star-border-icon {
                position: absolute;
                color: var(--blue-900);
                background-color: rgba(255, 255, 255, 0.5);
                width: 1.5rem;
                height: 1.5rem;
                top: 0.15rem;
                right: 0.15rem;
                padding: 0.2rem;
                border-radius: 50%;

                &:hover {
                    background-color: var(--blue-100);
                }
            }

            & > .image-star-icon {
                position: absolute;
                color: var(--yellow-300);
                background-color: rgba(255, 255, 255, 0.5);
                width: 1.5rem;
                height: 1.5rem;
                top: 0.15rem;
                right: 0.15rem;
                padding: 0.2rem;
                border-radius: 50%;

                &:hover {
                    background-color: var(--blue-100);
                }
            }

            &:hover {
                background-color: var(--blue-100);

                & > .image-add-icon {
                    color: var(--blue-500);
                }
            }
        }

        .no-image {
            width: 13rem;
            height: 13rem;
            display: flex;
            align-items: center;
            justify-content: center;
            background-color: white;
            border-radius: 8px;
            cursor: pointer;

            &:hover {
                background-color: var(--grey-300);
            }
        }

        .carousel {
            display: flex;
            flex-direction: column-reverse;

            .control-dots {
                position: relative;

                .dot {
                    background: #d9d9d9;
                    box-shadow: none;
                    width: 10px;
                    height: 10px;
                }

                .selected {
                    background: #767676;
                }
            }
        }
    }
}
